import { Component, OnInit, OnDestroy, Renderer2,NgModule } from '@angular/core';
import { AppService } from 'src/app/utils/services/app.service';
import { AccService } from 'src/app/utils/services/acc.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestBase } from 'src/app/Models/input.model/RequestBase';
import { RequestLogin } from 'src/app/Models/input.model/RequestLogin';
import { ResponseLogin } from 'src/app/Models/output.model/ResponseLogin';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss','./main.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup;
  public m: RequestLogin;
  public LoginResult: ResponseLogin;
  
  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private AppService: AppService,
    private http: HttpClient,
    private cookieService: CookieService,
    private spinner: NgxSpinnerService,
    private Acc: AccService
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'login-page');
    this.m = new RequestLogin();
    this.cookieService.deleteAll;
    this.LoginResult = new ResponseLogin();
    this.loginForm = new FormGroup({
      UserName: new FormControl(null, Validators.required),
      Password: new FormControl(null, Validators.required),
    });
  }

  Ridrect() {
    this.AppService.login();
  }
  logIn() {
    this.spinner.show();
    if (this.loginForm.valid) {
      this.m.UserName = this.loginForm.controls.UserName.value;
      this.m.Password = this.loginForm.controls.Password.value;
      let req = {
        UserName: this.m.UserName,
        Password: this.m.Password,
      };
      this.Acc.Login(req).subscribe((z) => {
        if (z.Status == 1) {
          localStorage.setItem('UserInfo', JSON.stringify(z));
          this.AppService.login();
          this.spinner.hide();
        } else {
          this.toastr.error(z.Message, 'Tác vụ thất bại');
          localStorage.removeItem('UserInfo');
          this.spinner.hide();
        }
      });
    } else {
      this.toastr.error('Vui lòng nhập đầy đủ thông tin', 'Tác vụ thất bại');
      this.spinner.hide();
    }
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'login-page');
  }
}
