import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { BaseComponent, removeSpaces, formatDate } from 'src/app/base/base.component';

@Component({
  selector: 'app-user-customer',
  templateUrl: './user-customer.component.html',
  styleUrls: ['./user-customer.component.scss']
})
export class UserCustomerComponent extends BaseComponent implements OnInit {

  dtOptions: DataTables.Settings;
  dataTable: any = []
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective
  dtTrigger = new Subject();

  Insert = new FormGroup({
    CustomerCode: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    CustomerName: new FormControl('', [Validators.required, Validators.maxLength(500)]),
    Url_update: new FormControl('', [Validators.required, Validators.maxLength(500)]),
    DatabaseName: new FormControl('', [Validators.required, Validators.maxLength(200)]),
    ServerName: new FormControl('', [Validators.required, Validators.maxLength(200)]),
    TypeID: new FormControl('', [Validators.required]),
  });

  searchForm = new FormGroup({
    CustomerCode: new FormControl(''),
    CustomerName: new FormControl(''),
    Url_update: new FormControl(''),
    DatabaseName: new FormControl(''),
    ServerName: new FormControl(''),
    TypeName: new FormControl(''),
  });

  ngOnInit(): void {
    this.getToken();
    /**-------------------------USE dtOptions to format table-------------------------*/
    // Filter DATA TABLE
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: true,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        },
      },
      columns: [
        {
          title: 'STT',
          className: "dt-center",
          visible: false
        },
        {
          title: 'Mã khách hàng',
          className: "dt-center",
        },
        {
          title: 'Tên khách hàng',
        },
        {
          title: 'Loại khách hàng',
          visible: false
        },
        {
          title: 'URL',
        },
        {
          title: 'Database',

        },
        {
          title: 'Server',
          width: '100px'
        },
        {
          title: 'Thao tác Database',
          className: "dt-center",
          width: '75px'
        },
        {
          title: 'Phân hệ',
          className: "dt-center",
        },
        {
          title: 'Thao tác',
          className: "dt-center",
          width: '75px'
        },
      ],
    };
    this.GetAll();
  }

  GetAll() {
    this.spinner.show();
    this.CustomerService.Customer_UserID(this.UserID_get, this.Token)
      .subscribe(z => {
        this.dataTable = z.listCustomers;
        this.dtTrigger.next();

        this.dataCustomerCodeFilter = [...new Set(this.dataTable.map(x => x.CustomerCode))];
        this.dataCustomerCodeFilter = this.dataCustomerCodeFilter.sort((a, b) => a.localeCompare(b));

        this.dataDBFilter = [...new Set(this.dataTable.map(x => x.DatabaseName))];
        this.dataDBFilter = this.dataDBFilter.sort((a, b) => a.localeCompare(b));

        this.dataServerFilter = [...new Set(this.dataTable.map(x => x.ServerName))];
        this.dataServerFilter = this.dataServerFilter.sort((a, b) => a.localeCompare(b));

        this.dataTypeNameFilter = [...new Set(this.dataTable.map(x => x.TypeName))];
        this.dataTypeNameFilter = this.dataTypeNameFilter.sort((a, b) => a.localeCompare(b));

        this.CustomerTypeService.Load_List(this.Token).subscribe(
          (res) => {
            this.dataCustomerType = res.Data;
            this.spinner.hide();
          }
        );
      })
  }

  open(content, sizm, type, Data) {
    this.submitted = false;
    this.selected_ID = Data.CustomerID;
    this.selected_database = Data.DatabaseName;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (type == "Add") {
      this.Insert.reset();
      this.Insert.patchValue({
        TypeID: ''
      });
      this.checkInsert = true;
      this.titleModal = "Thêm mới";
    }
    if (type == "Update") {
      this.checkInsert = false;
      this.titleModal = Data.CustomerName;
      this.Insert.patchValue({
        CustomerCode: Data.CustomerCode,
        CustomerName: Data.CustomerName,
        Url_update: Data.Url_update,
        DatabaseName: Data.DatabaseName,
        ServerName: Data.ServerName,
        TypeID: Data.TypeID
      });
    }
    if (type == "Delete") {
      this.selected_ID = Data.CustomerID;
    }
    if (type == "ViewModule") {
      this.searchModule = '';
      this.itemSelected = false;
      this.titleModal = Data.CustomerName;
      this.spinner.show();
      this.selected_ID = Data.CustomerID;
      this.ModuleService.Load_List(this.Token).subscribe((z) => {
        this.modules = z.listModules;
        this.dataModuleFilter = z.listModules;
        this.ModuleService.Module_CustomerID(this.selected_ID, this.Token)
          .subscribe((z) => {
            this.listModuleByCustomerID = z.listModules;
            this.itemSelected = this.listModuleByCustomerID.length == this.modules.length ? true : false;
            this.ID_module_selected = this.listModuleByCustomerID.map(
              (x) => x.ModuleID
            );
            for (var i = 0; i < this.ID_module_selected.length; i++) {
              for (var j = 0; j < this.modules.length; j++) {
                if (this.modules[j].ModuleID == this.ID_module_selected[i]) {
                  this.modules[j].checked = true;
                }
              }
            }
            var module_checked = this.modules.filter(value => value.checked == true).map(
              (x) => x.ModuleID
            );
            this.count_module = module_checked.length;
          });
      });
      this.spinner.hide();
    }
    if (type == "ViewHistory" || type == "Restore" || type == 'Backup') {
      this.titleModal = Data.DatabaseName;
      this.dbName = Data.DatabaseName;
      this.svName = Data.ServerName;
    }
  }

  changeModule() {
    if (this.searchModule != '') {
      this.modules = this.dataModuleFilter.filter(x => x.ModuleName.toLowerCase().includes(this.searchModule.toLowerCase()));
      this.itemSelected = this.listModuleByCustomerID.length == this.modules.length ? true : false;
    }
    else {
      this.modules = this.dataModuleFilter;
      this.itemSelected = this.listModuleByCustomerID.length == this.modules.length ? true : false;
    }
    var module_checked = this.modules.filter(value => value.checked == true)
    this.count_module = module_checked.length;
  }

  unCheckModule() {
    $('#SelectALL').prop('checked', false);
  }

  DestroyCheck() {
    this.itemSelected = false;
    this.checkUncheckAll();
  }
  CustomerModuleSave() {
    // Filter checkbox get id module
    var ID_module_Filter = this.dataModuleFilter.filter(value => value.checked == true).map(
      (x) => x.ModuleID
    )
    let req = {
      CustomerID: this.selected_ID,
      list_customer_module: ID_module_Filter
    }
    this.spinner.show();
    /**--------------INSERT--------------- */
    this.CustomerService.Insert_Module(req, this.Token).subscribe(
      (z) => {
        if (z.Status == 2) {
          this.spinner.show();
          this.toastr.warning(z.Message);
          this.spinner.hide();
        } else if (z.Status == 1) {
          this.spinner.show();
          this.toastr.success(z.Message);
          this.spinner.hide();
        } else {
          this.spinner.show();
          this.toastr.error(z.Message);
          this.spinner.hide();
        }
      },
      (err) => {
        if (err.status == 401) {
          this.spinner.show();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.spinner.hide();
        }
      }
    );
    this.spinner.hide();
  }

  checkUncheckAll() {
    for (var i = 0; i < this.modules.length; i++) {
      this.modules[i].checked = this.itemSelected;
    }
    this.total_count_module();
  }
  isAllSelected() {
    this.itemSelected = this.listModuleByCustomerID.every(function (item: any) {
      return item.checked == true;
    }
    )
  }

  total_count_module() {
    var customer_checked = this.modules.filter(value => value.checked == true)
    if (this.modules.filter(value => value.checked == true)) {
      this.count_module++;
    }
    if (this.modules.filter(value => value.checked == false)) {
      this.count_module--;
    }
    this.count_module = customer_checked.length;
  }

  deleteItem() {
    this.spinner.show();
    this.CustomerService.Delete(this.selected_ID, this.Token)
      .subscribe(z => {
        if (z.Status == 2) {
          this.spinner.show();
          this.toastr.warning(z.Message);
          this.spinner.hide();
        } else if (z.Status == 1) {
          this.spinner.show();
          this.toastr.success("Xóa thành công !");
          this.spinner.hide();
        } else {
          this.spinner.show();
          this.toastr.error(z.Message);
          this.spinner.hide();
        };
        this.modalService.dismissAll('');
        this.datatableElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          }
        );
        this.GetAll();
      },
        (err) => {
          if (err.status == 401) {
            this.spinner.show();
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
            this.spinner.hide();
          }
        });
  }

  get checkvalueInsert() {
    return this.Insert.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.Insert.invalid) {
      return false;
    }
    let req = {
      CustomerID: this.selected_ID,
      CustomerCode: this.Insert.value.CustomerCode,
      CustomerName: this.Insert.value.CustomerName,
      Url_update: this.Insert.value.Url_update,
      DatabaseName: this.Insert.value.DatabaseName,
      ServerName: this.Insert.value.ServerName,
      TypeID: this.Insert.value.TypeID,
      UserID: this.UserID_get
    }
    this.spinner.show();
    if (this.checkInsert) {
      this.CustomerService.Insert(req, this.Token).subscribe((res) => {
        if (res.Status == 2) {
          this.toastr.warning(res.Message);
          this.spinner.hide();
        } else if (res.Status == 1) {
          this.toastr.success(res.Message);
          this.spinner.hide();
        } else if (res.Status == 4) {
          this.toastr.error('Thêm mới thất bại!');
          this.spinner.hide();
        }
        this.modalService.dismissAll('AddModal');
        this.datatableElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          }
        );
        this.spinner.hide();
        this.GetAll();
      },
        (err) => {
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem('UserInfo');
            this.router.navigate(['/login']);
          }
          if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        })
    } else {
      this.CustomerService.Update(req, this.Token).subscribe((res) => {
        if (res.Status == 2) {
          this.toastr.warning(res.Message);
          this.spinner.hide();
        } else if (res.Status == 1) {
          this.toastr.success(res.Message);
          this.spinner.hide();
        } else if (res.Status == 4) {
          this.toastr.error('Cập nhật thất bại!');
          this.spinner.hide();
        }
        this.modalService.dismissAll('AddModal');
        this.datatableElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          }
        );
        this.spinner.hide();
        this.GetAll();
      },
        (err) => {

          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem('UserInfo');
            this.router.navigate(['/login']);
          }
          if (err.status == 401) {
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
          }
        })
    }
  }

  RestoreDB() {
    this.spinner.show();
    let req = {
      ServerName: this.svName,
      DatabaseName: this.dbName
    }
    this.spinner.show();
    this.CustomerService.RestoreDB(req, this.Token)
      .subscribe(z => {
        if (z.Status == 2) {
          this.spinner.show();
          this.toastr.warning(z.Message);
          this.spinner.hide();
        } else if (z.Status == 1) {
          this.spinner.show();
          this.toastr.success(z.Message);
          this.spinner.hide();
        } else {
          this.spinner.show();
          this.toastr.error(z.Message);
          this.spinner.hide();
        }
        this.datatableElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          }
        );
        this.GetAll();
        this.modalService.dismissAll('');
        this.spinner.hide();
      },
        (err) => {
          if (err.status == 401) {
            this.spinner.show();
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
            this.spinner.hide();
          }
        });
  }

  Excel() {
    let dataExport = {
      UserID: this.UserID_get,
      CustomerCode: this.searchForm.value.CustomerCode,
      CustomerName: this.searchForm.value.CustomerName,
      Url_update: this.searchForm.value.Url_update,
      DatabaseName: this.searchForm.value.DatabaseName,
      ServerName: this.searchForm.value.ServerName,
      TypeName: this.searchForm.value.TypeName,
    }
    var r = confirm("File của bạn sẽ được tải xuống trong giây lát!");
    if (r) {
      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      this.spinner.show();
      this.CustomerService.Excel(dataExport, a.Token)
        .subscribe(z => {
          if (z.Status == 1) {
            this.ExportService.exportExcelByte(z.FileData, 'DanhSachKhachHang');
            this.toastr.success("File đã được tải xuống", "Tác vụ thành công")
            this.spinner.hide();
          }
          else {
            this.toastr.error(z.Message, "Tác vụ thất bại")
            this.spinner.hide();
          }
        });
    };
  }

  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn['dataTable'].ext.search.pop();
    });
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let CustomerCode_data = data[1];
      let CustomerName_data = data[2];
      let TypeName_data = data[3];
      let Url_update_data = data[4];
      let DatabaseName_data = data[5];;
      let ServerName_data = data[6];
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let bool4 = true;
      let bool5 = true;
      let bool6 = true;

      if (this.searchForm.value.CustomerCode != '') {
        bool1 = CustomerCode_data == this.searchForm.value.CustomerCode;
      }
      if (this.searchForm.value.CustomerName != '') {
        bool2 = this.xoa_dau(CustomerName_data.toLowerCase()).includes(this.xoa_dau(this.searchForm.value.CustomerName.toLowerCase()));
      }
      if (this.searchForm.value.TypeName != '') {
        bool3 = TypeName_data == this.searchForm.value.TypeName;
      }
      if (this.searchForm.value.Url_update != '') {
        bool4 = this.xoa_dau(Url_update_data.toLowerCase()).includes(this.xoa_dau(this.searchForm.value.Url_update.toLowerCase()));
      }
      if (this.searchForm.value.DatabaseName != '') {
        bool5 = DatabaseName_data == this.searchForm.value.DatabaseName;
      }
      if (this.searchForm.value.ServerName != '') {
        bool6 = ServerName_data == this.searchForm.value.ServerName;
      }
      if (bool1 && bool2 && bool3 && bool4 && bool5 && bool6) {
        return true;
      }
      return false;
    });
  }

  backupDB() {
    this.spinner.show();
    this.CustomerService.backupDatabase({ DatabaseNameBackup: this.dbName }, this.Token).subscribe(
      (res: any) => {
        if (res.Status == 1) {
          this.toastr.success(res.Message);
          this.spinner.hide();
        }
        else {
          this.toastr.warning(res.Message);
          this.spinner.hide();
        }
      }
    );
  }
}
