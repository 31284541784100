<p>user-function works!</p>
<!----------------------------------------------- HEADER ------------------------------------------------->
<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">Quyền cá nhân</li>
                </ol>
            </div>
        </div>
    </div>
  </section>
  
  <!----------------------------------------------- FILTER ------------------------------------------------->
  <!-- <section class="content cus-body">
    <div class="card">
        <div class="card-body">
              <div class="row" style="padding-left:10px; padding-right:10px">
                <div class="container">
                  <input type="text" class="form-control" [(ngModel)]="searchString" placeholder="Tìm kiếm nhanh...">
                </div>
              </div>
        </div>
    </div>
  </section> -->
  <!----------------------------------------------- TABLE ------------------------------------------------->
  <section class="content cus-body">
    <div class="card">
      <div class="card-body">
        <div class="tab-content">
          <div class="active tab-pane" id="TeachInfo">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <div class="container">
                  </div>
                  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                  class="table-bordered dataTables_wrapper no-footer">
                     <thead style="text-align: center;">
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                     </thead>
                     <tbody id = "myTable">
                         <tr *ngFor="let function of dataTable; let i=index" >
                             <td>{{i+1}}</td>
                             <td>{{function.FunctionCode}}</td>
                             <td>{{function.FunctionName}}</td>
                         </tr>
                     </tbody>
                 </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  