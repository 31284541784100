<section class="content-header" style="padding: 0.5% !important">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-md-6">
              <ol class="breadcrumb float-sm-left">
                  <li class="breadcrumb-item">
                      <a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                  </li>
                  <li class="breadcrumb-item active">Hồ sơ của tôi</li>
              </ol>
          </div>
          <div class="col-md-6">
              <button class="btn btn-warning btn-sm"  type="submit" style="float: right; ">
                  <i class="fas fa-info-circle"></i>
                  Hướng dẫn
              </button>
          </div>
      </div>
  </div>
</section>
<!-- Main content -->
<section class="content">
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
        <div class="card card card-primary">
            <div class="card-header no-border" style = "background-color: #17a2b8!important;">
              <div class="row">
                  <div class=" col-md-3"><h3 class="card-title" >Thông tin người dùng</h3></div>
              </div>
            </div>
            <div class="card-body">
                <div class="tab-content">
                    <div class="active tab-pane">
                        <form class="form-horizontal" [formGroup]="InforForm">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="inputName" class="col-sm-6 control-label">
                                        <i class="fas fa-user-circle"></i>  
                                        Họ và tên
                                      </label>
                                      <div class="col-sm-10">
                                          <input type="text"  class="form-control" formControlName="Ho_ten" value="{{FullName_get}}" readonly placeholder="Họ tên">
                                      </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="inputName2" class="col-sm-6 control-label">
                                        <i class="far fa-envelope"></i>  
                                        Địa chỉ email
                                      </label>
                                      <div class="col-sm-10">
                                          <input type="email" class="form-control" formControlName="Email" placeholder="Nhập địa chỉ email">
                                      </div>
                                </div>
                            </div>
                          </form>
                    </div>
                    <div class="tab-pane" id="settings">
                        <form class="form-horizontal"></form>
                    </div>
                    <!-- /.tab-pane -->
                </div>
                <!-- /.tab-content -->
            </div><!-- /.card-body -->
            <div class="card-footer">
                  <p class="btn btn-success float-sm-right cs-i-c" (click)="Save()">
                      <i class="fas fa-save"></i> Lưu thông tin
                  </p>
            </div>
        </div>
    </div>
    <!-- /.col -->
</div>
  <!-- /.row -->
</div>
<!-- /.container-fluid -->
</section>


