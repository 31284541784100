import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { Chart } from 'chart.js';
import { LevelFunctionService } from 'src/app/utils/services/level-function.service';
import { ModuleService } from 'src/app/utils/services/module.service';
import { BaseComponent } from 'src/app/base/base.component';

@Component({
  selector: 'app-level-function',
  templateUrl: './level-function.component.html',
  styleUrls: ['./level-function.component.scss']
})
export class LevelFunctionComponent extends BaseComponent implements OnInit {

  dtOptions: DataTables.Settings;
  dataTable: any = []
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective
  dtTrigger = new Subject();

  searchForm = new FormGroup({
    Tu_ngay: new FormControl(''),
    Den_ngay: new FormControl(''),
    Phan_he: new FormControl(''),
    Limit: new FormControl(2),
  })

  ngOnInit(): void {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.UserID_get = a.Info.UserID;
    this.Token = a.Token;  
    this.statisticByDate();
    this.getList();
    this.getModule();
  }

  getModule() {
    this.ModuleService.Load_List(this.Token).subscribe(
      (res) => {
        this.dataModule = res.listModules;
      }
    );
  }

  search() {
    this.getList();
  }

  getList() {
    this.spinner.show();
    this.LevelFunctionService.getList(this.searchForm.value,this.Token).subscribe(
      (res) => {
        this.dataTable = res.DataFunction;
        this.dataChart = res.DataFunction;
        this.spinner.hide();
        this.Linechart = new Chart('canvas', {  
          type: 'bar',  
          data: {  
            labels: this.dataChart.map(x => x.Ma_chuc_nang),               
            datasets: [  
              {  
                borderColor: '#3cb371',  
                backgroundColor: "#17a2b8",  
                fill: true,
                data: this.dataChart.map(x => x.So_lan_su_dung),  
              }  
            ]  
          },  
          options: {  
            responsive: true,
            legend: {  
              display: false  
            },  
            scales: {  
              xAxes: [{  
                position: 'bottom',
                scaleLabel: {
                  labelString: 'Khách hàng',
                  display: true,
                }
              }],  
              yAxes: [{  
                type: 'linear',
                scaleLabel: {
                  labelString: 'Số lần sử dụng',
                  display: true,
                }             
              }],  
            }  
          }  
        }); 
      }
    );
  }

  statisticByDate() {
    this.filterDate(this.searchForm.value.Limit);
    this.searchForm.patchValue({
      Tu_ngay: this.dayBefore,
      Den_ngay: this.currentDate,
    })
  }
}
