<!----------------------------------------------- HEADER ------------------------------------------------->
<section class="content-header" style="padding:0.5%  !important">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-md-6">
              <ol class="breadcrumb float-left">
                  <li class="breadcrumb-item"><a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                  </li>
                  <li class="breadcrumb-item active">Quản lí quyền</li>
              </ol>
          </div>
          <div class="col-md-6">
              <button class="btn btn-outline-success btn-sm" (click)="open(AddModal,'ls','Add','')" style="float: right;"><i
                      class="fas fa-plus-circle"></i> Thêm mới
              </button>
          </div>
      </div>
  </div>
</section>

<!----------------------------------------------- FILTER ------------------------------------------------->
<!-- <section class="content cus-body">
  <div class="card">
      <div class="card-body">
            <div class="row" style="padding-left:10px; padding-right:10px">
              <div class="container">
                <input type="text" class="form-control" [(ngModel)]="searchString" placeholder="Tìm kiếm nhanh...">
              </div>
            </div>
      </div>
  </div>
</section> -->
<!----------------------------------------------- TABLE ------------------------------------------------->
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane" id="TeachInfo">
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <div class="container">
                </div>
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                class="table-bordered dataTables_wrapper no-footer">
                   <thead style="text-align: center;">
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                   </thead>
                   <tbody id = "myTable">
                       <tr *ngFor="let function of dataTable; let i=index" >
                           <td>{{i+1}}</td>
                           <td>{{function.FunctionCode}}</td>
                           <td>{{function.FunctionName}}</td>
                           <td>
                            <button class="btn btn-outline-info btn-sm" 
                            (click)="open(AddModal, 'xs', 'Update', function)" style="margin-right: 5px;"> 
                            <i class="fas fa-edit"></i> 
                            </button>  
                            <button class="btn btn-outline-danger btn-sm" 
                            (click)="open(DeleteModal, 'xs', 'Delete', function)"> 
                            <i class="fas fa-trash-alt"></i> 
                            </button>
                           </td>
                       </tr>
                   </tbody>
               </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-----------------------------------------------ADD MODAL ------------------------------------------------->
<ng-template #AddModal let-modal>
  <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
    <h4 class="modal-title h-title w-100"> {{titleModal}}</h4>
    <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="AddForm">       
      <div class="row">
        <div class="col-md-12">
          <label class="col-md-10 require">Mã quyền</label>
            <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && checkvalue.FunctionCode.errors }"
            formControlName="FunctionCode" placeholder="Nhập mã quyền...">
            <div *ngIf="submitted && checkvalue.FunctionCode.errors" class="invalid-feedback">
              <div *ngIf="checkvalue.FunctionCode.errors.required">
                Mã quyền không được bỏ trống!
              </div>
              <div *ngIf="checkvalue.FunctionCode.errors.maxlength">
                Mã quyền tối đa 50 ký tự!
              </div>
            </div>
        </div>
        <div class="col-md-12">
          <label class="col-md-10 require">Tên quyền</label>
            <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && checkvalue.FunctionName.errors }"
             formControlName="FunctionName" placeholder="Nhập tên quyền...">
             <div *ngIf="submitted && checkvalue.FunctionName.errors" class="invalid-feedback">
              <div *ngIf="checkvalue.FunctionName.errors.required">
                Tên quyền không được bỏ trống!
              </div>
              <div *ngIf="checkvalue.FunctionName.errors.maxlength">
                Tên quyền tối đa 200 ký tự!
              </div>
            </div>
        </div>
        <div class="col-md-6">
       </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-outline-success btn-sm" (click)="onSubmit()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button type="button" class="btn btn-outline-danger btn-sm" (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;">
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<!-----------------------------------------------DELETE MODAL ------------------------------------------------->
<ng-template #DeleteModal let-modal>
  <app-delete-modal (delete)="deleteItem()" (close)="dissmissModal()"></app-delete-modal>
</ng-template>

