<!----------------------------------------------- HEADER ------------------------------------------------->
<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;TEST</a>
                    </li>
                </ol>
            </div>
            <div class="col-md-6">
                <button class="btn btn-success btn-sm" style="float: right;" (click)="Filter()">
                    <i class="fas fa-filter"></i> Lọc kết quả
                </button>
                <button class="btn btn-warning btn-sm" style="float: right;margin-right: 5px;" (click)="Refresh()">
                    <i class="fas fa-redo-alt"></i> Refresh
                </button>
            </div>
        </div>
    </div>
  </section>

<!----------------------------------------------- TABLE ------------------------------------------------->
<section class="content cus-body">
    <div class="card">
      <div class="card-body">
        <div class="tab-content">
          <div class="active tab-pane">
            <div class="row">
              <div class="col-md-6" style="margin: 0 auto;">
                <div class="table-responsive">
                  <div class="container">
                  </div>
                  <table class="table-bordered" style="width: 100%;">
                     <thead style="text-align: center;font-weight: bold;">
                        <tr>
                          <th>STT</th>
                          <th>Tên</th>
                          <th>Câu hỏi</th>
                        </tr>
                     </thead>
                     <tbody style="text-align: center;">
                         <tr *ngFor="let data of Thi_sinh; let i=index" >
                             <td>{{i+1}}</td>
                             <td>{{data.Ho_ten}}</td>
                             <td>{{data.Cau_hoi_ca_nhan}}</td>
                         </tr>
                     </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>