import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
import { Version } from './../../Models/version';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) { }

  Load_List(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Version/GetVersionList', {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }
  Delete(ID_version: number, token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Version/Delete_Version?ID_version='+ID_version,{
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  Update(req: any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Version/Update_Version',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }
  Insert(version: any, token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Version/Insert_Version',version,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

}
