<!----------------------------------------------- HEADER ------------------------------------------------->
<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">Chi tiết mức độ sử dụng</li>
                </ol>
            </div>
            <div class="col-md-6">
                <button class="btn btn-info btn-sm" (click)="search()" style="float: right;"><i class="fas fa-chart-bar"></i>&nbsp;Thống kê
                </button>
            </div>
        </div>
    </div>
</section>
<!----------------------------------------------- FILTER ------------------------------------------------->
<section class="content cus-body "  [@openClose]="isDisplay ? 'open' : 'closed'">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="searchForm">
              <div class="row">
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;">Loại khách hàng</label>
                  <ng-select formControlName="TypeID" notFoundText="Không có kết quả">
                      <ng-option value="">Tất cả</ng-option>
                      <ng-option *ngFor="let type of dataType" [value]="type.TypeID">{{type.TypeName}}</ng-option>
                  </ng-select>
                </div>
                <div class="col-md-3">
                    <label class="col-md-10" style="margin-top: 10px;">Mã khách hàng</label>
                    <input class="form-control" formControlName="Ma_truong" type="text" placeholder="Nhập mã khách hàng...">
                </div>
                <div class="col-md-3">
                    <label class="col-md-10" style="margin-top: 10px;">Mã phân hệ</label>
                    <input class="form-control" formControlName="Phan_he" type="text" placeholder="Nhập mã phân hệ...">
                </div>
                <div class="col-md-3">
                    <label class="col-md-10" style="margin-top: 10px;">Mã chức năng</label>
                    <input class="form-control" formControlName="Ma_chuc_nang" type="text" placeholder="Nhập mã chức năng...">
                </div>
                <div class="col-md-3">
                    <label class="col-md-10" style="margin-top: 10px;">Tên người dùng</label>
                    <input class="form-control" formControlName="Ten_nguoi_dung" type="text" placeholder="Nhập tên người dùng...">
                </div>
                <div class="col-md-3">
                    <label class="col-md-10" style="margin-top: 10px;">Phạm vi báo cáo</label>
                    <ng-select formControlName="Limit" notFoundText="Không có kết quả" (change)="statisticByDate()">
                        <ng-option [value]="0">Tùy chỉnh</ng-option>
                        <ng-option *ngFor="let p of selectDate" [value]="p.value">{{p.name}}</ng-option>
                    </ng-select>
                  </div>
                <div class="col-md-3">
                  <label class="col-md-10 " style="margin-top: 10px;">Từ ngày</label>
                  <input class="form-control" type="date" formControlName="Tu_ngay"/>
                </div>
                <div class="col-md-3">
                    <label class="col-md-10 " style="margin-top: 10px;">Đến ngày</label>
                    <input class="form-control" type="date" formControlName="Den_ngay"/>
                </div>
              </div>
        </form>
        </div>
    </div>
</section>
<!----------------------------------------------- TABLE ------------------------------------------------->
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <div class="dol-md-12" style="margin-bottom: 10px;">
                                    <form [formGroup]="searchForm">
                                        <div class="row">
                                            <div class="col-md-2">
                                                <select class="form-control-sm"  formControlName="So_ban_ghi" style="height: 25px;" (change)="filterData()">
                                                    <option [ngValue]="100000000">Tất cả</option>
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                            <div class="col-md-10">
                                                <button type="button" class="btn btn-sm btn-outline-secondary" (click)="clickFilter()"
                                                    ngbTooltip="Ẩn/hiện bộ lọc" placement="left"
                                                    style="float: right; margin: 0 5px;">
                                                    <i class="fas fa-filter"></i>
                                                </button>
                                                <button type="button" class="btn btn-sm btn-outline-secondary" placement="left" (click)="reload()"
                                                    ngbTooltip="Tải lại" style="float: right; margin-bottom: 5px;">
                                                    <i class="fas fa-redo-alt"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <table class="table table-bordered">
                                    <thead style="text-align: center;">
                                      <tr>
                                        <th>STT</th>
                                        <th>Mã khách hàng</th>
                                        <th>Tên khách hàng</th>
                                        <th>Mã phân hệ</th>
                                        <th>Mã chức năng</th>
                                        <th>Tên chức năng</th>
                                        <th>Tên người dùng</th>
                                        <th>Họ và tên</th>
                                        <th>Tên đơn vị</th>
                                        <th>Ngày tháng</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let data of dataTable">
                                        <td style="text-align: center;white-space: nowrap;">{{data.RowNum}}</td>
                                        <td style="text-align: center;white-space: nowrap;">{{data.Ma_truong}}</td>
                                        <td>{{data.Ten_truong}}</td>
                                        <td style="text-align: center;white-space: nowrap;">{{data.Phan_he}}</td>
                                        <td style="text-align: center;white-space: nowrap;">{{data.Ma_chuc_nang}}</td>
                                        <td>{{data.Ten_chuc_nang}}</td>
                                        <td style="text-align: center;white-space: nowrap;">{{data.Ten_nguoi_dung}}</td>
                                        <td>{{data.Ho_ten_nguoi_dung}}</td>
                                        <td>{{data.Ten_don_vi}}</td>
                                        <td style="text-align: center;white-space: nowrap;">{{data.Ngay_thang | date: 'dd-MM-yyyy hh:a'}}</td>
                                      </tr>
                                      <tr *ngIf="!(dataTable.length > 0)" style="text-align: center;font-weight: bold;">
                                        <td colspan="10">Không có dữ liệu !</td>
                                      </tr>
                                    </tbody>                                  
                                  </table>
                                  <div class="col-md-12">
                                    <nav aria-label="..." style="float: right;">
                                        <ul class="pagination">
                                          <li class="page-item">
                                            <a class="page-link" tabindex="-1" [routerLink]="" (click)="refreshPage()">Đầu</a>
                                          </li>
                                          <li class="page-item">
                                            <a class="page-link" tabindex="-1" [routerLink]="" (click)="previewPage()">Trước</a>
                                          </li>
                                          <!-- <li class="page-item"><a class="page-link" href="#">1</a></li>
                                          <li class="page-item active">
                                            <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                                          </li>
                                          <li class="page-item"><a class="page-link" href="#">3</a></li> -->
                                          <li class="page-item active">
                                            <a class="page-link" [routerLink]="">{{page}}<span class="sr-only"></span></a>
                                          </li>
                                          <li class="page-item">
                                            <a class="page-link" [routerLink]="" (click)="nextPage()">Tiếp</a>
                                          </li>
                                          <li class="page-item">
                                            <a class="page-link" [routerLink]="" (click)="lastPage()">Cuối</a>
                                          </li>
                                        </ul>
                                    </nav>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
</section>