import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  dtOptions: DataTables.Settings;
  Token: string;
  dataTable: any = []
  closeResult: string;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective
  submitted = false
  dtTrigger = new Subject();
  public com: common;
  public data: any;
  checkInsert:boolean = false;
  titleModal: any;

  Cau_hoi_random1: any = [];
  Cau_hoi_random2: any = [];
  Cau_hoi_random3: any = [];
  Cau_hoi_random4: any = [];

  Thi_sinh: any;
  Bo_cau_hoi: any = [];

  constructor(
    public spinner: NgxSpinnerService,
    private modalService: NgbModal,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
  ) { }


  ngOnInit(): void {
    this.GetAll();
    this.GetCauHoi();
  }

  GetAll() {
    this.Thi_sinh = [
      {
        "Ho_ten": "Thí sinh 1",
        "Cau_hoi_ca_nhan": this.Cau_hoi_random1
      },
      {
        "Ho_ten": "Thí sinh 2",
        "Cau_hoi_ca_nhan": this.Cau_hoi_random2
      },
      {
        "Ho_ten": "Thí sinh 3",
        "Cau_hoi_ca_nhan": this.Cau_hoi_random3
      },
      {
        "Ho_ten": "Thí sinh 4",
        "Cau_hoi_ca_nhan": this.Cau_hoi_random4
      },
    ]
  }

  GetCauHoi() {
    for(let i=11; i<=30; i++) {
      this.Bo_cau_hoi.push(i);
    }
    this.Bo_cau_hoi = this.Bo_cau_hoi.sort();
  }

  Filter() {
    console.log(this.Bo_cau_hoi);
    var random_number = this.Bo_cau_hoi[Math.floor(Math.random() * this.Bo_cau_hoi.length)];
    this.Bo_cau_hoi.forEach((element,index)=>{
      if(element == random_number) delete this.Bo_cau_hoi[index];
    });
    this.Bo_cau_hoi = this.Bo_cau_hoi.filter(x => (x > 0));
    if (this.Cau_hoi_random1.length < 5) {
      this.Cau_hoi_random1.push(random_number);
    }
    else if(this.Cau_hoi_random2.length < 5) {
      this.Cau_hoi_random2.push(random_number);
    }
    else if(this.Cau_hoi_random3.length < 5) {
      this.Cau_hoi_random3.push(random_number);
    }
    else if(this.Cau_hoi_random4.length < 5) {
      this.Cau_hoi_random4.push(random_number);
    }
    this.GetAll();
  }

  Refresh() {
    this.Bo_cau_hoi = [];
    this.GetCauHoi();
    this.Cau_hoi_random1 = [];
    this.Cau_hoi_random2 = [];
    this.Cau_hoi_random3 = [];
    this.Cau_hoi_random4 = [];
    this.GetAll();
  }
}
