<section class="content">
  <div class="container-fluid" >
    <div class="col-md-12" style="padding-top: 25px;">
      <div class="row">
        <!----------------------------------------- TITLE LEFT ----------------------------------------->
        <div class="col-md-6">
          <div style="height: 90%;" class="small-box bg-info">
            <div class="row">
              <div class="col-md-6" style="border-right: 1px solid white;margin-top: 2%;">
                <div class="inner __title_dashboard">
                  <a [routerLink]="['/customer']" [style]="styleDashboardCustomerWithRole">
                    <h6 class="__title">Khách hàng</h6>
                    <h6>{{count_customer}}</h6>
                    <h6>Trong năm: {{count_customer_inYear}}</h6>
                    <div class="icon">
                      <i class="fas fa-school"></i>
                    </div>
                  </a>
                </div>
              </div>
              <div class="col-md-6" style="margin-top: 2%;">
                <div class="inner __title_dashboard">
                  <a [routerLink]="['/customer']" [style]="styleDashboardCustomerWithRole">
                    <h6 class="__title">Quá hạn cập nhật</h6>
                    <h5>{{count_customer_outOfDate}}</h5>
                    <h6>Phân hệ: {{count_module_outOfDate}}</h6>
                    <div class="icon">
                      <i style="padding-right: 20px !important;" class="fas fa-hourglass-end"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!----------------------------------------- TITLE RIGHT ----------------------------------------->
        <div class="col-md-6">
          <div style="height: 90%;" class="small-box bg-info">
            <div class="row">
              <div class="col-md-6" style="border-right: 1px solid white;margin-top: 2%;">
                <div class="inner __title_dashboard">
                  <a [routerLink]="['/customer']" [style]="styleDashboardCustomerWithRole">
                    <h6 class="__title">Khách hàng dịch vụ</h6>
                    <h5>{{count_customer_service}}</h5>
                    <h6>Quá hạn: {{count_customer_outOfDate2}}</h6>
                    <div class="icon">
                      <i class="fas fa-user-tag"></i>
                    </div>
                  </a>
                </div>
              </div>
              <div class="col-md-6" style="margin-top: 2%;">
                <div class="inner __title_dashboard">
                  <a [routerLink]="['/customer']" [style]="styleDashboardCustomerWithRole">
                    <h6 class="__title">Thanh toán online</h6>
                    <h5>{{count_customer_online}}</h5>
                    <h6>Số giao dịch: {{count_trade}}</h6>
                    <div class="icon">
                      <i style="padding-right: 20px !important;" class="fas fa-file-upload"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
<!----------------------------------------- END TITLE ----------------------------------------->       
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <p style="font-size: 18px;text-align: center;padding: 10px;font-weight: bold;">Thống kê cập nhật phiên bản theo tháng</p>
        <canvas id="canvas1">{{ Linechart1 }}</canvas>    
      </div>
      <div class="col-md-6">
        <p style="font-size: 18px;text-align: center;padding: 10px;font-weight: bold;">Top 20 đơn vị mức độ sử dụng theo tháng</p>
        <canvas id="canvas2">{{ Linechart2 }}</canvas>    
      </div>
    </div>
    
    <div class="row">
      <div class="card" style="width:100%">
        <div class="card-header" style="text-align: center;">
          <label style="font-size: 18px;">KHÁCH HÀNG QUÁ HẠN CẬP NHẬT</label>
        </div>
        <div class="table-responsive" style="max-height: 30vh; margin-bottom: 15px;">
          <table class="table table-bordered table-hover" id="tableData">
            <thead style="text-align: center;">
              <th>STT</th>
              <th>Mã khách hàng</th>
              <th>Tên khách hàng</th>
              <th>Phân hệ</th>
              <th>Cập nhật lần cuối</th>
              <th>Số ngày</th>
            </thead>
            <tbody>
              <tr *ngFor="let p of dataDanhSachQuaHanCNPB; let i = index; let odd = odd">
                <td style="text-align: center;">{{i+1}}</td>
                <td style="text-align: center;">{{p.CustomerCode}}</td>
                <td>{{ p.CustomerName }}</td>
                <td style="text-align: center;">{{ p.ModuleCode}}</td>
                <td style="text-align: center;">{{ p.Cap_nhat_lan_cuoi | date:'dd/MM/yyyy, h:mm a' }}</td>
                <td style="text-align: center;">{{ p.So_ngay}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>