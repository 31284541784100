<link href="//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/js/bootstrap.min.js"></script>
<script src="//code.jquery.com/jquery-1.11.1.min.js"></script>
<!------ Include the above in your HEAD tag ---------->

<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
<div class="form-gap"></div>
<div class="container" style="padding-top: 5vh;">
	<div class="row">
		<div class="col-md-5 col-md-offset-4">
        <div class="panel panel-default">
          <div class="panel-body">
            <div class="text-center">
              <h3><i class="fa fa-lock fa-4x"></i></h3>
              <h2 class="text-center">Quên mật khẩu?</h2>
              <p>Hãy điền đúng thông tin để chúng tôi có thể hỗ trợ bạn.</p>
              <div class="panel-body">
                <form class="form-horizontal" [formGroup]="ForgotPassword">
                  <div class="row">
                    <div class="col-md-12">
                      <label style="text-align: left;" class="col-md-12 require">Nhập tài khoản Email</label>
                      <input type="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && checkvalue.Email.errors }"
                        formControlName="Email" placeholder="Nhập email...">
                        <div *ngIf="submitted && checkvalue.Email.errors" class="invalid-feedback">
                        <div *ngIf="checkvalue.Email.errors.required">
                          Email không được bỏ trống!
                        </div>
                      </div>
                      <label style="text-align: left;" class="col-md-12 require">Tên đăng nhập</label>
                      <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && checkvalue.UserName.errors }"
                        formControlName="UserName" placeholder="Nhập tên đăng nhập...">
                        <div *ngIf="submitted && checkvalue.UserName.errors" class="invalid-feedback">
                        <div *ngIf="checkvalue.UserName.errors.required">
                          Tên đăng nhập không được bỏ trống!
                        </div>
                      </div>
                  </div>
                  </div>
                </form>
                <div class="form-group" style="margin-top: 20px;">
                  <input name="recover-submit" class="btn btn-lg btn-primary btn-block" value="Xác nhận" type="submit" (click)="onSubmit()">
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
	</div>
</div>


