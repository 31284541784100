<section class="content cus-body">
  <div class="card">
      <div class="card-body">
          <div class="tab-content">
              <div class="active tab-pane">
                  <div class="row">
                      <div class="col-md-12">
                          <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                            <div class="container" style="margin: 5vh 0">
                              <input type="text" class="form-control" [(ngModel)]="searchString" placeholder="Tìm kiếm...">
                            </div>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" style="text-align: center;">STT</th>
                                        <th scope="col" style="text-align: center;">Mã phân hệ</th>
                                        <th scope="col" style="text-align: center;">Tên phân hệ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let p of dataTable | filter: searchString; let i=index;">
                                        <td style="text-align: center;">{{i+1}}</td>
                                        <td>{{p.ModuleCode}}</td>
                                        <td>{{p.ModuleName}}</td>
                                    </tr>
                                </tbody>
                            </table>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
