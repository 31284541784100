import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { BaseComponent, removeSpaces, formatDate} from 'src/app/base/base.component';

@Component({
  selector: 'app-update-version',
  templateUrl: './update-version.component.html',
  styleUrls: ['./update-version.component.scss']
})


export class UpdateVersionComponent extends BaseComponent implements OnInit {

  dtOptions: DataTables.Settings;
  dataTable: any = []
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective
  dtTrigger = new Subject();

  Upload = new FormGroup({
    ModuleCode: new FormControl('',[Validators.required]),
    VersionID: new FormControl(0),
    Revision: new FormControl('',[Validators.required]),
    UpdateNote: new FormControl(''),  //Noi dung cap nhat
    UpdateError: new FormControl(''),
  });

  searchForm = new FormGroup({
    CustomerCode: new FormControl(''),
    CustomerName: new FormControl(''),
    ModuleCode: new FormControl(''),
    UserName: new FormControl(''),
    FromDate: new FormControl(''),
    ToDate: new FormControl('')
  });

  ngOnInit(): void {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.UserID_get = a.Info.UserID;
    this.UserName_get = a.Info.UserName;
    this.Token = a.Token;
    /**-------------------------USE dtOptions to format table-------------------------*/
    // Filter DATA TABLE
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: true,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        },
      },
      columns: [
        {
          title: 'STT',
          width: "4vh",
          className: "dt-center"
        },
        {
          title: 'Mã khách hàng',
          width: "20vh",
          className: "dt-center"
        },
        {
          title: 'Tên khách hàng',
          width: "20vh",
        },
        {
          title: 'Phân hệ',
          width: "10vh",
          className: "dt-center"
        },
        {
          title: 'Cập nhật lần cuối',
          className: "dt-center",
          width: "20vh",
        },
        {
          title: 'Người cập nhật',
          width: "10vh",
          className: "dt-center",
        },
        {
          title: 'Thao tác',
          width: '10vh',
          className: "dt-center"
        },
      ],
    };
    this.GetAll();
    this.GetAllModule();
    this.GetAllCustomer();
  }

  Search() {
    this.GetAll();
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      }
    );
  }

  GetAllModule() {
    this.ModuleService.Load_List(this.Token).subscribe(
      (res) => {
        this.dataModule = res.listModules;
        this.dataModule.sort((a,b) => (a.ModuleCode > b.ModuleCode) ? 1 : ((b.ModuleCode > a.ModuleCode) ? -1 : 0))
      }
    );
  }

  GetAllCustomer() {
    this.CustomerService.Load_List(this.Token).subscribe(
      (res) => {
        this.dataCustomer = res.listCustomers;
        this.dataCustomer.sort((a,b) => (a.CustomerName > b.CustomerName) ? 1 : ((b.CustomerName > a.CustomerName) ? -1 : 0))
      }
    );
  }

  GetAll(){
    let dataSearch = {
      UserID: this.UserID_get,
      CustomerCode : this.searchForm.value.CustomerCode,
      CustomerName : this.searchForm.value.CustomerName,
      ModuleCode : this.searchForm.value.ModuleCode,
      FromDate : this.searchForm.value.FromDate,
      ToDate : this.searchForm.value.ToDate,
      UserName : this.searchForm.value.UserName,
      Internal: this.optionInternal
    }
    this.UpdateVersionService.UpdateVersion_UserID(dataSearch,this.Token)
    .subscribe(z => {
      this.dataTable = z.listUpdateVersions;
      this.dtTrigger.next();
    });
  }

  open(content, sizm, type, Data) {
    this.submitted = false;
    this.selected_ID = Data.CustomerID;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (type == "Detail") {
      this.selected_ID = Data.CustomerID;
      this.titleModal = Data.CustomerName;
      this.selected_ID = Data.CustomerID;
    }
    if (type == "Upload") {
      this.UpdateVersionService.Get_Module_CustomerID(this.selected_ID, this.Token)
      .subscribe(z => {
        this.listModuleByCustomerID = z.listCustomerModules;
      });
      this.VersionService.Load_List(this.Token)
      .subscribe(z => {
        this.versions = z.listVersions;
        this.version_max = Math.max(...this.versions.map(x => x.VersionID));
        this.Upload.patchValue({VersionID: this.version_max})
      });
      this.spinner.show();
      this.Upload.patchValue({
        ModuleCode: '',
        Revision: '',
        UpdateNote: '',
        UpdateError: '',
      })
      this.titleModal = Data.CustomerName;
      this.Url_update_get = Data.URL_update;
      this.spinner.hide();
    }
  }

  getRevision() {
    this.UpdateClientService.GetRevision(this.Upload.value.ModuleCode,this.Token).subscribe(
      (res) => {
        this.Revision_get = res.Data;
        if (!(parseInt(this.Revision_get) >= 0)) {
          this.toastr.warning(res.Message);
          this.Upload.patchValue({
            Revision: ''
          })
        }
        else {
          this.Upload.patchValue({
            Revision: this.Revision_get
          })
        }
      }
    );
  }

  get checkvalueUpload() {
    return this.Upload.controls;
  }

  Save() {
    this.submitted = true;
    if (this.Upload.invalid) {
      return false;
    }
    var ModuleID_get = this.listModuleByCustomerID.filter(x => x.ModuleCode == this.Upload.value.ModuleCode).map(x => x.ModuleID);
    var VersionCode_get = this.versions.filter(x => x.VersionID ==  this.Upload.value.VersionID).map(x => x.VersionCode);
    let req = {
      ModuleCode : this.Upload.value.ModuleCode,
      UserName: this.UserName_get,
      VersionID : this.Upload.value.VersionID,
      Revision : this.Upload.value.Revision,
      UpdateNote : this.Upload.value.UpdateNote,
      access_token: this.Token,
      postURL: this.Url_update_get,
      UserID: this.UserID_get,
      CustomerID: this.selected_ID,
      ModuleID: ModuleID_get[0],
      VersionCode: VersionCode_get[0]
    };
    // var Message = [
    //   {
    //     "Message": "Cập nhật thất bại !",
    //     "Result": "True",
    //     "Token": "null"
    //   }
    // ];
    /**--------------INSERT--------------- */
    this.spinner.show();
    this.UpdateClientService.UpdateVersion(req, this.Token).subscribe(
      (z) => {
        if (z.Status == 2) {
          this.toastr.warning(z.Message);
          this.Upload.patchValue({
            UpdateError: z.Message
          })
          this.spinner.hide();
        } else if (z.Status == 1) {
          var message = z.Message;
          var messge_insert = JSON.parse(message)[0].Message;
          var result_insert = JSON.parse(message)[0].Result;
          /**--------------INSERT to TABLE htUpdateVersion--------------- */
          if (!result_insert || result_insert == "false") {
            this.Revision_get = 0;           
          };
          let req_isnert = {
            UserID: this.UserID_get,
            CustomerID: this.selected_ID,
            ModuleID: ModuleID_get[0],
            VersionID : this.Upload.value.VersionID,
            Revision : this.Revision_get,
            UpdateNote: this.Upload.value.UpdateNote,
            UpdateResult: result_insert,
            UpdateError: messge_insert
          }
          this.UpdateVersionService.UpdateVersion_Insert(req_isnert, this.Token).subscribe(
            (z) => {
              if (z.Status == 2) {
                this.spinner.show();
                this.toastr.warning(z.Message);
                this.Upload.patchValue({
                  UpdateError: z.Message
                })
                this.spinner.hide();
              } else if (z.Status == 1) {
                this.toastr.success(messge_insert);
                this.Upload.patchValue({
                  UpdateError: messge_insert
                })
                this.spinner.hide();
              } else {
                this.spinner.show();
                this.toastr.warning(z.Message);
                this.Upload.patchValue({
                  UpdateError: z.Message
                })
                this.spinner.hide();
            }
          })
          this.spinner.hide();
        } else {
          this.spinner.show();
          this.toastr.warning(z.Message);
          this.Upload.patchValue({
            UpdateError: z.Message
          })
          this.spinner.hide();
        }
        this.GetAll();
        this.datatableElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          }
        );
        this.spinner.hide();
      },
      (err) => {
        if (err.status == 401) {
          this.spinner.show();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.Upload.patchValue({
            UpdateError: 'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          })
          this.spinner.hide();
        }
      }
    );
  }

  Excel(){
    let dataExport = {
      UserID: this.UserID_get,
      CustomerCode: this.searchForm.value.CustomerCode,
      CustomerName: this.searchForm.value.CustomerName,
      ModuleCode: this.searchForm.value.ModuleCode,
      UserName: this.searchForm.value.UserName,
      FromDate: this.searchForm.value.FromDate,
      ToDate: this.searchForm.value.ToDate,
      Internal: this.optionInternal
    }
    var r = confirm("File của bạn sẽ được tải xuống trong giây lát!");
    if (r) {
      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      this.spinner.show();            
      this.UpdateVersionService.Excel(dataExport,a.Token)
        .subscribe(z => {
          if (z.Status == 1) {
            this.ExportService.exportExcelByte(z.FileData, 'DanhSachUpdate');
            this.toastr.success("File đã được tải xuống", "Tác vụ thành công")
            this.spinner.hide();
          }
          else {
            this.toastr.error(z.Message, "Tác vụ thất bại")
            this.spinner.hide();
          }
        });
    };
  }

  changeType(data) {
    if (data.internal == null) {
      this.spinner.show();
      this.optionName = "Lọc khách hàng";
      this.optionInternal = null;
      this.GetAll();
      this.datatableElement.dtInstance.then(
        (dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        }
      );    
    }
    else {
      this.spinner.show();
      this.optionName = data.name;
      this.optionInternal = data.internal;
      this.GetAll();
      this.datatableElement.dtInstance.then(
        (dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        }
      );    
    }
    this.spinner.hide();
  }
  
  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn['dataTable'].ext.search.pop();
    });
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let CustomerCode_data = data[1];
      let CustomerName_data = data[2];
      let ModuleCode_data = data[3];
      let UserName_data = data[5];
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let bool4 = true;

      if (this.searchForm.value.CustomerCode != '') {
        bool1 = CustomerCode_data == this.searchForm.value.CustomerCode;
      }
      if (this.searchForm.value.CustomerName != '') {
        bool2 = CustomerName_data == this.searchForm.value.CustomerName;
      }
      if (this.searchForm.value.ModuleCode != '') {
        bool3 = ModuleCode_data == this.searchForm.value.ModuleCode;
      }
      if (this.searchForm.value.UserName != '') {
        bool4 = this.xoa_dau(UserName_data.toLowerCase()).includes(this.xoa_dau(this.searchForm.value.UserName.toLowerCase()));
      }
      if (bool1 && bool2 && bool3 && bool4) {
        return true;
      }
      return false;
    });
  }
}
