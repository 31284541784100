import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { BaseComponent, removeSpaces, formatDate} from 'src/app/base/base.component';

@Component({
  selector: 'app-user-function',
  templateUrl: './user-function.component.html',
  styleUrls: ['./user-function.component.scss']
})
export class UserFunctionComponent extends BaseComponent implements OnInit {

  dtOptions: DataTables.Settings;
  dataTable: any = []
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective
  dtTrigger = new Subject();

  ngOnInit(): void {
    this.getToken();
    /**-------------------------USE dtOptions to format table-------------------------*/
    // Filter DATA TABLE
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: true,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        },
      },
      columns: [
        {
          title: 'STT',
          className: "dt-center"
        },
        {
          title: 'Mã quyền',
          className: "dt-center",
        },
        {
          title: 'Tên quyền',
          className: "dt-center",
        },
      ],
    };
    this.getListFunctionByUserID();
  }

  getListFunctionByUserID() {
    this.FunctionService.Get_Function_UserID(this.UserID_get,this.Token)
      .subscribe(z => {
        this.dataTable = z.listFunctions;
        this.dtTrigger.next();
    });
  }

}
