<!-- Navbar -->
<nav class="main-header navbar navbar-expand bg-info navbar-light">
  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" (click)="toggleMenuSidebar.emit()" style="color: white;"
        ><i class="fas fa-bars cus-icon"></i
      ></a>
    </li>
    <li class="nav-item d-none d-sm-inline-block">
      <a [routerLink]="['/admin']" class="nav-link" style="color: white;">Dashboard</a>
    </li>
    <!-- <li class="nav-item d-none d-sm-inline-block">
      <a [routerLink]="['/blank']" class="nav-link">Contact</a>
    </li> -->
  </ul>

  <!-- Right navbar links -->
  <ul class="navbar-nav ml-auto">
    <!-- Messages Dropdown Menu -->
    <app-user-dropdown-menu></app-user-dropdown-menu>
    <!-- Notifications Dropdown Menu -->
    <!-- <app-notifications-dropdown-menu></app-notifications-dropdown-menu> -->
    <li class="nav-item">
      <a class="nav-link" (click)="logout()" style="color: white;">
        <i class="fas fa-power-off cus-icon"></i>
      </a>
    </li>
  </ul>
</nav>
<!-- /.navbar -->
