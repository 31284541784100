<section class="content-header" style="padding: 0.5% !important;" *ngIf="IsValid">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/admin/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">Execute SQLScript (Dành riêng cho kỹ thuật)</li>
                </ol>
            </div>
            <div class="col-md-6">
                <!-- <button class="btn btn-success btn-sm" (click)="open(ThemModal, 'xl', 'Add', '')" style="float: right; margin-left: 10px;">
                      <i class="fas fa-users-cog"></i> Xếp phòng tự động
                  </button> -->
                <button class="btn btn-success btn-sm" (click)="ExportExcel()" style="float: right;"> 
                    <i class="fas fa-file-download"></i> Export Excel
                </button>
                <button class="btn btn-success btn-sm" (click)="Execute()"
                    style="float: right; margin-right: 10px; background-color: #4c6ef5;">
                    <i class="fas fa-database"></i> Execute
                </button>
            </div>
        </div>
    </div>
</section>
<section class="content cus-body" *ngIf="IsValid">
    <div class="card">
        <div class="card-body">
            <form class="form-horizontal" [formGroup]="Filter">
                <div class="row">
                    <div class="col-md-12">
                        <label>Mật khẩu kỹ thuật viên</label>
                        <input type="password" formControlName="Password" class="form-control"
                            placeholder="Mật khẩu Kỹ thuật viên" />
                    </div>
                    <div class="col-md-12">
                        <label>Script</label>
                        <textarea rows="10" formControlName="QuerryString" class="form-control"> </textarea>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label>Result</label>
                        <textarea rows="10" value="{{Message}}" class="form-control"> </textarea>
                    </div>
                </div>
            </form>

        </div>
    </div>
</section>