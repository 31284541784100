import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UpdateVersionService {

  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) { }

  GetUpdateVersionList(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'UpdateVersion/GetUpdateVersionList', {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  UpdateVersion_UserID(req:any, token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'UpdateVersion/UpdateVersion_UserID',req, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  UpdateVersion_CustomerID(req:any, token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'UpdateVersion/UpdateVersion_CustomerID',req, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }
  Get_Module_CustomerID(ID_customer:number, token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Customer_Module/Load_List?CustomerID='+ID_customer, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  UpdateVersion_Insert(req: any, token) {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'UpdateVersion/UpdateVersion_Insert',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  GetRevision(ModuleName:string, token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'UpdateVersion/GetReversion?ModuleName='+ModuleName, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  Delete(req: any, token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'UpdateVersion/Delete',req, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  Excel(req: any, token) {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Excel/UpdateVersionUser_Excel',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }
  
  Excel_History(req: any, token) {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Excel/HistoryUpdate_Excel',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  Revision_search(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'UpdateVersion/Revision_search', {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

}
