import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { Subject } from 'rxjs';
import { BaseComponent, removeSpaces, formatDate} from 'src/app/base/base.component';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent extends BaseComponent implements OnInit {

  dtOptions: DataTables.Settings;
  dataTable: any = []
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective
  dtTrigger = new Subject();

  searchForm = new FormGroup({
    TypeID: new FormControl(0),
    Tu_ngay: new FormControl(''),
    Den_ngay: new FormControl(''),
    Trang_thai: new FormControl(1),
    Limit: new FormControl(2),
  })

  ngOnInit(): void {
    this.getToken();
    /**-------------------------USE dtOptions to format table-------------------------*/
    // Filter DATA TABLE
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: true,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        },
      },
      columns: [
        {
          title: 'STT',
          width: '4vh',
          className: "dt-center"
        },
        {
          title: 'Ngày giao dịch',
          className: "dt-center",
        },
        {
          title: 'Mã đối tác',
          className: "dt-center",
        },
        {
          title: 'Mã đại lý',
          className: "dt-center",
        },
        {
          title: 'Mã giao dịch',
          className: "dt-center",
        },
        {
          title: 'Nội dung giao dịch',
          className: "dt-center",
        },
        {
          title: 'Số tiền',
          className: "dt-center",
        },
        {
          title: 'Thao tác',
          width: '10vh',
          className: "dt-center"
        },
      ],
    };
    this.statisticByDate();
    this.getList();
    this.statistic();
    this.getListCustomerType();
  }

  search() {
    this.getList();
    this.statistic();
  }

  getList() {
    this.spinner.show();
    this.TransactionService.getList(this.searchForm.value, this.Token).subscribe(
      (res) => {
        this.dataTable = res.Data;
        this.spinner.hide();
      }
    );
  }

  statistic() {
    this.spinner.show();
    this.TransactionService.statistic(this.searchForm.value, this.Token).subscribe(
      (res) => {
        this.dataStatistic = res.Data;
        this.sum_transaction = this.dataStatistic.map(x => x.So_giao_dich).reduce((partialSum, a) => partialSum + a, 0);
        this.sum_money = this.dataStatistic.map(x => x.Tong_so_tien).reduce((partialSum, a) => partialSum + a, 0);
        this.dataChart = this.dataStatistic;
        this.spinner.hide();
        this.Linechart = new Chart('canvas', {  
          type: 'bar',  
          data: {  
            labels: this.dataChart.map(x => x.Ngay_thang),               
            datasets: [  
              {  
                borderColor: '#3cb371',  
                backgroundColor: "#17a2b8",  
                fill: true,
                data: this.dataChart.map(x => x.So_giao_dich),  
              }  
            ]  
          },  
          options: {  
            responsive: true,
            legend: {  
              display: false  
            },  
            scales: {  
              xAxes: [{  
                position: 'bottom',
                scaleLabel: {
                  labelString: 'Ngày tháng',
                  display: true,
                }
              }],  
              yAxes: [{  
                type: 'linear',
                scaleLabel: {
                  labelString: 'Số giao dịch',
                  display: true,
                }             
              }],  
            }  
          }  
        }); 
      }
    );
  }

  getListCustomerType() {
    this.spinner.show();
    this.CustomerTypeService.Load_List(this.Token).subscribe(
      (res) => {
        this.dataType = res.Data;
        this.spinner.hide();
      }
    );
  }

  statisticByDate() {
    this.filterDate(this.searchForm.value.Limit);
    this.searchForm.patchValue({
      Tu_ngay: this.dayBefore,
      Den_ngay: this.currentDate,
    })
  }
}
