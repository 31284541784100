import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { BaseComponent, removeSpaces, formatDate} from 'src/app/base/base.component';

@Component({
  selector: 'app-updated-customer',
  templateUrl: './updated-customer.component.html',
  styleUrls: ['./updated-customer.component.scss']
})
export class UpdatedCustomerComponent extends BaseComponent implements OnInit {

  dtOptions: DataTables.Settings;
  dataTable: any = []
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective
  dtTrigger = new Subject();

  searchForm = new FormGroup({
    CustomerCode: new FormControl('',removeSpaces),
    CustomerName: new FormControl('',removeSpaces),
    Url_update: new FormControl('',removeSpaces),
    DatabaseName: new FormControl('',removeSpaces),
    ServerName: new FormControl('',removeSpaces),
    TypeName: new FormControl('',removeSpaces),
    FromDate: new FormControl('',removeSpaces),
    ToDate: new FormControl('',removeSpaces)
  });

  ngOnInit(): void {
    this.getToken();
    /**-------------------------USE dtOptions to format table-------------------------*/
    // Filter DATA TABLE
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: true,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        },
      },
      columns: [
        {
          title: 'STT',
          className: "dt-center",
          visible: false
        },
        {
          title: 'Mã khách hàng',
          className: "dt-center",
        },
        {
          title: 'Tên khách hàng',
        },
        {
          title: 'Loại khách hàng',
          visible: false,
        },
        {
          title: 'URL',
        },
        {
          title: 'Database',

        },
        {
          title: 'Server',
        },
        {
          title: 'Cập nhật lần cuối',
        },
        {
          title: 'Phân hệ',
          className: "dt-center",
        },
      ],
    };
    this.GetAll();
  }
  
  Search() {
    this.GetAll();
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      }
    );
  }

  GetAll() {
    let dataSearch = {
      UserID: this.UserID_get,
      CustomerCode : this.searchForm.value.CustomerCode,
      CustomerName : this.searchForm.value.CustomerName,
      Url_update : this.searchForm.value.Url_update,
      DatabaseName : this.searchForm.value.DatabaseName,
      ServerName: this.searchForm.value.ServerName,
      FromDate : this.searchForm.value.FromDate,
      ToDate : this.searchForm.value.ToDate,
    }
    this.CustomerService.Customer_Updated(dataSearch,this.Token)
    .subscribe(z =>{
      this.spinner.show();
      this.dataTable = z.Data1;
      this.dtTrigger.next();

      this.dataCustomerCodeFilter = [...new Set(this.dataTable.map(x => x.CustomerCode))];
      this.dataCustomerCodeFilter = this.dataCustomerCodeFilter.sort((a, b) => a.localeCompare(b));

      this.dataDBFilter = [...new Set(this.dataTable.map(x => x.DatabaseName))];
      this.dataDBFilter = this.dataDBFilter.sort((a, b) => a.localeCompare(b));

      this.dataServerFilter = [...new Set(this.dataTable.map(x => x.ServerName))];
      this.dataServerFilter = this.dataServerFilter.sort((a, b) => a.localeCompare(b));

      this.dataTypeNameFilter = [...new Set(this.dataTable.map(x => x.TypeName))];
      this.dataTypeNameFilter = this.dataTypeNameFilter.sort((a, b) => a.localeCompare(b));
      this.spinner.hide();
    })
  }

  open(content, sizm, type, Data) {
    this.submitted = false;
    this.selected_ID = Data.CustomerID;
    this.modalService
    .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
    .result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    ); 
    if (type == "ViewModule") {
      this.itemSelected = false;
      this.spinner.show();
      this.selected_ID = Data.CustomerID;
      this.spinner.hide();
    }
  }

  Excel(){
    let dataExport = {
      UserID: this.UserID_get,
      CustomerCode: this.searchForm.value.CustomerCode,
      CustomerName: this.searchForm.value.CustomerName,
      TypeName: this.searchForm.value.TypeName,
      ServerName: this.searchForm.value.ServerName,
      Url_update: this.searchForm.value.Url_update,
      DatabaseName: this.searchForm.value.DatabaseName,
      FromDate: this.searchForm.value.FromDate,
      ToDate: this.searchForm.value.ToDate,
    }
    var r = confirm("File của bạn sẽ được tải xuống trong giây lát!");
    if (r) {
      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      this.spinner.show();            
      this.CustomerService.CustomerUserUpdated_Excel(dataExport,a.Token)
        .subscribe(z => {
          if (z.Status == 1) {
            this.ExportService.exportExcelByte(z.FileData, 'DanhSachKhachHangDaCapNhat');
            this.toastr.success("File đã được tải xuống", "Tác vụ thành công")
            this.spinner.hide();
          }
          else {
            this.toastr.error(z.Message, "Tác vụ thất bại")
            this.spinner.hide();
          }
        });
    };
  }

  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn['dataTable'].ext.search.pop();
    });
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let CustomerCode_data = data[1];
      let CustomerName_data = data[2];
      let TypeName_data = data[3];
      let Url_update_data = data[4];
      let DatabaseName_data = data[5];;
      let ServerName_data = data[6];
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let bool4 = true;
      let bool5 = true;
      let bool6 = true;

      if (this.searchForm.value.CustomerCode != '') {
        bool1 = CustomerCode_data == this.searchForm.value.CustomerCode;
      }
      if (this.searchForm.value.CustomerName != '') {
        bool2 = this.xoa_dau(CustomerName_data.toLowerCase()).includes(this.xoa_dau(this.searchForm.value.CustomerName.toLowerCase()));
      }
      if (this.searchForm.value.TypeName != '') {
        bool3 = TypeName_data == this.searchForm.value.TypeName;
      }
      if (this.searchForm.value.Url_update != '') {
        bool4 = this.xoa_dau(Url_update_data.toLowerCase()).includes(this.xoa_dau(this.searchForm.value.Url_update.toLowerCase()));
      }
      if (this.searchForm.value.DatabaseName != '') {
        bool5 = DatabaseName_data == this.searchForm.value.DatabaseName;
      }
      if (this.searchForm.value.ServerName != '') {
        bool6 = ServerName_data == this.searchForm.value.ServerName;
      }
      if (bool1 && bool2 && bool3 && bool4 && bool5 && bool6) {
        return true;
      }
      return false;
    });
  }
}
