
  <!----------------------------------------------- HEADER ------------------------------------------------->
<section class="content-header" style="padding:0.5%  !important">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-md-6">
          </div>
          <div class="col-md-6">
            <button class="btn btn-outline-warning btn-sm float-right" (click)="DeleteHistory()">
              <i class="fas fa-eraser"></i> Xóa lịch sử
            </button>
            <button class="btn btn-outline-success btn-sm" style="float: right;margin-right: 5px;" (click)="Excel()">
                <i class="fas fa-file-excel"></i> Xuất báo cáo
            </button>
            <button class="btn btn-outline-info btn-sm" style="float: right;margin-right: 5px;" (click)="Search()">
                <i class="fas fa-search"></i> Tìm kiếm
            </button>
          </div>
      </div>
  </div>
</section>
<!----------------------------------------------- FILTER ------------------------------------------------->
<section class="content cus-body ">
  <div class="card">
      <div class="card-body">
          <form [formGroup]="searchForm">
            <div class="row">
              <div class="col-md-2">
                <label class="col-md-10 " style="margin-top: 10px;">Mã phân hệ</label>
                <ng-select formControlName="ModuleCode" (change)="filterById()" notFoundText="Không có kết quả">
                    <ng-option value="">Tất cả</ng-option>
                    <ng-option *ngFor="let module of dataModuleCodeFilter" [value]=module.ModuleCode>{{module.ModuleCode}}</ng-option>
                </ng-select>
              </div>
              <div class="col-md-2">
                <label class="col-md-10 " style="margin-top: 10px;">Phiên bản</label>
                <ng-select formControlName="VersionName" (change)="filterById()" notFoundText="Không có kết quả">
                    <ng-option value="">Tất cả</ng-option>
                    <ng-option *ngFor="let version of dataVersionNameFilter" [value]=version.VersionName>{{version.VersionName}}</ng-option>
                </ng-select>
              </div>
              <div class="col-md-2">
                <label class="col-md-10 " style="margin-top: 10px;">Revision</label>
                <ng-select formControlName="Revision" (change)="filterById()" notFoundText="Không có kết quả">
                    <ng-option value="">Tất cả</ng-option>
                    <ng-option value="0">0</ng-option>
                    <ng-option *ngFor="let revision of dataRevisionFilter" [value]=revision.Revision>{{revision.Revision}}</ng-option>
                </ng-select>
              </div>
              <div class="col-md-2">
                <label class="col-md-10 " style="margin-top: 10px;">Từ ngày</label>
                <input type="date" class="form-control" formControlName="FromDate">
              </div>
              <div class="col-md-2">
                <label class="col-md-10 " style="margin-top: 10px;">Đến ngày</label>
                <input type="date" class="form-control" formControlName="ToDate">
              </div>
              <div class="col-md-2">
                <label class="col-md-10 " style="margin-top: 10px;">Tên đăng nhập</label>
                <input type="text" class="form-control" placeholder="Nhập tên đăng nhập" formControlName="UserName" (keyup)="filterById()">
              </div>
            </div>
      </form>
      </div>
  </div>
</section>
<!----------------------------------------------- TABLE ------------------------------------------------->
<section class="content cus-body">
  <div class="card">
      <div class="card-body">
          <div class="tab-content">
              <div class="active tab-pane">
                  <div class="row">
                      <div class="col-md-12">
                          <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                              <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="maindata"
                                  class="table-bordered dataTables_wrapper no-footer">
                                    <thead>
                                      <tr>
                                          <th>
                                            <input type="checkbox" (click)="chonall()" name="SelectALL"
                                            id="SelectALL" value="SelectAll" />
                                          </th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let update of dataTable; let i=index;">
                                      <td>
                                        <input type="checkbox" (change)="SelectRow(update.UpdateID)"
                                        name="checkbox{{ update.UpdateID }}" id="checkbox{{ update.UpdateID }}"
                                        [checked]="update.Selected" value="{{ update.UpdateID }}" />
                                      </td>
                                      <td>{{update.UpdateID}}</td>
                                      <td style="text-align: center;">{{i+1}}</td>
                                      <td>{{update.ModuleCode}}</td>
                                      <td>{{update.VersionName}}</td>
                                      <td>{{update.Revision}}</td>
                                      <td>{{update.UpdateDate | date:'dd/MM/yyyy, h:mm a'}}</td>
                                      <td style="word-wrap: break-word;max-width: 200px;">{{update.UpdateNote}}</td>
                                      <td style="word-wrap: break-word;max-width: 400px;">{{update.UpdateError}}</td>
                                      <td>{{update.UserName}}</td>
                                      <td>{{update.Result}}</td>
                                    </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>

