import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { BaseComponent, removeSpaces, formatDate} from 'src/app/base/base.component';

@Component({
  selector: 'app-user-manager',
  templateUrl: './user-manager.component.html',
  styleUrls: ['./user-manager.component.scss'],
})
export class UserManagerComponent extends BaseComponent implements OnInit {

  dtOptions: DataTables.Settings;
  dataTable: any = []
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective
  dtTrigger = new Subject();

  AddForm = new FormGroup({
    UserName_Add: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
      removeSpaces
    ]),
    Password_Add: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
      removeSpaces
    ]),
    FullName_Add: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
      removeSpaces
    ]),
    Admin_Add: new FormControl(null),
    Active_Add: new FormControl(''),
    Email_Add: new FormControl('',[
      removeSpaces
    ]),
  });

  UpdateForm = new FormGroup({
    FullName_Update: new FormControl('',[
      Validators.required,
      Validators.maxLength(50),
      removeSpaces    
    ]),
    Admin_Update: new FormControl(''),
    Active_Update: new FormControl(''),
    Email_Update: new FormControl('',[
      removeSpaces
    ]),
  });

  searchForm = new FormGroup({
    UserName: new FormControl(''),
    FullName: new FormControl(''),
    Ho_ten: new FormControl(''),
    Active: new FormControl(''),
    Trang_thai: new FormControl(''),
  });

  ngOnInit(): void {
    this.getToken();
    /**-------------------------USE dtOptions to format table-------------------------*/
    // Filter DATA TABLE
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: true,
      language: {
        processing: 'Đang xử lý...',
        lengthMenu: 'Xem _MENU_ mục',
        emptyTable: 'Không có dữ liệu!',
        info: 'Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục',
        infoEmpty: 'Đang xem 0 đến 0 trong tổng số 0 mục',
        infoFiltered: '(được lọc từ _MAX_ mục)',
        infoPostFix: '',
        search: 'Tìm kiếm nhanh:',
        url: '',
        searchPlaceholder: 'Nhập từ khóa cần tìm...',
        paginate: {
          first: 'Đầu',
          previous: 'Trước',
          next: 'Tiếp',
          last: 'Cuối',
        },
      },
      columns: [
        {
          title: 'STT',
          width: '4vh',
          className: 'dt-center',
        },
        {
          title: 'Tên đăng nhập',
          width: '10vh',
        },
        {
          title: 'Họ và tên',
          width: '10vh',
        },
        {
          title: 'Email',
          width: '6vh',
        },
        {
          title: 'Admin',
          className: 'dt-center',
          width: '4vh',
        },
        {
          title: 'Active',
          className: 'dt-center',
          width: '4vh',
        },
        {
          title: 'Trang_thai',
          visible: false
        },
        {
          title: 'Thao tác',
          width: '100px',
          className: 'dt-center',
        },
        {
          title: 'Active',
          visible: false
        },
      ],
    };

    this.getListAll();
  }

  getListAll() {
    this.spinner.show();
    this.UserService.GetUserList(this.Token).subscribe((z) => {
      if(z.Status == 1) {
        this.dataTable = z.listUsers;
        this.dtTrigger.next();
        this.spinner.hide();
      }else {
        this.toastr.warning(z.Message);
        this.spinner.hide();
      }
    },
      (err) => {
        if (err.status == 401) {
          this.spinner.show();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.spinner.hide();
        }
      }
    );
  }

  open(content, sizm, type, Data) {
    this.submitted = false;
    this.AddForm.reset();
    this.selected_ID = Data.UserID;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );

    if (type == 'Add') {
      this.checkInsert = true;
      this.titleModal = 'Thêm mới';
    }
    if (type == 'Update') {
      this.spinner.show();
      this.titleModal = 'Cập nhật';
      this.checkInsert = false;
      this.UpdateForm.patchValue({
        FullName_Update: Data.FullName,
        Admin_Update: Data.Admin,
        Active_Update: Data.Active,
        Email_Update: Data.Email,
      });
      this.spinner.hide();
    }
    if (type == 'Delete') {
      this.selected_ID = Data.UserID;
    }
    if (type == 'ViewFunction') {
      this.searchFunction = '';
      this.itemSelected = false;
      this.spinner.show();
      this.selected_ID = Data.UserID;
      this.FunctionService.Load_List(this.Token).subscribe((z) => {
        this.functions = z.listFunctions;
        this.dataFunctionFilter = z.listFunctions;
        this.UserService.GetFunction_UserID(this.selected_ID,this.Token)
        .subscribe((z) => {
          this.listFunctionByUserID = z.listFunctions;
          this.itemSelected = this.listFunctionByUserID.length == this.functions.length ? true : false;
          this.ID_function_selected = this.listFunctionByUserID.map(
            (x) => x.FunctionID
          );
          for (var i = 0; i < this.ID_function_selected.length; i++) {
            for (var j = 0; j < this.functions.length; j++) {
              if(this.functions[j].FunctionID == this.ID_function_selected[i]){
                this.functions[j].checked = true;
              }
            }
          }
          var function_checked = this.functions.filter(value=>value.checked==true).map(
            (x) => x.FunctionID
          );
          this.count_function = function_checked.length;
        });
      });
      this.spinner.hide();
    }
    if (type == 'ViewCustomer') {
      this.searchCustomer = '';
      this.customerSelected = false;
      this.spinner.show();
      this.selected_ID = Data.UserID;
      this.CustomerService.Load_List(this.Token).subscribe((z) => {
        this.customers = z.listCustomers;
        this.dataCustomerFilter = z.listCustomers;
        this.UserService.Customer_UserID(this.selected_ID,this.Token)
        .subscribe((z) => {
          this.listCustomerByUserID = z.listCustomers;
          this.customerSelected = this.listCustomerByUserID.length == this.customers.length ? true : false;
          this.ID_customer_selected = this.listCustomerByUserID.map(
            (x) => x.CustomerID
          );
          for (var i = 0; i < this.ID_customer_selected.length; i++) {
            for (var j = 0; j < this.customers.length; j++) {
              if(this.customers[j].CustomerID == this.ID_customer_selected[i]){
                this.customers[j].checked = true;
              }
            }
          }
          var customer_checked = this.customers.filter(value=>value.checked==true).map(
            (x) => x.CustomerID
          );
          this.count_customer = customer_checked.length;
        });
      });
      this.spinner.hide();
    }
  }

  changeFunction() {
    if (this.searchFunction != '') {
      this.functions = this.dataFunctionFilter.filter(x => this.xoa_dau(x.FunctionName.toLowerCase()).includes(this.xoa_dau(this.searchFunction.toLowerCase())));
      this.itemSelected = this.listFunctionByUserID.length == this.functions.length ? true : false;
    }
    else {
      this.functions = this.dataFunctionFilter;
      this.itemSelected = this.listFunctionByUserID.length == this.functions.length ? true : false;
    }
    var function_checked = this.functions.filter(value=>value.checked==true)
    this.count_function = function_checked.length;
  }

  changeCustomer() {
    if (this.searchCustomer != '') {
      this.customers = this.dataCustomerFilter.filter(x => this.xoa_dau(x.CustomerName.toLowerCase()).includes(this.xoa_dau(this.searchCustomer.toLowerCase())));
      this.customerSelected = this.listCustomerByUserID.length == this.customers.length ? true : false;
    }
    else {
      this.customers = this.dataCustomerFilter;
      this.customerSelected = this.listCustomerByUserID.length == this.customers.length ? true : false;
    }
    var customer_checked = this.customers.filter(value=>value.checked==true)
    this.count_customer = customer_checked.length;
  }

  checkUncheckAll() {
    for (var i = 0; i < this.functions.length; i++) {
      this.functions[i].checked = this.itemSelected;
    }
    this.total_count_function();
  }

  checkUncheckCustomerAll() {
    for (var i = 0; i < this.customers.length; i++) {
      this.customers[i].checked = this.customerSelected;
    }
    this.total_count_customer();
  }

  isAllSelected() {
      this.itemSelected = this.listFunctionByUserID.every(function(item:any) {
          return item.checked == true;
      }
    )
  }

  isAllCustomerSelected() {
    this.customerSelected = this.listCustomerByUserID.every(function(customer:any) {
        return customer.checked == true;
      }
    )
  }

  unCheckFunction() {
    $('#SelectALL').prop('checked', false);
  }

  unCheckCustomer() {
    $('#SelectALLCustomer').prop('checked', false);
  }

  DestroyCheckCustomer() {
    this.customerSelected = false;
    this.checkUncheckCustomerAll();
  }
  DestroyCheckFunction() {
    this.itemSelected = false;
    this.checkUncheckAll();
  }

  total_count_function() {
    var function_checked = this.functions.filter(value=>value.checked==true)
    if (this.functions.filter(value=>value.checked==true)) {
      this.count_function++;
    }
    if (this.functions.filter(value=>value.checked==false)){
      this.count_function--;
    }
    this.count_function = function_checked.length;
  }

  total_count_customer() {
    var customer_checked = this.customers.filter(value=>value.checked==true)
    if (this.customers.filter(value=>value.checked==true)) {
      this.count_customer++;
    }
    if (this.customers.filter(value=>value.checked==false)){
      this.count_customer--;
    }
    this.count_customer = customer_checked.length;
  }

  UserFunctionSave(){
    // Filter checkbox get id function
    var ID_function_Filter = this.dataFunctionFilter.filter(value=>value.checked==true).map(
      (x) => x.FunctionID
    );
    let req = {
      UserID: this.selected_ID,
      list_user_function: ID_function_Filter
    }
    this.spinner.show();
    /**--------------INSERT--------------- */
    this.UserService.Insert_User_Function(req, this.Token).subscribe(
      (z) => {
        if (z.Status == 2) {
          this.spinner.show();
          this.toastr.warning(z.Message);
          this.spinner.hide();
        } else if (z.Status == 1) {
          this.spinner.show();
          this.toastr.success('Cập nhật quyền cho người dùng thành công !');
          this.getListAll();
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.spinner.hide();
        } else {
          this.spinner.show();
          this.toastr.error(z.Message);
          this.spinner.hide();
        }
      },
      (err) => {
        if (err.status == 401) {
          this.spinner.show();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.spinner.hide();
        }
      }
    );
    this.spinner.hide();
  }

  UserCustomerSave(){
    // Filter checkbox get id customer
    var ID_customer_Filter = this.dataCustomerFilter.filter(value=>value.checked==true).map(
      (x) => x.CustomerID
    );
    let req = {
      UserID: this.selected_ID,
      list_user_customer: ID_customer_Filter
    }
    this.spinner.show();
    /**--------------INSERT--------------- */
    this.UserService.Insert_User_Customer(req, this.Token).subscribe(
      (z) => {
        if (z.Status == 2) {
          this.spinner.show();
          this.toastr.warning(z.Message);
          this.spinner.hide();
        } else if (z.Status == 1) {
          this.spinner.show();
          this.toastr.success('Cập nhật khách hàng cho người dùng thành công !');
          this.getListAll();
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.spinner.hide();
        } else {
          this.spinner.show();
          this.toastr.error(z.Message);
          this.spinner.hide();
        }
      },
      (err) => {
        if (err.status == 401) {
          this.spinner.show();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.spinner.hide();
        }
      }
    );
  }

  get checkvalueInsert() {
    return this.AddForm.controls;
  }
  get checkvalueUpdate() {
    return this.UpdateForm.controls;
  }

  deleteItem() {
    this.spinner.show();
    if (this.selected_ID == this.UserID_get) {
      this.toastr.warning("Bạn không thể xóa tài khoản đang đăng nhập !");
      this.spinner.hide();
    }
    else {
      this.UserService.Delete(this.selected_ID, this.Token).subscribe(
        (z) => {
          this.modalService.dismissAll('DeleteModal');
          if (z.Status == 2) {
            this.spinner.show();
            this.toastr.warning(z.Message);
            this.spinner.hide();
          } else if (z.Status == 1) {
            this.spinner.show();
            this.toastr.success('Xóa thành công !');
            this.spinner.hide();
          } else {
            this.spinner.show();
            this.toastr.error(z.Message);
            this.spinner.hide();
          }
          this.getListAll();
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
        },
        (err) => {
          if (err.status == 401) {
            this.spinner.show();
            this.toastr.warning(
              'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
            );
            this.spinner.hide();
          }
        }
      );
    }
  }
  
  Insert() {
    this.submitted = true;
    if (this.AddForm.invalid) {
      return false;
    }
    /** variable : this.Insert.value.(value in formControl) */
    let UserInsert = {
      UserName: this.AddForm.value.UserName_Add,
      Password: this.AddForm.value.Password_Add,
      FullName: this.AddForm.value.FullName_Add,
      Admin: this.AddForm.value.Admin_Add,
      Active: this.AddForm.value.Active_Add,
      Email: this.AddForm.value.Email_Add,
    };
    this.spinner.show();
    /**--------------INSERT--------------- */
    this.UserService.Insert(UserInsert, this.Token).subscribe(
      (z) => {
        this.modalService.dismissAll('AddModal');
        if (z.Status == 2) {
          this.spinner.show();
          this.toastr.warning(z.Message);
          this.spinner.hide();
        } else if (z.Status == 1) {
          this.spinner.show();
          this.toastr.success('Thêm mới người dùng thành công !');
          this.getListAll();
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.spinner.hide();
        } else {
          this.spinner.show();
          this.toastr.error(z.Message);
          this.spinner.hide();
        }
      },
      (err) => {
        if (err.status == 401) {
          this.spinner.show();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.spinner.hide();
        }
      }
    );
    this.spinner.hide();
  }

  Update() {
    this.submitted = true;
    if (this.UpdateForm.invalid) {
      return false;
    }
    let UserUpdate = {
      UserID: this.selected_ID,
      FullName: this.UpdateForm.value.FullName_Update,
      Admin: this.UpdateForm.value.Admin_Update,
      Active: this.UpdateForm.value.Active_Update,
      Email: this.UpdateForm.value.Email_Update,
    };
    /**--------------UPDATE--------------- */
    this.spinner.show();
    this.UserService.Update(UserUpdate, this.Token).subscribe(
      (z) => {
        this.modalService.dismissAll('UpdateModal');
        if (z.Status == 2) {
          this.spinner.show();
          this.toastr.warning(z.Message);
          this.spinner.hide();
        } else if (z.Status == 1) {
          this.spinner.show();
          this.toastr.success('Cập nhật thông tin thành công !');
          this.getListAll();
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.spinner.hide();
        } else {
          this.spinner.show();
          this.toastr.error(z.Message);
          this.spinner.hide();
        }
      },
      (err) => {
        if (err.status == 401) {
          this.spinner.show();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.spinner.hide();
        }
      }
    );
  }

  Excel(){
    var r = confirm("File của bạn sẽ được tải xuống trong giây lát!");
    if (r) {
      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      this.spinner.show();            
      this.UserService.Excel(this.searchForm.value,a.Token)
        .subscribe(z => {
          if (z.Status == 1) {
            this.ExportService.exportExcelByte(z.FileData, 'DanhSachNguoiDung');
            this.toastr.success("File đã được tải xuống", "Tác vụ thành công")
            this.spinner.hide();
          }
          else {
            this.toastr.error(z.Message, "Tác vụ thất bại")
            this.spinner.hide();
          }
        });
    };
  }

  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn['dataTable'].ext.search.pop();
    });
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let UserName_data = data[1];
      let Ho_ten_data = data[2];
      let Active_data = data[8];
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;

      if (this.searchForm.value.UserName != '') {
        bool1 = this.xoa_dau(UserName_data.toLowerCase()).includes(this.xoa_dau(this.searchForm.value.UserName.toLowerCase()));
      }
      if (this.searchForm.value.FullName != '') {
        bool2 = this.xoa_dau(Ho_ten_data.toLowerCase()).includes(this.xoa_dau(this.searchForm.value.FullName.toLowerCase()));
      }
      if (this.searchForm.value.Active.toString() != '') {
        bool3 = Active_data == this.searchForm.value.Active.toString();
      }
      if (bool1 && bool2 && bool3) {
        return true;
      }
      return false;
    });
  }
}
