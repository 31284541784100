<section class="content-header" style="padding: 0.5% !important;">
  </section>
  <section class="content col-md-6" style="margin: 0 auto;">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
            <div class="card card card-primary">
                <div class="card-header no-border">
                  <div class="row">
                      <div class=" col-md-3"><h3 class="card-title">{{title}}</h3></div>
                  </div>
                </div>
                <div class="card-body login-card-body" style = "height:100% !important">
                  <form [formGroup]="ChangePasswordForm">
                    <div class="row cus-row">
                      <div class="col-md-4">
                        <label><i class="fas fa-unlock-alt"></i> &nbsp; Mật khẩu mới: </label>
                      </div>
                      <div class="input-group col-md-8">
                        <input formControlName="New_Password_Form" type="{{isPassword ? 'password' : 'text' }}" class="form-control" placeholder="Mật khẩu mới" />
                        <div class="input-group-append">
                          <span (click)="show()" class="input-group-text"><i class="far fa-eye"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="row cus-row">
                      <div class="col-md-4">
                        <label><i class="fas fa-lock"></i> &nbsp; Nhập lại mật khẩu mới: </label>
                      </div>
                      <div class="input-group col-md-8">
                        <input formControlName="Confirm_Password_Form" type="{{isPassword ? 'password' : 'text' }}" class="form-control" placeholder="Nhập lại mật khẩu mới" />
                        <div class="input-group-append">
                          <span (click)="show()" class="input-group-text"><i class="far fa-eye"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <label style="color: red;"> {{ValidatePassword}} </label>
                      </div>
                    </div>
                    <div class="row cus-row col-md-6" style="display: block; margin-left: auto; margin-right: auto">
                      <!-- /.col -->
                      <button (click)="ChangePass()" type="submit" class="btn btn-primary btn-block btn-flat"
                        style="border-radius: 12px;">
                        Lưu thay đổi
                      </button>
                      <!-- /.col -->
                    </div>
                  </form>
                </div>
                <!-- /.row -->
              </div>
            </div>
        </div>
    </div>
  </section>