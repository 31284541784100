import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { Subject } from 'rxjs';
import { BaseComponent, createDate, formatDate } from 'src/app/base/base.component';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-level-customer',
  templateUrl: './level-customer.component.html',
  styleUrls: ['./level-customer.component.scss']
})

export class LevelCustomerComponent extends BaseComponent implements OnInit {

  dtOptions: DataTables.Settings;
  dataTable: any = []
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective
  dtTrigger = new Subject();

  searchForm = new FormGroup({
    Tu_ngay: new FormControl(''),
    Den_ngay: new FormControl(''),
    TypeID: new FormControl(0),
    Limit: new FormControl(2),
  })

  ngOnInit(): void {
    this.getToken(); 
    this.statisticByDate();
    this.getList();
    this.getType();
  }

  getType() {
    this.CustomerTypeService.Load_List(this.Token).subscribe(
      (res) => {
        this.dataType = res.Data;
      }
    );
  }

  search() {
    this.getList();
  }
  
  getList() {
    this.spinner.show();
    this.LevelCustomerService.getList(this.searchForm.value,this.Token).subscribe(
      (res) => {
        this.dataTable = res.DataCustomer;
        this.dataChart = res.DataCustomer;
        this.spinner.hide();
        this.Linechart = new Chart('canvas', {  
          type: 'bar',  
          data: {  
            labels: this.dataChart.map(x => x.CustomerCode),               
            datasets: [  
              {  
                borderColor: '#3cb371',  
                backgroundColor: "#17a2b8",  
                fill: true,
                data: this.dataChart.map(x => x.So_lan_su_dung),  
              }  
            ]  
          },  
          options: {  
            responsive: true,
            legend: {  
              display: false  
            },  
            scales: {  
              xAxes: [{  
                position: 'bottom',
                scaleLabel: {
                  labelString: 'Khách hàng',
                  display: true,
                }
              }],  
              yAxes: [{  
                type: 'linear',
                scaleLabel: {
                  labelString: 'Số lần cập nhật',
                  display: true,
                }             
              }],  
            }  
          }  
        }); 
      }
    );
  }

  statisticByDate() {
    this.filterDate(this.searchForm.value.Limit);
    this.searchForm.patchValue({
      Tu_ngay: this.dayBefore,
      Den_ngay: this.currentDate,
    })
  }
}
