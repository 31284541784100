import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UpdateClientService {

  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) { }

  GetRevision(ModuleCode:any,token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'UpdateClient/Revision_Load_List?ModuleCode='+ModuleCode, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  UpdateVersion(req: any, token) {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'UpdateClient/UpdateVersion',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

}
