<!----------------------------------------------- HEADER ------------------------------------------------->
<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
            </div>
            <div class="col-md-6">
                <button class="btn btn-outline-info btn-sm" (click)="Search()" style="float: right;">
                  <i class="fas fa-search"></i> Tìm kiếm
                </button>
                <button class="btn btn-outline-success btn-sm" style="float: right;margin-right: 5px;">
                    <i class="fas fa-file-excel"></i> Xuất báo cáo
                </button>
            </div>
        </div>
    </div>
</section>
<!----------------------------------------------- FILTER ------------------------------------------------->
<section class="content cus-body ">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="searchForm">
              <div class="row">
                <div class="col-md-4">
                  <label class="col-md-10 " style="margin-top: 10px;">Database Name</label>
                  <ng-select formControlName="DatabaseName" notFoundText="Không có kết quả">
                      <ng-option value="">Tất cả</ng-option>
                      <ng-option *ngFor="let db of dataDBFilter" [value]="db">{{ db }}</ng-option>
                  </ng-select>
                </div>
                <div class="col-md-4">
                  <label class="col-md-10 " style="margin-top: 10px;">Từ ngày</label>
                  <input type="date" class="form-control" formControlName="FromDate">
                </div>
                <div class="col-md-4">
                  <label class="col-md-10 " style="margin-top: 10px;">Đến ngày</label>
                  <input type="date" class="form-control" formControlName="ToDate">
                </div>
              </div>
        </form>
        </div>
    </div>
</section>


<!----------------------------------------------- TABLE ------------------------------------------------->
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class="table-bordered dataTables_wrapper no-footer">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of dataTable">
                                            <td>{{p.DatabaseName}}</td>
                                            <td>{{p.RestoreDate | date:'dd/MM/yyyy, h:mm a'}}</td>
                                            <td>{{p.BackupStartDate | date:'dd/MM/yyyy, h:mm a'}}</td>
                                            <td>{{p.BackupFinishDate | date:'dd/MM/yyyy, h:mm a'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
