import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { BaseComponent, removeSpaces } from 'src/app/base/base.component';

@Component({
  selector: 'app-step1-modal',
  templateUrl: './step1-modal.component.html',
  styleUrls: ['./step1-modal.component.scss']
})
export class Step1ModalComponent extends BaseComponent implements OnInit {

  dataCustomerFilter: any;
  submitted: boolean = false;

  @Output() submit: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  @Input() titleModalInput: any;
  @Input() dataCustomerInput: any;
  @Input() dataResponseInput: any;
  @Input() getResponse: any;

  ngOnInit(): void {
    this.getResponse.emit();
  }

  AddForm = new FormGroup({
    DatabaseNameCreate: new FormControl('', [Validators.required,Validators.maxLength(200),removeSpaces]),
    DatabaseNameBackup: new FormControl('', [Validators.required]),
    ResponseMessage: new FormControl(''),
  })

  cancel(){
    this.close.emit()
  }

  get checkvalue() {
    return this.AddForm.controls;
  }

  filterCustomer() {
    this.spinner.show();
    this.dataCustomerFilter = this.dataCustomerInput.filter(x => x.DatabaseName == this.AddForm.value.DatabaseNameBackup);
    this.spinner.hide();
  }

  confirm() {
    this.submitted = true;
    if (this.AddForm.invalid) {
      return false;
    }
    let req = {
      DatabaseNameCreate: this.AddForm.value.DatabaseNameCreate,
      DatabaseNameBackup: this.AddForm.value.DatabaseNameBackup,
    }
    this.submit.emit(req);
  }
}
