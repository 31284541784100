<!----------------------------------------------- HEADER ------------------------------------------------->
<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
            </div>
            <div class="col-md-6">
                <button class="btn btn-outline-info btn-sm" (click)="search()" style="float: right;">
                  <i class="fas fa-search"></i> Tìm kiếm
                </button>
                <button class="btn btn-outline-success btn-sm" style="float: right;margin-right: 5px;">
                    <i class="fas fa-file-excel"></i> Xuất báo cáo
                </button>
            </div>
        </div>
    </div>
</section>
<!----------------------------------------------- FILTER ------------------------------------------------->
<section class="content cus-body" [@openClose]="isDisplay ? 'open' : 'closed'">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="searchForm">
              <div class="row">
                <div class="col-md-3">
                  <label class="col-md-10 " style="margin-top: 10px;">Sự kiện</label>
                  <ng-select style="text-transform: uppercase;" formControlName="Event" notFoundText="Không có kết quả">
                      <ng-option value="">Tất cả</ng-option>
                      <ng-option value="1">Đăng nhập</ng-option>
                      <ng-option value="2">Quản lý người dùng</ng-option>
                      <ng-option value="3">Quản lý khách hàng</ng-option>
                      <ng-option value="4">Quản lý quyền</ng-option>
                      <ng-option value="5">Quản lý phân hệ</ng-option>
                      <ng-option value="6">Update Version</ng-option>
                      <ng-option value="7">Lịch sử thao tác</ng-option>
                  </ng-select>
                </div>
                <div class="col-md-2">
                    <label class="col-md-10 " style="margin-top: 10px;">Chức năng</label>
                    <ng-select formControlName="Perform" notFoundText="Không có kết quả">
                        <ng-option value="">Tất cả</ng-option>
                        <ng-option value="1">Thêm</ng-option>
                        <ng-option value="2">Sửa</ng-option>
                        <ng-option value="3">Xóa</ng-option>
                        <ng-option value="4">Nâng cấp hệ thống</ng-option>
                        <ng-option value="5">Restore Database</ng-option>
                    </ng-select>
                  </div>
                <div class="col-md-2">
                  <label class="col-md-10 " style="margin-top: 10px;">Từ ngày</label>
                  <input type="date" class="form-control" formControlName="FromDate">
                </div>
                <div class="col-md-2">
                  <label class="col-md-10 " style="margin-top: 10px;">Đến ngày</label>
                  <input type="date" class="form-control" formControlName="ToDate">
                </div>
                <div class="col-md-3">
                    <label class="col-md-10 " style="margin-top: 10px;">Tài khoản</label>
                    <input class="form-control" placeholder="Nhập tên tài khoản" type="text"
                        formControlName="UserName"/>
                  </div>
              </div>
        </form>
        </div>
    </div>
</section>

<!----------------------------------------------- TABLE ------------------------------------------------->
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <div class="dol-md-12" style="margin-bottom: 10px;">
                                    <form [formGroup]="searchForm">
                                        <div class="row">
                                            <div class="col-md-2">
                                                <select class="form-control-sm"  formControlName="So_ban_ghi" style="height: 25px;" (change)="filterData()">
                                                    <option [ngValue]="100000000">Tất cả</option>
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                            <div class="col-md-10">
                                                <button type="button" class="btn btn-sm btn-outline-secondary" (click)="clickFilter()"
                                                    ngbTooltip="Ẩn/hiện bộ lọc" placement="left"
                                                    style="float: right; margin: 0 5px;">
                                                    <i class="fas fa-filter"></i>
                                                </button>
                                                <button type="button" class="btn btn-sm btn-outline-secondary" placement="left" (click)="reload()"
                                                    ngbTooltip="Tải lại" style="float: right; margin-bottom: 5px;">
                                                    <i class="fas fa-redo-alt"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <table class="table table-bordered">
                                    <thead style="text-align: center;">
                                      <tr>
                                        <th>STT</th>
                                        <th>Sự kiện</th>
                                        <th>Thao tác</th>
                                        <th>Nội dung</th>
                                        <th>Thời điểm thao tác</th>
                                        <th>Địa chỉ IP</th>
                                        <th>Tài khoản</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let log of dataTable; let i=index" >
                                        <td style="text-align: center;">{{ log.RowNum }}</td>
                                        <td style="text-align: center;">{{ log.EventName }}</td>
                                        <td style="text-align: center;">{{ log.PerformName }}</td>
                                        <td style="word-wrap: break-word;max-width: 400px;">{{ log.Content | json}}</td>
                                        <td style="text-align: center;">{{ log.Moment | date:'dd/MM/yyyy, h:mm a'}}</td>
                                        <td style="text-align: center;">{{ log.IPAddress }}</td>
                                        <td style="text-align: center;">{{ log.UserName }}</td>
                                      </tr>
                                      <tr *ngIf="!(dataTable.length > 0)" style="text-align: center;font-weight: bold;">
                                        <td colspan="10">Không có dữ liệu !</td>
                                      </tr>
                                    </tbody>                                  
                                  </table>
                                  <div class="col-md-12">
                                    <nav aria-label="..." style="float: right;">
                                        <ul class="pagination">
                                          <li class="page-item">
                                            <a class="page-link" tabindex="-1" [routerLink]="" (click)="refreshPage()">Đầu</a>
                                          </li>
                                          <li class="page-item">
                                            <a class="page-link" tabindex="-1" [routerLink]="" (click)="previewPage()">Trước</a>
                                          </li>
                                          <!-- <li class="page-item"><a class="page-link" href="#">1</a></li>
                                          <li class="page-item active">
                                            <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                                          </li>
                                          <li class="page-item"><a class="page-link" href="#">3</a></li> -->
                                          <li class="page-item active">
                                            <a class="page-link" [routerLink]="">{{page}}<span class="sr-only"></span></a>
                                          </li>
                                          <li class="page-item">
                                            <a class="page-link" [routerLink]="" (click)="nextPage()">Tiếp</a>
                                          </li>
                                          <li class="page-item">
                                            <a class="page-link" [routerLink]="" (click)="lastPage()">Cuối</a>
                                          </li>
                                        </ul>
                                    </nav>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
