import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { Subject } from 'rxjs';
import { BaseComponent, removeSpaces, formatDate} from 'src/app/base/base.component';

@Component({
  selector: 'app-out-of-date-customer',
  templateUrl: './out-of-date-customer.component.html',
  styleUrls: ['./out-of-date-customer.component.scss']
})
export class OutOfDateCustomerComponent extends BaseComponent implements OnInit {

  dtOptions: DataTables.Settings;
  dataTable: any = []
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective
  dtTrigger = new Subject();


  searchForm = new FormGroup({
    CustomerCode: new FormControl(''),
    CustomerName: new FormControl(''),
    ModuleCode: new FormControl(''),
    Nguoi_cap_nhat: new FormControl(''),
  })

  Upload = new FormGroup({
    ModuleCode: new FormControl('',[Validators.required]),
    VersionID: new FormControl(0),
    Revision: new FormControl('',[Validators.required]),
    UpdateNote: new FormControl(''),  //Noi dung cap nhat
    UpdateError: new FormControl(''),
  });

  ngOnInit(): void {
    this.getToken();
    /**-------------------------USE dtOptions to format table-------------------------*/
    // Filter DATA TABLE
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: true,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        },
      },
      columns: [
        {
          title: 'STT',
          className: "dt-center"
        },
        {
          title: 'Mã khách hàng',
          className: "dt-center",
        },
        {
          title: 'Tên khách hàng',
        },
        {
          title: 'Phân hệ',
          className: "dt-center",
        },
        {
          title: 'Cập nhật lần cuối',
          className: "dt-center",
        },
        {
          title: 'Người cập nhật',
          className: "dt-center",
        },
        {
          title: 'Thao tác',
          className: "dt-center",
        },
      ],
    };
  this.getList();
  }

  getList() {
    this.spinner.show();
    this.DashboardService.DanhSachQuaHanCapNhatPhienBan(this.Token).subscribe(
      (res) => {
        this.dataTable = res.DataQHCN;
        this.dtTrigger.next();
        this.spinner.hide();
      }
    );
  }

  open(content, sizm, type, Data) {
    console.log(Data);
    this.submitted = false;
    this.selected_ID = Data.CustomerID;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    if (type == "Upload") {
      this.UpdateVersionService.Get_Module_CustomerID(this.selected_ID, this.Token)
      .subscribe(z => {
        this.listModuleByCustomerID = z.listCustomerModules;
      });
      this.VersionService.Load_List(this.Token)
      .subscribe(z => {
        this.versions = z.listVersions;
        this.version_max = Math.max(...this.versions.map(x => x.VersionID));
        this.Upload.patchValue({VersionID: this.version_max})
      });
      this.spinner.show();
      this.Upload.patchValue({
        ModuleCode: '',
        Revision: '',
        UpdateNote: '',
        UpdateError: '',
      })
      this.titleModal = Data.CustomerName;
      this.Url_update_get = Data.URL_update;
      this.spinner.hide();
    }
  }

  getRevision() {
    this.UpdateClientService.GetRevision(this.Upload.value.ModuleCode,this.Token).subscribe(
      (res) => {
        this.Revision_get = res.Data;
        if (!(parseInt(this.Revision_get) >= 0)) {
          this.toastr.warning(res.Message);
          this.Upload.patchValue({
            Revision: ''
          })
        }
        else {
          this.Upload.patchValue({
            Revision: this.Revision_get
          })
        }
      }
    );
  }

  get checkvalueUpload() {
    return this.Upload.controls;
  }

  Save() {
    this.submitted = true;
    if (this.Upload.invalid) {
      return false;
    }
    var ModuleID_get = this.listModuleByCustomerID.filter(x => x.ModuleCode == this.Upload.value.ModuleCode).map(x => x.ModuleID);
    var VersionCode_get = this.versions.filter(x => x.VersionID ==  this.Upload.value.VersionID).map(x => x.VersionCode);
    let req = {
      ModuleCode : this.Upload.value.ModuleCode,
      UserName: this.UserName_get,
      VersionID : this.Upload.value.VersionID,
      Revision : this.Upload.value.Revision,
      UpdateNote : this.Upload.value.UpdateNote,
      access_token: this.Token,
      postURL: this.Url_update_get,
      UserID: this.UserID_get,
      CustomerID: this.selected_ID,
      ModuleID: ModuleID_get[0],
      VersionCode: VersionCode_get[0]
    };
    // var Message = [
    //   {
    //     "Message": "Cập nhật thất bại !",
    //     "Result": "True",
    //     "Token": "null"
    //   }
    // ];
    /**--------------INSERT--------------- */
    this.spinner.show();
    this.UpdateClientService.UpdateVersion(req, this.Token).subscribe(
      (z) => {
        if (z.Status == 2) {
          this.toastr.warning(z.Message);
          this.Upload.patchValue({
            UpdateError: z.Message
          })
          this.spinner.hide();
        } else if (z.Status == 1) {
          var message = z.Message;
          var messge_insert = JSON.parse(message)[0].Message;
          var result_insert = JSON.parse(message)[0].Result;
          /**--------------INSERT to TABLE htUpdateVersion--------------- */
          if (!result_insert || result_insert == "false") {
            this.Revision_get = 0;           
          };
          let req_isnert = {
            UserID: this.UserID_get,
            CustomerID: this.selected_ID,
            ModuleID: ModuleID_get[0],
            VersionID : this.Upload.value.VersionID,
            Revision : this.Revision_get,
            UpdateNote: this.Upload.value.UpdateNote,
            UpdateResult: result_insert,
            UpdateError: messge_insert
          }
          this.UpdateVersionService.UpdateVersion_Insert(req_isnert, this.Token).subscribe(
            (z) => {
              if (z.Status == 2) {
                this.spinner.show();
                this.toastr.warning(z.Message);
                this.Upload.patchValue({
                  UpdateError: z.Message
                })
                this.spinner.hide();
              } else if (z.Status == 1) {
                this.toastr.success(messge_insert);
                this.Upload.patchValue({
                  UpdateError: messge_insert
                })
                this.spinner.hide();
              } else {
                this.spinner.show();
                this.toastr.warning(z.Message);
                this.Upload.patchValue({
                  UpdateError: z.Message
                })
                this.spinner.hide();
            }
          })
          this.spinner.hide();
        } else {
          this.spinner.show();
          this.toastr.warning(z.Message);
          this.Upload.patchValue({
            UpdateError: z.Message
          })
          this.spinner.hide();
        }
        this.getList();
        this.datatableElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          }
        );
        this.spinner.hide();
      },
      (err) => {
        if (err.status == 401) {
          this.spinner.show();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.Upload.patchValue({
            UpdateError: 'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          })
          this.spinner.hide();
        }
      }
    );
  }

  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn['dataTable'].ext.search.pop();
    });
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let CustomerCode_data = data[1];
      let CustomerName_data = data[2];
      let ModuleCode_data = data[3];
      let Nguoi_cap_nhat_data = data[5];

      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let bool4 = true;

      if (this.searchForm.value.CustomerCode != '') {
        bool2 = this.xoa_dau(CustomerCode_data.toLowerCase()).includes(this.xoa_dau(this.searchForm.value.CustomerCode.toLowerCase()));
      }
      if (this.searchForm.value.CustomerName != '') {
        bool2 = this.xoa_dau(CustomerName_data.toLowerCase()).includes(this.xoa_dau(this.searchForm.value.CustomerName.toLowerCase()));
      }
      if (this.searchForm.value.ModuleCode != '') {
        bool2 = this.xoa_dau(ModuleCode_data.toLowerCase()).includes(this.xoa_dau(this.searchForm.value.ModuleCode.toLowerCase()));
      }
      if (this.searchForm.value.Nguoi_cap_nhat != '') {
        bool2 = this.xoa_dau(Nguoi_cap_nhat_data.toLowerCase()).includes(this.xoa_dau(this.searchForm.value.Nguoi_cap_nhat.toLowerCase()));
      }
      if (bool1 && bool2 && bool3 && bool4) {
        return true;
      }
      return false;
    });
  }
}
