<!----------------------------------------------- HEADER ------------------------------------------------->
<!-- <progress-bar [progress]="100" [color]="'#488aff'"></progress-bar> -->
<section class="content-header" style="padding:0.5%  !important">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-md-6">
              <ol class="breadcrumb float-left">
                  <li class="breadcrumb-item"><a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                  </li>
                  <li class="breadcrumb-item active">Quản lí người dùng</li>
              </ol>
          </div>
          <div class="col-md-6">
              <button class="btn btn-outline-success btn-sm" (click)="open(AddModal,'ls','Add','')" style="float: right;"><i
                      class="fas fa-plus-circle"></i> Thêm mới
              </button>
              <button class="btn btn-outline-success btn-sm" style="float: right;margin-right: 5px;" (click)="Excel()">
                <i class="fas fa-file-excel"></i> Xuất báo cáo
              </button>
          </div>
      </div>
  </div>
</section>
<!-- ------------------------------------------------------FILTER----------------------------------------------------- -->
<section class="content cus-body ">
  <div class="card">
      <div class="card-body">
          <form [formGroup]="searchForm">
            <div class="row">
              <div class="col-md-4">
                <label class="col-md-10 " style="margin-top: 10px;">Tên đăng nhập</label>
                <input class="form-control" placeholder="Nhập tên đăng nhập" type="text"
                    formControlName="UserName" (keyup)="filterById()" />
              </div>
              <div class="col-md-4">
                <label class="col-md-10 " style="margin-top: 10px;">Họ tên</label>
                <input class="form-control" placeholder="Nhập họ và tên" type="text"
                    formControlName="FullName" (keyup)="filterById()" />
              </div>
              <div class="col-md-4">
                <label class="col-md-10 " style="margin-top: 10px;">Trạng thái</label>
                <ng-select formControlName="Active" (change)="filterById()" notFoundText="Không có kết quả">
                    <ng-option value="">Tất cả</ng-option>
                    <ng-option [value]="true">Còn hiệu lực</ng-option>
                    <ng-option [value]="false">Vô hiệu hóa</ng-option>
                </ng-select>
              </div>
            </div>
      </form>
      </div>
  </div>
</section>
<!----------------------------------------------- TABLE ------------------------------------------------->
<section class="content cus-body">
  <div class="card">
      <div class="card-body">
          <div class="tab-content">
              <div class="active tab-pane">
                  <div class="row">
                      <div class="col-md-12">
                          <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                              <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                  class="table-bordered dataTables_wrapper no-footer">
                                    <thead style="text-align: center;">
                                      <tr>
                                          <th></th>
                                          <th ></th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr *ngFor="let user of dataTable; let i=index" >
                                          <td>{{i+1}}</td>
                                          <td style="white-space: nowrap">{{user.UserName}}</td>
                                          <td style="white-space: nowrap">{{user.FullName}}</td>
                                          <td>{{user.Email}}</td>
                                          <td style="text-align: center;">
                                              <input type="checkbox" disabled [checked]="user.Admin" >
                                          </td>
                                          <td style="text-align: center;">  <input type="checkbox" disabled [checked]="user.Active" ></td>
                                          <td>{{user.Trang_thai}}</td>
                                          <td>
                                            <button class="btn btn-outline-info btn-sm"
                                            (click)="open(UpdateModal, 'xs', 'Update', user)"
                                            style="margin-right: 5px;">
                                            <i class="fas fa-edit"></i>
                                            </button>
                                            <button class="btn btn-outline-warning btn-sm"
                                            (click)="open(FunctionModal, 'xl', 'ViewFunction', user)"
                                            style="margin-right: 5px;">
                                            <i class="fas fa-user-tag"></i>
                                            </button>
                                            <button class="btn btn-outline-secondary btn-sm"
                                            (click)="open(CustomerModal, 'xl', 'ViewCustomer', user)"
                                            style="margin-right: 5px;">
                                            <i class="fas fa-school"></i>
                                            </button>
                                            <button class="btn btn-outline-danger btn-sm"
                                            (click)="open(DeleteModal, 'xs', 'Delete', user)">
                                            <i class="fas fa-trash-alt"></i>
                                            </button>
                                          </td>
                                          <td>{{ user.Active }}</td>
                                      </tr>
                                  </tbody>
                                </table>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
</section>
<!-----------------------------------------------ADD MODAL ------------------------------------------------->
<ng-template #AddModal let-modal>
  <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
    <h4 class="modal-title h-title w-100"> {{titleModal}}</h4>
    <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="AddForm">
      <div class="row">
        <div class="col-md-12">
          <label class="col-md-10 require">Tên đăng nhập</label>
            <input type="text" class="form-control" formControlName="UserName_Add" placeholder="Nhập tên đăng nhập..."
            [ngClass]="{ 'is-invalid': submitted && checkvalueInsert.UserName_Add.errors }">
            <div *ngIf="submitted && checkvalueInsert.UserName_Add.errors" class="invalid-feedback">
              <div *ngIf="checkvalueInsert.UserName_Add.errors.required">
                  Tên tài khoản không được bỏ trống!
              </div>
              <div *ngIf="checkvalueInsert.UserName_Add.errors.maxlength">
                  Tên tài khoản tối đa 50 ký tự!
              </div>
            </div>
        </div>
        <div class="col-md-12">
          <label class="col-md-10 require">Password</label>
            <input type="password" class="form-control" formControlName="Password_Add" placeholder="Nhập mật khẩu..."
            [ngClass]="{ 'is-invalid': submitted && checkvalueInsert.Password_Add.errors }">
            <div *ngIf="submitted && checkvalueInsert.Password_Add.errors" class="invalid-feedback">
              <div *ngIf="checkvalueInsert.Password_Add.errors.required">
                  Mật khẩu không được bỏ trống!
              </div>
              <div *ngIf="checkvalueInsert.Password_Add.errors.maxlength">
                  Mật khẩu tối đa 50 ký tự!
              </div>
            </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-10 require">Họ tên</label>
            <input type="text" class="form-control" formControlName="FullName_Add" placeholder="Họ và tên..."
            [ngClass]="{ 'is-invalid': submitted && checkvalueInsert.FullName_Add.errors }">
            <div *ngIf="submitted && checkvalueInsert.FullName_Add.errors" class="invalid-feedback">
              <div *ngIf="checkvalueInsert.FullName_Add.errors.required">
                  Họ tên không được bỏ trống!
              </div>
              <div *ngIf="checkvalueInsert.FullName_Add.errors.maxlength">
                Họ tên tối đa 50 ký tự!
              </div>
            </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-12">Email (Lấy lại mật khẩu)</label>
          <input type="email" class="form-control" formControlName="Email_Add" placeholder="Email...">
        </div>
        <div class="col-md-6">
          <div class="col-md-10">
            <div class="custom-control custom-switch">
              <input formControlName="Admin_Add" type="checkbox" class="custom-control-input" id="adminSwitch" checked>
              <label class="custom-control-label" for="adminSwitch">Admin</label>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-md-10">
            <div class="custom-control custom-switch">
              <input formControlName="Active_Add" type="checkbox" class="custom-control-input" id="activeSwitch" checked>
              <label class="custom-control-label" for="activeSwitch">Active</label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-outline-success btn-sm" (click)="Insert()" style="margin-right: 1em;">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button type="button" class="btn btn-outline-danger btn-sm" (click)="modal.dismiss('Cross click')">
      <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<!-----------------------------------------------DELETE MODAL ------------------------------------------------->
<ng-template #DeleteModal let-modal>
  <app-delete-modal (delete)="deleteItem()" (close)="dissmissModal()"></app-delete-modal>
</ng-template>

<!-----------------------------------------------UPDATE MODAL ------------------------------------------------->
<ng-template #UpdateModal let-modal>
  <div class="modal-header m-header text-center" style="padding-top: 0px;padding-bottom: 0px;">
      <h4 class="modal-title h-title w-100">{{titleModal}}</h4>
      <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
  </div>
  <div class="modal-body" >
      <form class="form-horizontal" [formGroup]="UpdateForm">
          <div class="row">
            <div class="col-md-6">
              <label class="col-md-10 require">Họ tên</label>
                <input type="text" class="form-control" formControlName="FullName_Update" [ngClass]="{ 'is-invalid': submitted && checkvalueUpdate.FullName_Update.errors }">
                <div *ngIf="submitted && checkvalueUpdate.FullName_Update.errors" class="invalid-feedback">
                  <div *ngIf="checkvalueUpdate.FullName_Update.errors.required">
                      Họ tên không được bỏ trống!
                  </div>
                  <div *ngIf="checkvalueUpdate.FullName_Update.errors.maxlength">
                    Họ tên tối đa 50 ký tự!
                  </div>
                </div>
            </div>
            <div class="col-md-6">
              <label class="col-md-10">Email</label>
                <input type="email" class="form-control" formControlName="Email_Update">
            </div>
            <div class="col-md-6">
              <div class="col-md-10">
                <div class="custom-control custom-switch">
                  <input formControlName="Admin_Update" type="checkbox" class="custom-control-input" id="adminSwitch" checked>
                  <label class="custom-control-label" for="adminSwitch">Admin</label>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="col-md-10">
                <div class="custom-control custom-switch">
                  <input formControlName="Active_Update" type="checkbox" class="custom-control-input" id="activeSwitch" checked>
                  <label class="custom-control-label" for="activeSwitch">Active</label>
                </div>
              </div>
            </div>
          </div>
      </form>
  </div>
  <div class="modal-footer">
      <div class="float-right">
          <button type="button" class="btn btn-outline-success btn-sm"  (click)="Update()" style="margin-right: 1em;">
            <i class="fas fa-save"></i> Lưu
          </button>
          <button type="button" class="btn btn-outline-danger btn-sm" (click)="modal.dismiss('Cross click')">
            <i class="far fa-times-circle"></i> Đóng
          </button>
        </div>
  </div>
</ng-template>

<!-----------------------------------------------FUNCTION MODAL ------------------------------------------------->
<ng-template #FunctionModal let-modal>
  <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
    <h4 class="modal-title h-title w-100"> Danh sách quyền của người dùng</h4>
    <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
  </div>
  <div class="container" style="margin-bottom: 30px;margin-top:30px">
    <input type="text" class="form-control" (keyup)="changeFunction()" [(ngModel)]="searchFunction" placeholder="Tìm kiếm tên quyền...">
  </div>
  <div class="container">
  </div>
  <div class="modal-body" style="overflow-y:auto;height: 300px;">
    <div class="total-page col-md-12">
      <div class="row">
        <div class="half-page col-md-6">
          <h5>&nbsp;</h5>
          <table class="table table-bordered">
            <thead class="thead-dark" style="text-align: center;">
              <tr>
                <th scope="col" style="width: 10vh;">
                  <input type="checkbox" id="SelectALL" [(ngModel)]="itemSelected" (change)="checkUncheckAll()">
                </th>
                <th scope="col" style="width: 20vh;">Mã quyền</th>
                <th scope="col" style="word-wrap: break-word;max-width: 70vh;">Tên quyền</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let function of functions | filter: searchFunction; let i=index;" >
                <td  *ngIf="!function.checked" style="text-align: center;">
                  <input (change)="total_count_function()" type="checkbox" [checked]="function.checked" [(ngModel)]="function.checked">
                </td>
                <td style="text-align: center;" *ngIf="!function.checked">{{function.FunctionCode}}</td>
                <td  *ngIf="!function.checked">{{function.FunctionName}}</td>
              </tr>
            </tbody>
          </table>

        </div>
        <div class="half-page col-md-6">
          <h5>Số lượng quyền: {{count_function}}</h5>
          <table class="table" class="table table-bordered">
            <thead class="thead-dark" style="text-align: center;">
              <tr>
                <th scope="col" (click)="DestroyCheckFunction()"><i class="fas fa-window-close"></i></th>
                <th scope="col">Mã quyền</th>
                <th scope="col">Tên quyền</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let functionByUserID of functions | filter: searchFunction; let i=index">
                <td style="width: 10vh;" *ngIf="functionByUserID.checked" style="text-align: center;">
                  <input (change)="isAllSelected()" 
                         (click)="unCheckFunction()" 
                         (change)="total_count_function()" 
                  type="checkbox" [(ngModel)]="functionByUserID.checked">
                </td>
                <td style="width: 20vh;text-align: center;" *ngIf="functionByUserID.checked">{{functionByUserID.FunctionCode}}</td>
                <td style="word-wrap: break-word;max-width: 70vh;"  *ngIf="functionByUserID.checked">{{functionByUserID.FunctionName}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-outline-success btn-sm" (click)="UserFunctionSave()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button type="button" class="btn btn-outline-danger btn-sm" (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;">
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<!-----------------------------------------------CUSTOMER MODAL ------------------------------------------------->
<ng-template #CustomerModal let-modal>
  <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px">
    <h4 class="modal-title h-title w-100"> Danh sách trường</h4>
    <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
  </div>
  <div class="container" style="margin-bottom: 30px;margin-top:30px">
    <input type="text" class="form-control" (keyup)="changeCustomer()" [(ngModel)]="searchCustomer" placeholder="Tìm kiếm trường...">
  </div>
  <div class="container">
  </div>
  <div class="modal-body" style="overflow-y:auto;height: 400px;max-width: 100%">
    <div class="total-page col-md-12">
      <div class="row">
        <div class="half-page col-md-6">
          <h5>&nbsp;</h5>
          <table class="table table-bordered" style="font-size: 12px;">
            <thead class="thead-dark" style="text-align: center;">
              <tr>
                <th scope="col">
                  <input type="checkbox" id="SelectALLCustomer" [(ngModel)]="customerSelected" (change)="checkUncheckCustomerAll()">
                </th>
                <th scope="col">Mã trường</th>
                <th scope="col">Tên trường</th>
                <th scope="col">URL</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let customer of customers | filter: searchCustomer; let i=index;">
                <td *ngIf="!customer.checked" style="text-align: center;width: 10vh;">
                  <input (change)="total_count_customer()" type="checkbox" [checked]="customer.checked" [(ngModel)]="customer.checked">
                </td>
                <td style="width: 20vh;text-align: center;" *ngIf="!customer.checked">{{customer.CustomerCode}}</td>
                <td style="word-wrap: break-word;max-width: 30vh;" *ngIf="!customer.checked">{{customer.CustomerName}}</td>
                <td style="word-wrap: break-word;max-width: 40vh;" *ngIf="!customer.checked">{{customer.Url_update}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="half-page col-md-6">
          <h5>Số lượng trường: {{count_customer}}</h5>
          <table class="table table-bordered" style="font-size: 12px;">
            <thead class="thead-dark" style="text-align: center;">
              <tr>
                <th scope="col" (click)="DestroyCheckCustomer()"><i class="fas fa-window-close"></i></th>
                <th scope="col">Mã trường</th>
                <th scope="col">Tên trường</th>
                <th scope="col">URL</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let customerByUserID of customers | filter: searchCustomer; let i=index">
                <td *ngIf="customerByUserID.checked" style="text-align: center;width: 10vh;">
                  <input (change)="isAllCustomerSelected()" 
                         (click)="unCheckCustomer()" 
                         (change)="total_count_customer()" 
                  type="checkbox" [checked]="customerByUserID.checked" [(ngModel)]="customerByUserID.checked">
                </td>
                <td style="text-align: center;width: 20vh;" *ngIf="customerByUserID.checked">{{customerByUserID.CustomerCode}}</td>
                <td style="word-wrap: break-word; max-width: 30vh;" *ngIf="customerByUserID.checked">{{customerByUserID.CustomerName}}</td>
                <td style="word-wrap: break-word; max-width: 40vh;" *ngIf="customerByUserID.checked">{{customerByUserID.Url_update}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-outline-success btn-sm" (click)="UserCustomerSave()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button type="button" class="btn btn-outline-danger btn-sm" (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;">
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>


