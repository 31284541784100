import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './pages/main/main.component';
import { LoginComponent } from './pages/login/login.component';
import { HeaderComponent } from './pages/main/header/header.component';
import { FooterComponent } from './pages/main/footer/footer.component';
import { MenuSidebarComponent } from './pages/main/menu-sidebar/menu-sidebar.component';
import { BlankComponent } from './views/blank/blank.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ProfileComponent } from './views/profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { MessagesDropdownMenuComponent } from './pages/main/header/messages-dropdown-menu/messages-dropdown-menu.component';
import { NotificationsDropdownMenuComponent } from './pages/main/header/notifications-dropdown-menu/notifications-dropdown-menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import {
  registerLocaleData,
  LocationStrategy,
  HashLocationStrategy,
} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { UserDropdownMenuComponent } from './pages/main/header/user-dropdown-menu/user-dropdown-menu.component';
import { HttpClientModule } from '@angular/common/http';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DataTablesModule } from 'angular-datatables';
import { CookieService } from 'ngx-cookie-service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DangNhapComponent } from './pages/dang-nhap/dang-nhap.component';
import { XacNhanComponent } from './pages/xac-nhan/xac-nhan.component';
import { PortalComponent } from './pages/portal/portal.component';
import { PortalFooterComponent } from './pages/portal/portal-footer/portal-footer.component';
import { PortalHeaderComponent } from './pages/portal/portal-header/portal-header.component';
import { PortalMenuSidebarComponent } from './pages/portal/portal-menu-sidebar/portal-menu-sidebar.component';
import { PortalUserDropdownComponent } from './pages/portal/portal-header/portal-user-dropdown/portal-user-dropdown.component';
import { ChangePassComponent } from './pages/change-pass/change-pass.component';
import {ProgressBarModule} from "angular-progress-bar"
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChatBotComponent } from './pages/chat-bot/chat-bot.component';

import { UserManagerComponent } from './components/user-manager/user-manager.component';
import { FunctionComponent } from './components/function/function.component';
import { ModuleComponent } from './components/module/module.component';
import { VersionComponent } from './components/version/version.component';
import { CustomerComponent } from './components/customer/customer.component';
import { UpdateVersionComponent } from './components/update-version/update-version.component';
import { UserFunctionComponent } from './components/function/user-function/user-function.component';
import { HistoryDBComponent } from './components/customer/history-db/history-db.component';
import { HistoryUpdateComponent } from './components/update-version/history-update/history-update.component';
import { CustomerTypeComponent } from './components/customer-type/customer-type.component';
import { NoneUpdateCustomerComponent } from './components/customer/none-update-customer/none-update-customer.component';
import { UpdatedCustomerComponent } from './components/customer/updated-customer/updated-customer.component';
import { UserCustomerComponent } from './components/customer/user-customer/user-customer.component';
import { ModuleCustomerComponent } from './components/customer/module-customer/module-customer.component';
import { AdminLogComponent } from './components/admin-log/admin-log.component';
import { LogSystemComponent } from './components/admin-log/log-system/log-system.component';
import { HistoryRestoreComponent } from './components/admin-log/history-restore/history-restore.component';
import { HistoryUpdateVersionComponent } from './components/admin-log/history-update-version/history-update-version.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { TestComponent } from './components/test/test.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ComfirmPasswordComponent } from './pages/comfirm-password/comfirm-password.component';
import { SelectMaterialModule } from './select-material.module';
import { DeveloperSqlComponent } from './components/developer-sql/developer-sql.component';
import { PaymentPartnerComponent } from './components/payment-partner/payment-partner.component';
import { LevelUsingComponent } from './components/level-using/level-using.component';
import { LevelCustomerComponent } from './components/level-using/level-customer/level-customer.component';
import { LevelFunctionComponent } from './components/level-using/level-function/level-function.component';
import { DetailLevelComponent } from './components/level-using/detail-level/detail-level.component';
import { TransactionComponent } from './components/transaction/transaction.component';
import { BaseComponent } from './base/base.component';
import { DeleteModalComponent } from './components/modal-component/delete-modal/delete-modal.component';
import { NewCustomerComponent } from './components/new-customer/new-customer.component';
import { Step1ModalComponent } from './components/modal-component/step1-modal/step1-modal.component';
import { Step2ModalComponent } from './components/modal-component/step2-modal/step2-modal.component';
import { Step3ModalComponent } from './components/modal-component/step3-modal/step3-modal.component';
import { Step4ModalComponent } from './components/modal-component/step4-modal/step4-modal.component';
import { OutOfDateCustomerComponent } from './components/customer/out-of-date-customer/out-of-date-customer.component';
import { SearchTransactionComponent } from './components/search-transaction/search-transaction.component';

registerLocaleData(localeEn, 'en-EN');
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    BlankComponent,
    ProfileComponent,
    DashboardComponent,
    MessagesDropdownMenuComponent,
    NotificationsDropdownMenuComponent,
    UserDropdownMenuComponent,
    DangNhapComponent,
    XacNhanComponent,
    PortalComponent,
    PortalFooterComponent,
    PortalHeaderComponent,
    PortalMenuSidebarComponent,
    PortalUserDropdownComponent,
    UserManagerComponent,
    FunctionComponent,
    ModuleComponent,
    CustomerComponent,
    VersionComponent,
    ChangePassComponent,
    UpdateVersionComponent,
    ChatBotComponent,
    UserFunctionComponent,
    UserCustomerComponent,
    HistoryDBComponent,
    HistoryUpdateComponent,
    CustomerTypeComponent,
    NoneUpdateCustomerComponent,
    UpdatedCustomerComponent,
    ModuleCustomerComponent,
    AdminLogComponent,
    LogSystemComponent,
    HistoryRestoreComponent,
    HistoryUpdateVersionComponent,
    TestComponent,
    ForgotPasswordComponent,
    ComfirmPasswordComponent,
    DeveloperSqlComponent,
    PaymentPartnerComponent,
    LevelUsingComponent,
    LevelCustomerComponent,
    LevelFunctionComponent,
    DetailLevelComponent,
    TransactionComponent,
    BaseComponent,
    DeleteModalComponent,
    NewCustomerComponent,
    Step1ModalComponent,
    Step2ModalComponent,
    Step3ModalComponent,
    Step4ModalComponent,
    OutOfDateCustomerComponent,
    SearchTransactionComponent,
  ],
  imports: [
    SelectMaterialModule,
    NgxPaginationModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    DataTablesModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    NgbModule,
    HttpClientModule,
    FormsModule,
    Ng2SearchPipeModule,
    ProgressBarModule,
    MatProgressBarModule,
    NgSelectModule,
    FormsModule
  ],
  providers: [
    CookieService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
