<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class="table-bordered dataTables_wrapper no-footer">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of dataTable">
                                            <td>{{p.DatabaseName}}</td>
                                            <td>{{p.RestoreDate | date:'dd/MM/yyyy, h:mm a'}}</td>
                                            <td>{{p.BackupStartDate | date:'dd/MM/yyyy, h:mm a'}}</td>
                                            <td>{{p.BackupFinishDate | date:'dd/MM/yyyy, h:mm a'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
