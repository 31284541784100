<div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
    <h4 class="modal-title h-title w-100"> {{titleModalInput}}</h4>
    <span (click)="cancel()" aria-hidden="true" style="font-size: 20px;">&times;</span>
</div>
<div class="modal-body">
<div class="container-fluid">
  <div class="row mb-2">
      <div class="col-md-6">
      </div>
      <div class="col-md-6">
        <button class="btn btn-outline-info btn-sm" (click)="loadDataTransferClass()" style="float: right;">
          <i class="fas fa-search"></i> Tìm kiếm
        </button>
      </div>
  </div>
</div>
  <div class="row">
      <div class="col-md-12">
        <section class="content cus-body ">
          <div class="card">
              <div class="card-body">
                <form [formGroup]="searchForm">
                  <div class="row">
                    <div class="col-md-6">
                      <label class="col-md-10">Hệ đào tạo</label>
                      <ng-select formControlName="Ten_he" notFoundText="Không có kết quả">
                          <ng-option value="">Tất cả</ng-option>
                          <ng-option *ngFor="let he of dataTenHeFilter" [value]=he.Ten_he>{{he.Ten_he}}</ng-option>
                      </ng-select>
                    </div>
                    <div class="col-md-6">
                      <label class="col-md-10">Khoa</label>
                      <ng-select formControlName="Ten_khoa" notFoundText="Không có kết quả">
                          <ng-option value="">Tất cả</ng-option>
                          <ng-option *ngFor="let t of dataTenKhoaFilter" [value]=t.Ten_khoa>{{t.Ten_khoa}}</ng-option>
                      </ng-select>
                    </div>
                    <div class="col-md-6">
                      <label class="col-md-10" style="margin-top: 10px;">Khóa học</label>
                      <ng-select formControlName="Khoa_hoc" notFoundText="Không có kết quả">
                          <ng-option *ngFor="let khoa of dataKhoaHocFilter" [value]=khoa.Khoa_hoc>{{khoa.Khoa_hoc}}</ng-option>
                      </ng-select>
                    </div>
        
                    <div class="col-md-6">
                      <label class="col-md-10" style="margin-top: 10px;">Chuyên ngành</label>
                      <ng-select formControlName="Ten_chuyen_nganh" notFoundText="Không có kết quả">
                          <ng-option value="">Tất cả</ng-option>
                          <ng-option *ngFor="let cn of dataTenChuyenNganhFilter" [value]=cn.Ten_chuyen_nganh>{{cn.Ten_chuyen_nganh}}</ng-option>
                      </ng-select>
                    </div>
                  </div>
                </form>
              </div>
          </div>
        </section>
      </div>
      <div class="col-md-12">
        <section class="content cus-body ">
          <div class="card">
              <div class="card-body">
                <div class="col-md-12" style="font-size: 15px !important; font-weight: bold; margin-bottom: 10px;">
                  <div class="row">
                    <div class="col-md-12">
                      Tổng số giảng viên: {{count_teacher}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;     
                      Tổng số phòng học: {{count_room}}
                      <input type="text" [(ngModel)]="searchString" placeholder="Tìm kiếm nhanh..." style="float: right;">
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                    <div class="card" *ngFor="let c of dataTransferClass| filter:searchString; index as i ">
                        <div class="card-header bg-info">
                            <i class="fas fa-user-tie"></i>&nbsp;
                                {{c.Ten_khoa}} - Khóa: {{c.Khoa_hoc}}
                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-toggle="collapse" [attr.data-target]="'#collapseExample' + i" [attr.aria-controls]="'#collapseExample' + i" aria-expanded="true" >
                                    <i class="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="collapse hide" id="collapseExample{{i}}">
                        <div class="card-body p-0" >
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive" style=" max-height: 60vh;">
                                      <table class="table table-bordered">
                                        <thead>
                                          <tr> 
                                            <th>STT</th>
                                            <th>Tên hệ</th>
                                            <th>Khóa học</th>
                                            <th>Tên khoa</th>
                                            <th>Tên chuyên ngành</th>
                                            <th>Số lớp</th>
                                            <th>Số SV</th>
                                            <th>CTĐT</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let data of loadListFilter(this.searchForm.value.Khoa_hoc, c.Ten_khoa); let i=index">
                                            <th scope="row">{{i+1}}</th>
                                            <td>{{data.Ten_he}}</td>
                                            <td>{{data.Khoa_hoc}}</td>
                                            <td>{{data.Ten_khoa}}</td>
                                            <td >{{data.Ten_chuyen_nganh}}</td>
                                            <td style="text-align: center;">{{data.So_lop}}</td>
                                            <td style="text-align: center;">{{data.So_sv}}</td>
                                            <td style="text-align: center;">{{data.So_CTDT}}</td>
                                          </tr>
                                          <tr style="font-weight: bold;">
                                            <td colspan="5">Tổng cộng</td>
                                            <td style="text-align: center;">{{countSoLop(this.searchForm.value.Khoa_hoc, c.Ten_khoa)}}</td>
                                            <td style="text-align: center;">{{countSoSV(this.searchForm.value.Khoa_hoc, c.Ten_khoa)}}</td>
                                            <td style="text-align: center;">{{countSoCTDT(this.searchForm.value.Khoa_hoc, c.Ten_khoa)}}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <div class="col-md-12">
                                        <p style="text-align: center;">--------------THÔNG TIN DỮ LIỆU ĐIỂM--------------</p>
                                      </div>
                                      <div class="col-md-12" style=" max-height: 60vh;overflow-x: auto;">
                                        <table class="table table-bordered" >
                                          <thead>
                                            <tr> 
                                              <th>STT</th>
                                              <th>Tên hệ</th>
                                              <th>Khóa học</th>
                                              <th>Tên khoa</th>
                                              <th>Tên chuyên ngành</th>
                                              <th>Tên lớp</th>
                                              <th>Số SV</th>
                                              <th>Số môn CTĐT</th>
                                              <th>Số môn có điểm</th>
                                              <th>Số môn thiếu điểm</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr *ngFor="let data of loadListMarkFilter(this.searchForm.value.Khoa_hoc, c.Ten_khoa, c.Ten_chuyen_nganh); let i=index">
                                              <th scope="row">{{i+1}}</th>
                                              <td>{{data.Ten_he}}</td>
                                              <td>{{data.Khoa_hoc}}</td>
                                              <td>{{data.Ten_khoa}}</td>
                                              <td >{{data.Ten_chuyen_nganh}}</td>
                                              <td style="text-align: center;">{{data.Ten_lop}}</td>
                                              <td style="text-align: center;">{{data.So_sv}}</td>
                                              <td style="text-align: center;">{{data.So_mon_CTDT}}</td>
                                              <td style="text-align: center;">{{data.So_mon_diem}}</td>
                                              <td style="text-align: center;">{{data.So_mon_chua_diem}}</td>
                                            </tr>
                                            <tr style="font-weight: bold;">
                                              <td colspan="5">Tổng cộng</td>
                                              <td style="text-align: center;">{{countMarkSoLop(this.searchForm.value.Khoa_hoc, c.Ten_khoa, c.Ten_chuyen_nganh)}}</td>
                                              <td style="text-align: center;">{{countMarkSoSV(this.searchForm.value.Khoa_hoc, c.Ten_khoa, c.Ten_chuyen_nganh)}}</td>
                                              <td style="text-align: center;">{{countMarkSoCTDT(this.searchForm.value.Khoa_hoc, c.Ten_khoa, c.Ten_chuyen_nganh)}}</td>
                                              <td style="text-align: center;">{{countMarkSoMonCoDiem(this.searchForm.value.Khoa_hoc, c.Ten_khoa, c.Ten_chuyen_nganh)}}</td>
                                              <td style="text-align: center;">{{countMarkSoMonChoDiem(this.searchForm.value.Khoa_hoc, c.Ten_khoa, c.Ten_chuyen_nganh)}}</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
              </div>
          </div>
        </section>
      </div>
  </div>
</div>
