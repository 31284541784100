import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { Subject } from 'rxjs';
import { BaseComponent, formatDate } from 'src/app/base/base.component';

@Component({
  selector: 'app-detail-level',
  templateUrl: './detail-level.component.html',
  styleUrls: ['./detail-level.component.scss']
})

export class DetailLevelComponent extends BaseComponent implements OnInit {

  dataTable: any = []

  searchForm = new FormGroup({
    Tu_ngay: new FormControl(''),
    Den_ngay: new FormControl(''),
    Limit: new FormControl(2),
    TypeID: new FormControl(''),
    So_ban_ghi: new FormControl(10),
    Ma_truong: new FormControl(''),
    Phan_he: new FormControl(''),
    Ma_chuc_nang: new FormControl(''),
    Ten_nguoi_dung: new FormControl(''),
  })
  
  ngOnInit(): void {
    this.getToken();   
    this.statisticByDate();
    this.getList();
    this.getType();
  }

  getList() {
    this.spinner.show();
    let req = {
      So_ban_ghi: this.searchForm.value.So_ban_ghi,
      Trang: this.page,
      Tu_ngay: this.searchForm.value.Tu_ngay,
      Den_ngay: this.searchForm.value.Den_ngay,
      Ma_truong: this.searchForm.value.Ma_truong,
      Phan_he: this.searchForm.value.Phan_he,
      Ma_chuc_nang: this.searchForm.value.Ma_chuc_nang,
      Ten_nguoi_dung: this.searchForm.value.Ten_nguoi_dung,
      TypeID: this.searchForm.value.TypeID,
    }
    this.LevelDetailService.getList(req, this.Token).subscribe(
      (res) => {
        this.dataTable = res.DataDetail;
        this.totalRecord = res.Tong_so_ban_ghi;
        this.spinner.hide();
      }
    );
  }

  lastPage() {
    this.getLastPage(this.searchForm.value.So_ban_ghi);
    this.getList();
  }

  filterData() {
    this.getList();
  }
  
  nextPage() {
    this.getNextPage(this.dataTable);
    this.getList();
  }
  
  previewPage() {
    this.getPreviewPage();
    this.getList();
  }

  reload() {
    this.pageReload();
    this.getList();
  }
  
  refreshPage() {
    this.page = 1;
    this.getList();
  }

  search() {
    this.refreshPage();
  }

  getType() {
    this.CustomerTypeService.Load_List(this.Token).subscribe(
      (res) => {
        this.dataType = res.Data;
      }
    );
  }

  statisticByDate() {
    this.filterDate(this.searchForm.value.Limit);
    this.searchForm.patchValue({
      Tu_ngay: this.dayBefore,
      Den_ngay: this.currentDate,
    })
  }
}
