import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { BaseComponent, removeSpaces, formatDate} from 'src/app/base/base.component';

@Component({
  selector: 'app-history-restore',
  templateUrl: './history-restore.component.html',
  styleUrls: ['./history-restore.component.scss']
})
export class HistoryRestoreComponent extends BaseComponent implements OnInit {

  dtOptions: DataTables.Settings;
  dataTable: any = []
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective
  dtTrigger = new Subject();
  
  searchForm = new FormGroup({
    DatabaseName: new FormControl('',removeSpaces),
    FromDate: new FormControl('',removeSpaces),
    ToDate: new FormControl('',removeSpaces),
  })

  ngOnInit(): void {
    this.getToken();
    /**-------------------------USE dtOptions to format table-------------------------*/
    // Filter DATA TABLE
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: true,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        },
      },
      columns: [
        {
          title: 'DB Restore',
          className: "dt-center"
        },
        {
          title: 'Ngày Restore',
          className: "dt-center",
        },
        {
          title: 'Bắt đầu Backup',
          className: "dt-center",
        },
        {
          title: 'Hoàn thành Backup',
          className: "dt-center",
        },
      ],
    };
  this.GetHistory();
  }

  getListCustomer() {
    this.CustomerService.Load_List(this.Token).subscribe(
      (res) => {
        this.dataDB = res.listCustomers;
        this.dataDBFilter = [...new Set(this.dataDB.map(x => x.DatabaseName))];
      }
    );
  }

  Search() {
    this.GetHistory();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
  }
  GetHistory() {
    this.spinner.show();
    this.getListCustomer();
    this.CustomerService.ListHistoryDB_Search(this.searchForm.value,this.Token)
    .subscribe(z =>{
      this.dataTable = z.Data;
      this.dtTrigger.next();      
    })
    this.spinner.hide();
  }
}
