import { Component, OnInit } from '@angular/core';
import { AdminLogService } from 'src/app/utils/services/admin-log.service';
import { CustomerService } from 'src/app/utils/services/customer.service';
import { CustomerTypeService } from '../utils/services/customer-type.service';
import { DashboardService } from '../utils/services/dashboard.service';
import { ExportService } from '../utils/services/export.service';
import { ForgotPasswordService } from '../utils/services/forgot-password.service';
import { FunctionService } from '../utils/services/function.service';
import { LevelCustomerService } from '../utils/services/level-customer.service';
import { LevelDetailService } from '../utils/services/level-detail.service';
import { LevelFunctionService } from '../utils/services/level-function.service';
import { ModuleService } from '../utils/services/module.service';
import { PaymentPartnerService } from '../utils/services/payment-partner.service';
import { SystemService } from '../Utils/services/system.service';
import { TransactionService } from '../utils/services/transaction.service';
import { UpdateClientService } from '../utils/services/update-client.service';
import { UpdateVersionService } from '../utils/services/update-version.service';
import { UserService } from '../Utils/services/user.service';
import { VersionService } from '../utils/services/version.service';
import { DeploymentService } from '../utils/services/deployment.service';
import { common } from 'src/app/app.common';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder,AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Title } from '@angular/platform-browser';
import { AppService } from '../utils/services/app.service';
import { OutOfDateCustomerComponent } from '../components/customer/out-of-date-customer/out-of-date-customer.component';

export function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 2),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

export function createDate(year, month, date) {
  return new Date(year, (month - 1), (date + 1)).toISOString().split('T')[0];
}

export function getTheLastDay(y,m){
  return  new Date(y, m +1, 0).getDate();
}

interface OptionType {
  name: string;
  internal: number;
}

export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        opacity: 1,
        display: 'block'
      })),
      state('closed', style({
        opacity: 0,
        display: 'none'
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.1s')
      ]),
    ]),
  ],
  styleUrls: ['./base.component.scss']
})
export class BaseComponent {

  Data: any;
  selected_ID: any;
  searchString: any;
  UserID_get: any;
  UserName_get: any;
  Linechart = [];  
  Token: string;
  closeResult: string;
  submitted = false
  public com: common;
  public data: any;
  checkInsert:boolean = false;
  titleModal: any;
  chart: any
  dataType: any;
  dataChart: any;
  dataDB: any;
  dataDBFilter: any;
  versions: any;
  dataResponse: any;

  ID_function_selected: any;
  ID_customer_selected: any;
  count_function: any;
  count_customer: any;
  /** dùng để lọc dữ liệu trong bảng */
  searchFunction: any;
  searchCustomer: any;
  searchCustomerAdd: any;
  searchFunctionAdd: any;

  listFunctionByUserID: any;
  listCustomerByUserID: any;
  itemSelected: boolean = false;
  customerSelected: boolean = false;
  dataFunctionFilter: any;
  dataCustomerFilter: any;
  dataCustomerCodeFilter: any;
  dataServerFilter: any;
  dataTypeNameFilter: any;
  dataCustomerType: any;
  selected_database: any;
  searchModule: any;
  modules: any;
  dataModuleFilter: any;
  listModuleByCustomerID: any;
  ID_module_selected: any;
  count_module: any;
  dbName: any;
  svName: any;
  DATE = new Date();
  dayBefore: any;
  currentDate: any;
  dataCustomer: any;
  version_max: any;
  Url_update_get: any;
  customers: any;
  functions: any;
  dataModule: any;
  Revision_get: any;
  dataModuleCodeFilter: any;
  dataVersionNameFilter: any;
  dataRevisionFilter: any;
  dataStatistic: any;
  isDisplay: boolean = true;
  currentYear = this.DATE.getFullYear(); 
  currentMonth = this.DATE.getMonth();
  lastDateCurrentMonth = new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
  lastDateMonthBefore = new Date(this.currentYear, this.currentMonth - 1, 0).getDate();
  customerCode: any;
  dataTransferClass: any = [];
  dataStatisticUpdate: any = [];
  dataStatisticFunction: any = [];
  dataStatisticUser: any = [];
  styleBinding: any;
  /** panigation table */
  totalRecord: any = 0;
  page = 1;
  resultResponse: boolean = false;
  sum_transaction: number;
  sum_money: number;
  content_update: any;
  URL: any;
  URL_mark: any;
  URL_class: any;
  URL_teacher_room: any;

  selectDate = [
    { value: 1, name: "7 ngày qua" },
    { value: 2, name: "Tháng này" },
    { value: 3, name: "Tháng trước" },
    { value: 4, name: "Quý này" },
    { value: 5, name: "Quý trước" },
    { value: 6, name: "Năm nay" },
    { value: 7, name: "Năm trước" },
  ];

  optionType: OptionType[] = [
    {name: 'Tất cả', internal: null},
    {name: 'Khách hàng nội bộ', internal: 0},
    {name: 'Khách hàng ngoài', internal: 1}
  ];

  optionName: string = "Lọc khách hàng";
  optionInternal: number;
  
  stepArray = new Array(5);
  
  constructor(
    public titleService: Title,
    public spinner: NgxSpinnerService,
    public modalService: NgbModal,
    public router: Router,
    public fromBuilder: FormBuilder,
    public toastr: ToastrService,
    public AdminLogService: AdminLogService,
    public CustomerService: CustomerService,
    public CustomerTypeService: CustomerTypeService,
    public DashboardService: DashboardService,
    public ExportService: ExportService,
    public ForgotPasswordService: ForgotPasswordService,
    public FunctionService: FunctionService,
    public LevelCustomerService: LevelCustomerService,
    public LevelFunctionService: LevelFunctionService,
    public ModuleService: ModuleService,
    public PaymentPartnerService: PaymentPartnerService,
    public SystemService: SystemService,
    public TransactionService: TransactionService,
    public UpdateClientService: UpdateClientService,
    public UpdateVersionService: UpdateVersionService,
    public UserService: UserService,
    public VersionService: VersionService,
    public LevelDetailService: LevelDetailService,
    public DeploymentService: DeploymentService,
    public appService: AppService,
  ) { }

  Functions: any;
  QUAN_LY_KHACH_HANG: boolean = false;
  QUAN_LY_TRIEN_KHAI_DU_AN: boolean = false;
  QUAN_TRI_HE_THONG: boolean = false;
  DANH_MUC: boolean = false;

  QL_danh_sach_khach_hang: boolean = false;
  QL_doi_tac_tich_hop_tt: boolean = false;
  QL_muc_do_su_dung_kh: boolean = false;
  QL_muc_do_su_dung_cn: boolean = false;
  QL_tra_cuu_muc_do_su_dung: boolean = false;
  QL_thong_ke_giao_dich_tt: boolean = false;
  QL_trien_khai_khach_hang_moi: boolean = false;
  QL_cap_nhat_phien_ban: boolean = false;
  QL_don_vi_chua_cap_nhat: boolean = false;
  QL_danh_sach_nguoi_dung: boolean = false;
  QL_tra_cuu_log: boolean = false;
  QL_lich_su_restore: boolean = false;
  QL_lich_su_cap_nhat: boolean = false;
  //DANH MUC:
  QL_phien_ban: boolean = false;
  QL_phan_he: boolean = false;
  QL_loai_khach_hang: boolean = false;
  QL_danh_sach_quyen: boolean = false;

  getToken() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.UserID_get = a.Info.UserID;
    this.UserName_get = a.Info.UserName;
    this.Functions = a.Info.Functions;
    this.CreateMenu(this.Functions);
  }

  CreateMenu(User_function) {
    this.QUAN_LY_KHACH_HANG = User_function.some(x => x.FunctionCode == "1");
    this.QL_danh_sach_khach_hang = User_function.some(x => x.FunctionCode == "101");
    this.QL_doi_tac_tich_hop_tt = User_function.some(x => x.FunctionCode == "102");
    this.QL_muc_do_su_dung_kh = User_function.some(x => x.FunctionCode == "103");
    this.QL_muc_do_su_dung_cn = User_function.some(x => x.FunctionCode == "104");
    this.QL_tra_cuu_muc_do_su_dung = User_function.some(x => x.FunctionCode == "105");
    this.QL_thong_ke_giao_dich_tt = User_function.some(x => x.FunctionCode == "106");

    this.QUAN_LY_TRIEN_KHAI_DU_AN = User_function.some(x => x.FunctionCode == "2");
    this.QL_trien_khai_khach_hang_moi = User_function.some(x => x.FunctionCode == "201");
    this.QL_cap_nhat_phien_ban = User_function.some(x => x.FunctionCode == "202");
    this.QL_don_vi_chua_cap_nhat = User_function.some(x => x.FunctionCode == "203");

    this.QUAN_TRI_HE_THONG = User_function.some(x => x.FunctionCode == "3");
    this.QL_danh_sach_nguoi_dung = User_function.some(x => x.FunctionCode == "301");
    this.QL_tra_cuu_log = User_function.some(x => x.FunctionCode == "302");
    this.QL_lich_su_restore = User_function.some(x => x.FunctionCode == "303");
    this.QL_lich_su_cap_nhat = User_function.some(x => x.FunctionCode == "304");

    this.DANH_MUC = User_function.some(x => x.FunctionCode == "4");
    this.QL_phien_ban = User_function.some(x => x.FunctionCode == "401");
    this.QL_phan_he = User_function.some(x => x.FunctionCode == "402");
    this.QL_loai_khach_hang = User_function.some(x => x.FunctionCode == "403");
    this.QL_danh_sach_quyen = User_function.some(x => x.FunctionCode == "404");
  }

  dissmissModal(){
    this.modalService.dismissAll('');
  }
  
  public getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  filterDate(value) {
    let momentMonth = this.currentMonth + 1;
    this.currentDate = this.DATE.toISOString().split('T')[0];
    switch(value) {
      case 0:
        this.dayBefore = "";
        this.currentDate = "";
        break;
      case 1:
        this.dayBefore = formatDate(this.currentYear + "-" + this.currentMonth + "-" + (this.DATE.getDate()-7));
        break;
      case 2:
        this.dayBefore = momentMonth == 1 ? new Date(`${this.currentYear}-01-01`).toISOString().split('T')[0] : formatDate(this.currentYear + "-" + this.currentMonth + "-" + "01");
        this.currentDate = new Date(this.currentYear, this.currentMonth, this.lastDateCurrentMonth + 1).toISOString().split('T')[0];
        break;
      case 3:
        if (momentMonth == 1) {
          this.dayBefore = new Date(`${this.currentYear-1}-12-01`).toISOString().split('T')[0];
          this.currentDate = new Date(`${this.currentYear-1}-12-31`).toISOString().split('T')[0];
        }
        else if (momentMonth == 3) {
          this.dayBefore = formatDate(this.currentYear + "-" + (this.currentMonth-1) + "-" + "01");
          this.currentDate = formatDate(this.currentYear + "-" + (this.currentMonth-1) + "-" + getTheLastDay(2022,1));
        }
        else {
          this.dayBefore = formatDate(this.currentYear + "-" + (this.currentMonth-1) + "-" + "01");
          this.currentDate = formatDate(this.currentYear + "-" + (this.currentMonth-1) + "-" + (this.lastDateMonthBefore - 1));
        }
        break;
      case 4:
        if ((1 <= momentMonth)  && (momentMonth <= 3)) {
          this.dayBefore = createDate(this.currentYear, 1, 1);
          this.currentDate = createDate(this.currentYear, 3, 31);
        }
        if ((4 <= momentMonth)  && (momentMonth <= 6)) {
          this.dayBefore = createDate(this.currentYear, 4, 1);
          this.currentDate = createDate(this.currentYear, 6, 30);
        }
        if ((7 <= momentMonth)  && (momentMonth <= 9)) {
          this.dayBefore = createDate(this.currentYear, 7, 1);
          this.currentDate = createDate(this.currentYear, 9, 30);
        }
        if ((10 <= momentMonth)  && (momentMonth <= 12)) {
          this.dayBefore = createDate(this.currentYear, 10, 1);
          this.currentDate = createDate(this.currentYear, 12, 31);
        }
        break;
      case 5:
        if ((1 <= momentMonth)  && (momentMonth <= 3)) {
          this.dayBefore = createDate(this.currentYear-1, 10, 1);
          this.currentDate = createDate(this.currentYear-1, 12, 31);
        }
        if ((4 <= momentMonth)  && (momentMonth <= 6)) {
          this.dayBefore = createDate(this.currentYear, 1, 1);
          this.currentDate = createDate(this.currentYear, 3, 31);
        }
        if ((7 <= momentMonth)  && (momentMonth <= 9)) {
          this.dayBefore = createDate(this.currentYear, 4, 1);
          this.currentDate = createDate(this.currentYear, 6, 30);
        }
        if ((10 <= this.currentMonth)  && (this.currentMonth <= 12)) {
          this.dayBefore = createDate(this.currentYear, 7, 1);
          this.currentDate = createDate(this.currentYear, 9, 30);
        }
        break;
      case 6:
        this.dayBefore = createDate(this.currentYear, 1, 1);
        this.currentDate = createDate(this.currentYear, 12, 31);
        break;
      case 7:
        this.dayBefore = createDate(this.currentYear-1, 1, 1);
        this.currentDate = createDate(this.currentYear-1, 12, 31);
        break;
      default:  
    }
  }

  /** panigation table */
  getLastPage(numberOfRecord) {
    let surplus = this.totalRecord % numberOfRecord;
    let pageFilter = Math.floor(this.totalRecord/numberOfRecord);
    if (this.totalRecord > numberOfRecord) {
      if (surplus == 0) {
        this.page = pageFilter;
      }
      if (surplus > 0) {
        this.page = pageFilter + 1;
      }
    }
  }

  getNextPage(data) {
    this.page++;
    this.page = !(data.length > 0) ? this.page -= 1 : this.page;
  }
  
  getPreviewPage() {
    this.page--;
    this.page = (this.page <= 0) ? 1 : this.page;
  }
  
  pageReload() {
    location.reload();
  }

  clickFilter() {
    this.isDisplay = !this.isDisplay;
  }

  xoa_dau(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ|ị/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // huyền, sắc, hỏi, ngã, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // mũ â (ê), mũ ă, mũ ơ (ư)
    return str;
  }
}
