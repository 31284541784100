import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) { }

  Load_Count_Update(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Dashboard/Load_Count_Update', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }

  ThongKeTong(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Dashboard/ThongKeTong', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }

  ThongKeCapNhatPhienBanThang(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Dashboard/ThongKeCapNhatPhienBanThang', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }

  ThongKeMucSuDungTopThang(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Dashboard/ThongKeMucSuDungTopThang', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }

  DanhSachQuaHanCapNhatPhienBan(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Dashboard/DanhSachQuaHanCapNhatPhienBan', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
}
