import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestLogin } from './../../Models/input.model/RequestLogin';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ForgotPasswordService } from 'src/app/utils/services/forgot-password.service';

@Component({
  selector: 'app-comfirm-password',
  templateUrl: './comfirm-password.component.html',
  styleUrls: ['./comfirm-password.component.scss']
})
export class ComfirmPasswordComponent implements OnInit {

  Token_get: any;
  UserName_get: any;
  Email_get: any;
  title = "Đổi mật khẩu";
  requestObject: RequestLogin = new RequestLogin()
  RequestLogin: RequestLogin;
  Username: any;
  Old_Password: any;
  New_Password: any;
  Confirm_Password: any;
  ValidatePassword: any;
  isPassword: boolean = true;

  com: common;

  ChangePasswordForm = new FormGroup({
    New_Password_Form: new FormControl(null, [Validators.required]),
    Confirm_Password_Form: new FormControl(null, [Validators.required])
  })

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    public router: Router,
    private fromBuilder: FormBuilder,
    private toastr: ToastrService,
    private ForgotPasswordService: ForgotPasswordService
  ) { }

  ngOnInit(): void {
    // Lấy giá trị của parameter
    this.Token_get = this.route.snapshot.paramMap.get('Token');
    this.UserName_get = this.route.snapshot.paramMap.get('UserName');
    this.Email_get = this.route.snapshot.paramMap.get('Email');
  }
  show() {
    this.isPassword = !(this.isPassword);
  }
  ChangePass() {
    if (this.ChangePasswordForm.valid) {
      this.Username = this.UserName_get;
      this.New_Password = this.ChangePasswordForm.controls.New_Password_Form.value;
      this.Confirm_Password = this.ChangePasswordForm.controls.Confirm_Password_Form.value;
      this.requestObject.UserName = this.UserName_get;
      if (this.New_Password == this.Confirm_Password) {
        let req = {
          UserName: this.UserName_get,
          NewPassword: this.New_Password,
          Email: this.Email_get
        }
        this.ForgotPasswordService.ForgotPassword(req, this.Token_get)
          .subscribe(z => {
            if (z.Status == 1) {
              this.toastr.success('Đổi mật khẩu thành công');
              this.router.navigate(['/']);
            } else {
              this.toastr.error(z.Message, 'Thao tác thất bại');
            }
          })
        }
      } else {
        this.toastr.error('Vui lòng nhập đầy đủ thông tin', 'Tác vụ thất bại');
      };
  }
}
