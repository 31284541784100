import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { BaseComponent, removeSpaces } from 'src/app/base/base.component';

@Component({
  selector: 'app-step2-modal',
  templateUrl: './step2-modal.component.html',
  styleUrls: ['./step2-modal.component.scss']
})
export class Step2ModalComponent extends BaseComponent implements OnInit {

  @Output() submit: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  @Input() titleModalInput: any;
  @Input() dataCustomerInput: any;
  @Input() URL_markInput: any;
  @Input() URL_classInput: any;
  @Input() URL_teacher_roomInput: any;

  dataCustomerFilter: any = [];
  count_teacher: number;
  count_room: number;
  dataTenHeFilter: any;
  dataKhoaHocFilter: any;
  dataTenKhoaFilter: any;
  dataTenChuyenNganhFilter: any;
  value_Khoa_hoc_max: number;
  dataTransferMark: any;
  dataTeacherRoom: any;
  req: any;

  searchForm = new FormGroup({
    Ten_he: new FormControl(''),
    Khoa_hoc: new FormControl(''),
    Ten_khoa: new FormControl(''),
    Ten_chuyen_nganh: new FormControl('')
  });


  ngOnInit(): void {
    this.dataCustomerFilter = this.dataCustomerInput;
    this.getRequest();
    this.loadDataSearchTransfer();
    this.loadDatatransferTeacherRoom();
  }

  getRequest() {
    this.req = {
      URL_Mark: this.URL_markInput,
      URL_Class: this.URL_classInput,
      URL_Teacher: this.URL_teacher_roomInput
    }
  }

  loadDataSearchTransfer() {
    this.spinner.show();
    this.DeploymentService.Load_DataSearch_Transfer(this.req).subscribe(
      (res) => {
        if (res.Status == 1) {
          this.dataTenHeFilter = res.List_Ten_he;
          this.dataKhoaHocFilter = res.List_Khoa_hoc;
          this.value_Khoa_hoc_max = Math.max(...this.dataKhoaHocFilter.map(x => x.Khoa_hoc));
          this.searchForm.patchValue({
            Khoa_hoc: this.value_Khoa_hoc_max
          })
          this.dataTenKhoaFilter = res.List_Ten_khoa;
          this.dataTenChuyenNganhFilter = res.List_Ten_chuyen_nganh;
          this.loadDataTransferClass();
          this.spinner.hide();
        }
        else {
          this.toastr.warning(res.Message);
          this.spinner.hide();
        }
      }
    );
  }

  loadDatatransferTeacherRoom() {
    this.spinner.show();
    this.DeploymentService.Load_Datatransfer_Teacher_Room(this.req).subscribe(
      (res) => {
        if (res.Status == 1) {
          this.dataTeacherRoom = res.Data;
          this.count_teacher = this.dataTeacherRoom[0].So_giang_vien;
          this.count_room = this.dataTeacherRoom[0].So_phong_hoc;
          this.spinner.hide();
        }
        else {
          this.toastr.warning(res.Message);
          this.spinner.hide();
        }
      }
    );
  }
  
  loadDataTransferClass() {
    let obj = {
      URL_Mark: this.URL_markInput,
      URL_Class: this.URL_classInput,
      URL_Teacher: this.URL_teacher_roomInput,
      Ten_he: this.searchForm.value.Ten_he,
      Khoa_hoc: this.searchForm.value.Khoa_hoc,
      Ten_khoa: this.searchForm.value.Ten_khoa,
      Ten_chuyen_nganh: this.searchForm.value.Ten_chuyen_nganh,
    }
    this.spinner.show();
    this.DeploymentService.Load_Datatransfer_Class(obj).subscribe(
      (res) => {
        if (res.Status == 1) {
          this.dataTransferClass = res.Data;
          this.dataTransferMark = res.DataMark;
          this.spinner.hide();
        }
        else {
          this.toastr.warning(res.Message);
          this.spinner.hide();
        }
      }
    );
  }  

  dataTransferClassFilter: any;
  dataTransferMarkFilter: any;

  loadListFilter(khoa_hoc, ten_khoa) {
    this.dataTransferClassFilter = this.dataTransferClass.filter(x => x.Khoa_hoc == khoa_hoc && x.Ten_khoa == ten_khoa);
    return this.dataTransferClassFilter;
  }

  loadListMarkFilter(khoa_hoc, ten_khoa, ten_chuyen_nganh) {
    this.dataTransferMarkFilter = this.dataTransferMark.filter(x => x.Khoa_hoc == khoa_hoc && x.Ten_khoa == ten_khoa && x.Ten_chuyen_nganh == ten_chuyen_nganh);
    return this.dataTransferMarkFilter;
  }
  
  countSoLop(khoa_hoc, ten_khoa) {
    return this.loadListFilter(khoa_hoc, ten_khoa).map(x => x.So_lop).reduce((a, b) => a + b, 0);
  }
  countSoSV(khoa_hoc, ten_khoa) {
    return this.loadListFilter(khoa_hoc, ten_khoa).map(x => x.So_sv).reduce((a, b) => a + b, 0);
  }
  countSoCTDT(khoa_hoc, ten_khoa) {
    return this.loadListFilter(khoa_hoc, ten_khoa).map(x => x.So_CTDT).reduce((a, b) => a + b, 0);
  }

  countMarkSoLop(khoa_hoc, ten_khoa, ten_chuyen_nganh) {
    let total = this.loadListMarkFilter(khoa_hoc, ten_khoa, ten_chuyen_nganh).filter(x => (x.Ten_lop != ""));
    return total.length;
  }
  countMarkSoSV(khoa_hoc, ten_khoa, ten_chuyen_nganh) {
    return this.loadListMarkFilter(khoa_hoc, ten_khoa, ten_chuyen_nganh).map(x => x.So_sv).reduce((a, b) => a + b, 0);
  }
  countMarkSoCTDT(khoa_hoc, ten_khoa, ten_chuyen_nganh) {
    return this.loadListMarkFilter(khoa_hoc, ten_khoa, ten_chuyen_nganh).map(x => x.So_mon_CTDT).reduce((a, b) => a + b, 0);
  }
  countMarkSoMonCoDiem(khoa_hoc, ten_khoa, ten_chuyen_nganh) {
    return this.loadListMarkFilter(khoa_hoc, ten_khoa, ten_chuyen_nganh).map(x => x.So_mon_diem).reduce((a, b) => a + b, 0);
  }
  countMarkSoMonChoDiem(khoa_hoc, ten_khoa, ten_chuyen_nganh) {
    return this.loadListMarkFilter(khoa_hoc, ten_khoa, ten_chuyen_nganh).map(x => x.So_mon_chua_diem).reduce((a, b) => a + b, 0);
  }

  cancel(){
    this.close.emit()
  }

  filterCustomer() {
    if (this.searchString != '') {
      this.dataCustomerInput = this.dataCustomerFilter.filter(x => this.xoa_dau(x.CustomerName.toLowerCase()).includes(this.xoa_dau(this.searchString.toLowerCase())));
    }
    else {
      this.dataCustomerInput = this.dataCustomerFilter;
    }
  }
}
