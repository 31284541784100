import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
import { Version } from './../../Models/version';

@Injectable({
  providedIn: 'root'
})
export class PaymentPartnerService {

  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) { }

  getList(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'PaymentPartner/Load_List', {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  insert(PaymentPartner: any, token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'PaymentPartner/Insert',PaymentPartner,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  delete(ID_doi_tac: number, token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'PaymentPartner/Delete?ID_doi_tac='+ID_doi_tac,{
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }

  update(PaymentPartner: any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'PaymentPartner/Update',PaymentPartner,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }
}
