<!----------------------------------------------- HEADER ------------------------------------------------->
<section class="content-header" style="padding:0.5%  !important">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-6">
        <ol class="breadcrumb float-left">
          <li class="breadcrumb-item"><a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
          </li>
          <li class="breadcrumb-item active">Quản lí khách hàng</li>
        </ol>
      </div>
      <div class="col-md-6">
        <button class="btn btn-outline-success btn-sm" (click)="open(AddModal,'lg','Add','')" style="float: right;"><i
            class="fas fa-plus-circle"></i> Thêm mới
        </button>
        <button class="btn btn-outline-success btn-sm" type="submit" style="float: right;margin-right: 5px;"
          (click)="Excel()">
          <i class="fas fa-file-excel"></i> Xuất báo cáo
        </button>
      </div>
    </div>
  </div>
</section>
<!----------------------------------------------- FILTER ------------------------------------------------->
<section class="content cus-body ">
  <div class="card">
    <div class="card-body">
      <form [formGroup]="searchForm">
        <div class="row">
          <div class="col-md-4">
            <label class="col-md-10" style="margin-top: 10px;">Mã khách hàng</label>
            <ng-select formControlName="CustomerCode" (change)="filterById()" notFoundText="Không có kết quả">
              <ng-option value="">Tất cả</ng-option>
              <ng-option *ngFor="let customer of dataCustomerCodeFilter" [value]=customer>{{customer}}</ng-option>
            </ng-select>
          </div>
          <div class="col-md-4">
            <label class="col-md-10 " style="margin-top: 10px;">Tên khách hàng</label>
            <input class="form-control" placeholder="Nhập tên khách hàng" type="text" formControlName="CustomerName"
              (keyup)="filterById()" />
          </div>
          <!-- <div class="col-md-4">
                <label class="col-md-10 " style="margin-top: 10px;">URL</label>
                <input class="form-control" placeholder="Nhập URL" type="text"
                    formControlName="Url_update" (keyup)="filterById()" />
              </div>
              <div class="col-md-4">
                <label class="col-md-10 " style="margin-top: 10px;">Database</label>
                <ng-select formControlName="DatabaseName" (change)="filterById()" notFoundText="Không có kết quả">
                    <ng-option value="">Tất cả</ng-option>
                    <ng-option *ngFor="let data of dataDBFilter" [value]=data>{{data}}</ng-option>
                </ng-select>
              </div> -->
          <!-- <div class="col-md-4">
                <label class="col-md-10 " style="margin-top: 10px;">Server</label>
                <ng-select formControlName="ServerName" (change)="filterById()" notFoundText="Không có kết quả">
                    <ng-option value="">Tất cả</ng-option>
                    <ng-option *ngFor="let server of dataServerFilter" [value]=server>{{server}}</ng-option>
                </ng-select>
              </div> -->
          <div class="col-md-4">
            <label class="col-md-10 " style="margin-top: 10px;">Loại khách hàng</label>
            <ng-select formControlName="TypeName" (change)="filterById()" notFoundText="Không có kết quả">
              <ng-option value="">Tất cả</ng-option>
              <ng-option *ngFor="let type of dataTypeNameFilter" [value]=type>{{type}}</ng-option>
            </ng-select>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
<!----------------------------------------------- TABLE ------------------------------------------------->
<section class="content cus-body">
  <div class="card">
    <div class="card-body">
      <div class="tab-content">
        <div class="active tab-pane">
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                <table style="width:100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                  class="table-bordered dataTables_wrapper no-footer">
                  <thead style="text-align: center;">
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let customer of dataTable; let i=index">
                      <td>{{i+1}}</td>
                      <td>{{customer.CustomerCode}}</td>
                      <td style="word-wrap: break-word;max-width: 200px;">{{customer.CustomerName}}</td>
                      <td>{{customer.TypeName}}</td>
                      <td style="word-wrap: break-word;max-width: 200px;">{{customer.Url_update}}</td>
                      <td>{{customer.DatabaseName}}</td>
                      <td>{{customer.ServerName}}</td>
                      <td style="white-space: nowrap;">
                        <button class="btn btn-outline-secondary btn-sm" ngbTooltip="Restore database"
                          (click)="open(RestoreModal, 'xs', 'Restore', customer)" style="margin-right: 5px;">
                          <i class="fas fa-database"></i>
                        </button>
                        <button class="btn btn-outline-warning btn-sm" ngbTooltip="Lịch sử restore"
                          (click)="open(HistoryModal, 'xl', 'ViewHistory', customer)" style="margin-right: 5px;">
                          <i class="fas fa-eye"></i>
                        </button>
                        <button class="btn btn-outline-primary btn-sm" ngbTooltip="Backup Database"
                          (click)="open(BackupModal, 'xs', 'Backup', customer)" style="margin-right: 5px;">
                          <i class="fa fa-retweet" aria-hidden="true"></i>
                        </button>
                      </td>
                      <td style="white-space: nowrap;">
                        <button class="btn btn-outline-success btn-sm" ngbTooltip="Xem phân hệ"
                          style="margin-right: 5px;" (click)="open(ModuleModal, 'xl', 'ViewModule', customer)">
                          <i class="fas fa-list-alt"></i>
                        </button>
                      </td>
                      <td style="white-space: nowrap;">
                        <button class="btn btn-outline-info btn-sm" (click)="open(AddModal, 'lg', 'Update', customer)"
                          style="margin-right: 5px;">
                          <i class="fas fa-edit"></i>
                        </button>
                        <button class="btn btn-outline-danger btn-sm"
                          (click)="open(DeleteModal, 'xs', 'Delete', customer)">
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-----------------------------------------------ADD MODAL ------------------------------------------------->
<ng-template #AddModal let-modal>
  <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
    <h4 class="modal-title h-title w-100"> {{titleModal}}</h4>
    <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="Insert">
      <div class="row">
        <div class="col-md-12">
          <label class="col-md-10 require">Mã khách hàng</label>
          <input type="text" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && checkvalueInsert.CustomerCode.errors }" [readonly]="!checkInsert"
            formControlName="CustomerCode" placeholder="Nhập mã khách hàng...">
          <div *ngIf="submitted && checkvalueInsert.CustomerCode.errors" class="invalid-feedback">
            <div *ngIf="checkvalueInsert.CustomerCode.errors.required">
              Mã trường không được bỏ trống!
            </div>
            <div *ngIf="checkvalueInsert.CustomerCode.errors.maxlength">
              Mã khách hàng tối đa 100 ký tự!
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <label class="col-md-10 require">Tên khách hàng</label>
          <input type="text" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && checkvalueInsert.CustomerName.errors }"
            formControlName="CustomerName" placeholder="Nhập tên khách hàng...">
          <div *ngIf="submitted && checkvalueInsert.CustomerName.errors" class="invalid-feedback">
            <div *ngIf="checkvalueInsert.CustomerName.errors.required">
              Tên khách hàng không được bỏ trống!
            </div>
            <div *ngIf="checkvalueInsert.CustomerName.errors.maxlength">
              Tên khách hàng tối đa 500 ký tự!
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <label class="col-md-10 require">URL</label>
          <input type="text" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && checkvalueInsert.Url_update.errors }" formControlName="Url_update"
            placeholder="Url...">
          <div *ngIf="submitted && checkvalueInsert.Url_update.errors" class="invalid-feedback">
            <div *ngIf="checkvalueInsert.Url_update.errors.required">
              URL không được bỏ trống!
            </div>
            <div *ngIf="checkvalueInsert.Url_update.errors.maxlength">
              URL tối đa 500 ký tự!
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-10 require">Database</label>
          <input type="text" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && checkvalueInsert.DatabaseName.errors }"
            formControlName="DatabaseName" placeholder="Database...">
          <div *ngIf="submitted && checkvalueInsert.DatabaseName.errors" class="invalid-feedback">
            <div *ngIf="checkvalueInsert.DatabaseName.errors.required">
              Database không được bỏ trống!
            </div>
            <div *ngIf="checkvalueInsert.DatabaseName.errors.maxlength">
              Database tối đa 200 ký tự!
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label class="col-md-10 require">Server</label>
          <input type="text" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && checkvalueInsert.ServerName.errors }" [readonly]="!checkInsert"
            formControlName="ServerName" placeholder="Server...">
          <div *ngIf="submitted && checkvalueInsert.ServerName.errors" class="invalid-feedback">
            <div *ngIf="checkvalueInsert.ServerName.errors.required">
              Server không được bỏ trống!
            </div>
            <div *ngIf="checkvalueInsert.ServerName.errors.maxlength">
              Server tối đa 200 ký tự!
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <label class="col-md-10 require">Loại khách hàng</label>
          <ng-select formControlName="TypeID" notFoundText="Không có kết quả"
            [ngClass]="{ 'is-invalid': submitted && checkvalueInsert.TypeID.errors }">
            <ng-option value="">Chọn loại khách hàng</ng-option>
            <ng-option *ngFor="let type of dataCustomerType" [value]=type.TypeID>{{type.TypeName}}</ng-option>
          </ng-select>
          <div *ngIf="submitted && checkvalueInsert.TypeID.errors" class="invalid-feedback">
            <div *ngIf="checkvalueInsert.TypeID.errors.required">
              Loaị khách hàng không được bỏ trống!
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-outline-success btn-sm" (click)="onSubmit()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button type="button" class="btn btn-outline-danger btn-sm" (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;">
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>

<!-----------------------------------------------DELETE MODAL ------------------------------------------------->
<ng-template #DeleteModal let-modal>
  <app-delete-modal (delete)="deleteItem()" (close)="dissmissModal()"></app-delete-modal>
</ng-template>


<!-----------------------------------------------MODULE MODAL ------------------------------------------------->
<ng-template #ModuleModal let-modal>
  <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
    <h4 class="modal-title h-title w-100"> {{titleModal}}</h4>
    <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
  </div>
  <div class="container" style="margin-bottom: 30px;margin-top:30px">
    <input type="text" class="form-control" (keyup)="changeModule()" [(ngModel)]="searchModule"
      placeholder="Tìm kiếm tên phân hệ...">
  </div>
  <div class="modal-body" style="overflow-y:auto;height: 500px;">
    <div class="total-page col-md-12">
      <div class="row">
        <div class="half-page col-md-6">
          <h5>&nbsp;</h5>
          <table class="table table-bordered">
            <thead class="thead-dark" style="text-align: center;">
              <tr>
                <th scope="col">
                  <input id="SelectALL" type="checkbox" [(ngModel)]="itemSelected" (change)="checkUncheckAll()">
                </th>
                <th scope="col">Mã phân hệ</th>
                <th scope="col">Tên phân hệ</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let module of modules  | filter: searchModule">
                <td *ngIf="!module.checked" style="text-align: center;width: 10vh;">
                  <input (change)="total_count_module()" type="checkbox" [checked]="module.checked"
                    [(ngModel)]="module.checked">
                </td>
                <td style="text-align: center;width: 20vh;" *ngIf="!module.checked">{{module.ModuleCode}}</td>
                <td style="word-wrap: break-word;max-width: 70vh;" *ngIf="!module.checked">{{module.ModuleName}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="half-page col-md-6">
          <h5>Số lượng phân hệ: {{count_module}}</h5>
          <table class="table" class="table table-bordered">
            <thead class="thead-dark" style="text-align: center;">
              <tr>
                <th scope="col" (click)="DestroyCheck()"><i class="fas fa-window-close"></i></th>
                <th scope="col">Mã phân hệ</th>
                <th scope="col">Tên phân hệ</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let moduleByCustomerID  of modules  | filter: searchModule; let i=index">
                <td *ngIf="moduleByCustomerID.checked" style="text-align: center;width: 10vh;">
                  <input (change)="isAllSelected()" (click)="unCheckModule()" (change)="total_count_module()"
                    type="checkbox" [checked]="moduleByCustomerID.checked" [(ngModel)]="moduleByCustomerID.checked">
                </td>
                <td style="text-align: center;width: 20vh;" *ngIf="moduleByCustomerID.checked">
                  {{moduleByCustomerID.ModuleCode}}</td>
                <td style="word-wrap: break-word;max-width: 70vh;" *ngIf="moduleByCustomerID.checked">
                  {{moduleByCustomerID.ModuleName}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-outline-success btn-sm" (click)="CustomerModuleSave()">
        <i class="fas fa-save"></i> Lưu
      </button>
      <button type="button" class="btn btn-outline-danger btn-sm" (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;">
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
<!-----------------------------------------------HISTORY MODAL ------------------------------------------------->
<ng-template #HistoryModal let-modal>
  <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
    <h4 class="modal-title h-title w-100">{{titleModal}}</h4>
    <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
  </div>
  <div class="icon-box" style="margin-top: 20px;border: 3px solid #17a2b8!important;">
    <i class="fas fa-history" style="color:#17a2b8;font-size: 46px;display: inline-block;margin-top: 13px;"></i>
  </div>
  <div class="modal-body" style="overflow-y:auto;max-height: 60vh;">
    <app-history-db [DatabaseName]="selected_database"></app-history-db>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-outline-danger btn-sm" (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;">
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
<!-----------------------------------------------RESTORE MODAL ------------------------------------------------->
<ng-template #RestoreModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i class="fas fa-database" style="color: #f15e5e;font-size: 46px;display: inline-block;margin-top: 13px;"></i>
      </div>
      <p
        style="color: inherit; text-decoration: none;font-size: 20px; text-align: center; font-weight: 600; margin-top: 3em;">
        Bạn có chắc chắn muốn Restore Database ?
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-outline-success btn-sm" (click)="RestoreDB()">
          Đồng ý
        </button>
        <button type="button" class="btn btn-outline-danger btn-sm" (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;">
          Hủy bỏ
        </button>

      </div>
    </div>
  </div>
</ng-template>

<!-----------------------------------------------RESTORE MODAL ------------------------------------------------->
<ng-template #BackupModal let-modal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="icon-box">
        <i class="fas fa-database" style="color: #f15e5e;font-size: 46px;display: inline-block;margin-top: 13px;"></i>
      </div>
      <p
        style="color: inherit; text-decoration: none;font-size: 20px; text-align: center; font-weight: 600; margin-top: 3em;">
        Bạn có chắc chắn muốn Backup Database ?
      </p>
      <div class="text-center">
        <button type="button" class="btn btn-outline-success btn-sm" (click)="backupDB()">
          Đồng ý
        </button>
        <button type="button" class="btn btn-outline-danger btn-sm" (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;">
          Hủy bỏ
        </button>

      </div>
    </div>
  </div>
</ng-template>