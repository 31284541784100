<!----------------------------------------------- HEADER ------------------------------------------------->
<section class="content-header" style="padding:0.5%  !important">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-md-6">
          </div>
          <div class="col-md-6">
              <!-- <button class="btn btn-outline-info btn-sm" (click)="filterById()" style="float: right;">
                <i class="fas fa-search"></i> Tìm kiếm
              </button> -->
              <button class="btn btn-outline-success btn-sm"  type="submit" style="float: right;margin-right: 5px;" (click)="Excel()">
                <i class="fas fa-file-excel"></i> Xuất báo cáo
              </button>
          </div>
      </div>
  </div>
</section>
<!----------------------------------------------- FILTER ------------------------------------------------->
<section class="content cus-body ">
  <div class="card">
      <div class="card-body">
          <form [formGroup]="searchForm">
            <div class="row">
              <div class="col-md-4">
                <label class="col-md-10 " style="margin-top: 10px;">Mã khách hàng</label>
                <ng-select formControlName="CustomerCode" (change)="filterById()" notFoundText="Không có kết quả">
                    <ng-option value="">Tất cả</ng-option>
                    <ng-option *ngFor="let customer of dataCustomerCodeFilter" [value]=customer>{{customer}}</ng-option>
                </ng-select>
              </div>
              <div class="col-md-4">
                <label class="col-md-10 " style="margin-top: 10px;">Tên khách hàng</label>
                <input class="form-control" placeholder="Nhập tên khách hàng" type="text"
                    formControlName="CustomerName" (keyup)="filterById()" />
              </div>
              <div class="col-md-4">
                <label class="col-md-10 " style="margin-top: 10px;">URL</label>
                <input class="form-control" placeholder="Nhập URL" type="text"
                    formControlName="Url_update" (keyup)="filterById()" />
              </div>
              <div class="col-md-4">
                <label class="col-md-10 " style="margin-top: 10px;">Database</label>
                <ng-select formControlName="DatabaseName" (change)="filterById()" notFoundText="Không có kết quả">
                    <ng-option value="">Tất cả</ng-option>
                    <ng-option *ngFor="let data of dataDBFilter" [value]=data>{{data}}</ng-option>
                </ng-select>
              </div>
              <div class="col-md-4">
                <label class="col-md-10 " style="margin-top: 10px;">Server</label>
                <ng-select formControlName="ServerName" (change)="filterById()" notFoundText="Không có kết quả">
                    <ng-option value="">Tất cả</ng-option>
                    <ng-option *ngFor="let server of dataServerFilter" [value]=server>{{server}}</ng-option>
                </ng-select>
              </div>
              <div class="col-md-4">
                <label class="col-md-10 " style="margin-top: 10px;">Loại khách hàng</label>
                <ng-select formControlName="TypeName" (change)="filterById()" notFoundText="Không có kết quả">
                    <ng-option value="">Tất cả</ng-option>
                    <ng-option *ngFor="let type of dataTypeNameFilter" [value]=type>{{type}}</ng-option>
                </ng-select>
              </div>


            </div>
      </form>
      </div>
  </div>
</section>
<!----------------------------------------------- TABLE ------------------------------------------------->
<section class="content cus-body">
  <div class="card">
      <div class="card-body">
          <div class="tab-content">
              <div class="active tab-pane">
                  <div class="row">
                      <div class="col-md-12">
                          <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                              <table style="width:100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                  class="table-bordered dataTables_wrapper no-footer">
                              <thead style="text-align: center;">
                                  <tr>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let customer of dataTable; let i=index" >
                                      <td>{{i+1}}</td>
                                      <td  style="width: 17vh !important;">{{customer.CustomerCode}}</td>
                                      <td  style="width: 25vh !important;">{{customer.CustomerName}}</td>
                                      <td>{{customer.TypeName}}</td>
                                      <td style="word-wrap: break-word;max-width: 350px;">{{customer.Url_update}}</td>
                                      <td>{{customer.DatabaseName}}</td>
                                      <td>{{customer.ServerName}}</td>
                                      <td>
                                        <button class="btn btn-outline-sm btn-success"
                                        ngbTooltip="Xem phân hệ"
                                        style="margin-right: 5px;"
                                        (click)="open(ModuleModal, 'lg', 'ViewModule', customer)">
                                        <i class="fas fa-list-alt"></i>
                                        </button>
                                    </tr>
                                </tbody>
                              </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-----------------------------------------------MODULE MODAL ------------------------------------------------->
<ng-template #ModuleModal let-modal>
<div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
  <h4 class="modal-title h-title w-100"> Danh sách phân hệ</h4>
  <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
</div>
<div class="modal-body" style="overflow-y:auto;height: 500px;">
  <app-module-customer [CustomerID]="this.selected_ID"></app-module-customer>
</div>
<div class="modal-footer">
  <div class="float-right">
    <button type="button" class="btn btn-outline-md btn-success">
      <i class="fas fa-save"></i> Lưu
    </button>
    <button type="button" class="btn btn-outline-md btn-danger" (click)="modal.dismiss('Cross click')"
      style="margin-left: 1em;">
      <i class="far fa-times-circle"></i> Đóng
    </button>
  </div>
</div>
</ng-template>

