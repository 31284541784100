import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ForgotPasswordService } from 'src/app/utils/services/forgot-password.service';

export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  submitted = false;
  modalRef: any;
  titleModal: any;
  closeResult: string;
  
  ForgotPassword = new FormGroup({
    Email: new FormControl(null, [Validators.required,removeSpaces]),
    UserName: new FormControl(null, [Validators.required,removeSpaces]),
  });
  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private ForgotPasswordService: ForgotPasswordService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  get checkvalue() {
    return this.ForgotPassword.controls;
  }

  goToItemsWithParams(UserName: string,Email: string,Token: string) {
    this.router.navigate(['/item-component', { UserName,Email,Token }]);
  }
  onSubmit() {
    this.submitted = true;
    if (this.ForgotPassword.invalid) {
      this.spinner.hide();
      return false;
    }
    let req = {
      Email : this.ForgotPassword.value.Email,
      UserName : this.ForgotPassword.value.UserName,
    }
    this.ForgotPasswordService.FindPassword(req).subscribe(
      (res) => {
        this.spinner.show();
        if (res.Status == 2) {
          this.spinner.show();
          this.toastr.warning(res.Message);
          this.spinner.hide();
        } else if (res.Status == 1) {
          this.spinner.show();
          this.toastr.success(res.Message);
          this.spinner.hide();
        } else if (res.Status == 4) {
          this.spinner.show();
          this.toastr.error('Thao tác thất bại!');
          this.spinner.hide();
        }
      }
    );
  }
}
