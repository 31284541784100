import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { BaseComponent, removeSpaces } from 'src/app/base/base.component';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-step4-modal',
  templateUrl: './step4-modal.component.html',
  styleUrls: ['./step4-modal.component.scss']
})
export class Step4ModalComponent extends BaseComponent implements OnInit {
  
  @Output() submit: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  @Input() titleModalInput: any;
  @Input() customerCodeInput: any;

  ngOnInit(): void {
    this.getToken();
    this.getDataFunction();
    this.getDataUser();
  }

  getDataFunction() {
    this.spinner.show();
    this.DeploymentService.StatisticFunction(this.customerCodeInput, this.Token).subscribe(
      (res) => {
        this.dataStatisticFunction = res.DataFunction;
        this.spinner.hide();
      }
    );
  }

  getDataUser() {
    this.spinner.show();
    this.DeploymentService.StatisticUser(this.customerCodeInput, this.Token).subscribe(
      (res) => {
        this.dataStatisticUser = res.DataUser;
        if (this.dataStatisticUser.length > 0) {
          this.styleBinding = "height: 50vh; overflow-y: auto;border: solid 1px #dee2e6;"
        }
        else {
          this.styleBinding = "";
        }
        this.spinner.hide();
      }
    );
  }

  cancel(){
    this.close.emit()
  }
}
