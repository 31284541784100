import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
import { Customer } from './../../Models/customer';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) { }

  Load_List(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Customer/Load_List', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  Customer_UserID(UserID: number, token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Customer/Customer_UserID?UserID='+UserID,{
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  Customer_Updated(req: any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Customer/Customer_Updated',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }
  Customer_NoneUpdated(req: any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Customer/Customer_NoneUpdated',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }
  Update(req: any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Customer/Update_Customer',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }
  Insert(customer: any, token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Customer/Insert',customer,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }
  Delete(CustomerID: number, token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Customer/Delete_Customer?CustomerID='+CustomerID,{
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  Customer_Module(CustomerID: number, token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Module/Customer_Module?CustomerID='+CustomerID,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  ListHistoryDB(DatabaseName: string,token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Customer/ListHistoryDB?DatabaseName='+DatabaseName, {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  ListHistoryDB_Search(req:any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Customer/ListHistoryDB_Search',req, {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  RestoreDB(req: any, token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'UpdateVersion/RestoreDB',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  Load_List_Module(CustomerID:number,token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Customer_Module/Load_List?DatabaseName='+CustomerID, {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }

  Insert_Module(module: any, token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Customer_Module/Insert_Customer_Module',module,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  Excel(req: any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Excel/CustomerUser_Excel',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  CustomerUserUpdated_Excel(req: any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Excel/CustomerUserUpdated_Excel',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  
  CustomerUserNoneUpdated_Excel(req: any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Excel/CustomerUserNoneUpdated_Excel',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  backupDatabase(req: any, token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Deployment/BackupDatabase',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }
}
