import { Component, OnInit } from '@angular/core';
import { common } from 'src/app/app.common';
import { Chart } from 'chart.js';
import { BaseComponent } from 'src/app/base/base.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends BaseComponent implements OnInit {
  data: any
  Token: any
  chart: any

  dataUser: any;
  dataCustomer: any;
  dataModule: any;
  dataFunction: any;
  dataVersion: any;
  dataUpdate: any;
  dataChart: any;

  count_user: any;
  count_customer: any;
  count_customer_inYear: any;
  count_customer_outOfDate: any;
  count_customer_outOfDate2: any;
  count_customer_service: any;
  count_customer_online: any;
  count_trade: any;
  count_module: any;
  count_module_outOfDate: any;
  count_function: any;
  count_version: any;
  count_database: any;

  Player = [];  
  Run = [];  
  Linechart1 = [];  
  Linechart2 = [];  

  public com: common;


  dataThongKeTong: any;
  dataThongKeCNPBThang: any;
  dataThongKeMSDTopThang: any;
  dataDanhSachQuaHanCNPB: any;

  styleDashboardCustomerWithRole: string = "color: white;text-decoration: none !important;";

  ngOnInit() {
    this.getToken();
    this.styleDashboardCustomerWithRole = this.QL_danh_sach_khach_hang ? this.styleDashboardCustomerWithRole : this.styleDashboardCustomerWithRole + "pointer-events: none;";

    this.titleService.setTitle('Trang chủ | Update Version');
    if (!this.Functions) {
      this.toastr.warning("Bạn chưa được cấp bất ký quyền nào, vui lòng liên hệ với quản trị viên để được hỗ trợ !");
    }
    this.getThongKeTong();
    this.getThongKeCNPBThang();
    this.getThongKeMSDTopThang();
    this.getDanhSachQuaHanCNPB();
  }


  getThongKeTong() {
    this.DashboardService.ThongKeTong(this.Token).subscribe(
      (res) => {
        this.dataThongKeTong = res.DataTKT[0];
        this.count_customer = this.dataThongKeTong.So_khach_hang;
        this.count_customer_inYear = this.dataThongKeTong.So_khach_hang_nam_hien_tai;
        this.count_customer_outOfDate = this.dataThongKeTong.So_khach_hang_cap_nhat_qua_han;
        this.count_module_outOfDate = this.dataThongKeTong.So_phan_he_cap_nhat_qua_han;
        this.count_customer_service = this.dataThongKeTong.So_khach_hang_dich_vu;
        this.count_customer_outOfDate2 = this.dataThongKeTong.So_khach_hang_qua_han;
        this.count_customer_online = this.dataThongKeTong.So_khach_hang_thanh_toan_online;
        this.count_trade = this.dataThongKeTong.So_luong_giao_dich_thang;
      }
    );
  }

  getThongKeCNPBThang() {
    this.DashboardService.ThongKeCapNhatPhienBanThang(this.Token).subscribe(
      (res) => {
        this.dataThongKeCNPBThang = res.DataTKCN;
        this.Linechart1 = new Chart('canvas1', {  
          type: 'bar',  
          data: {  
            labels: this.dataThongKeCNPBThang.map(x => x.CustomerCode),               
            datasets: [  
              {  
                data: this.dataThongKeCNPBThang.map(x => x.So_ngay_chua_cap_nhat),  
                borderColor: '#3cb371',  
                backgroundColor: "#17a2b8",  
              }  
            ]  
          },  
          options: {  
            legend: {  
              display: false  
            },  
            scales: {  
              xAxes: [{  
                display: true  
              }],  
              yAxes: [{  
                display: true  
              }],  
            }  
          }  
        }); 
      }
    );
  }
  
  getThongKeMSDTopThang() {
    this.DashboardService.ThongKeMucSuDungTopThang(this.Token).subscribe(
      (res) => {
        this.dataThongKeMSDTopThang = res.DataMDSD;
        this.Linechart2 = new Chart('canvas2', {  
          type: 'bar',  
          data: {  
            labels: this.dataThongKeMSDTopThang.map(x => x.CustomerCode),               
            datasets: [  
              {  
                data: this.dataThongKeMSDTopThang.map(x => x.So_lan_su_dung),  
                borderColor: '#3cb371',  
                backgroundColor: "#17a2b8",  
              }  
            ]  
          },  
          options: {  
            legend: {  
              display: false  
            },  
            scales: {  
              xAxes: [{  
                display: true  
              }],  
              yAxes: [{  
                display: true  
              }],  
            }  
          }  
        }); 
      }
    );
  }
  getDanhSachQuaHanCNPB() {
    this.DashboardService.DanhSachQuaHanCapNhatPhienBan(this.Token).subscribe(
      (res) => {
        this.dataDanhSachQuaHanCNPB = res.DataQHCN;
      }
    )
  }
}
