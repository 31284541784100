import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { Subject } from 'rxjs';
import { BaseComponent, removeSpaces, formatDate} from 'src/app/base/base.component';

@Component({
  selector: 'app-log-system',
  templateUrl: './log-system.component.html',
  styleUrls: ['./log-system.component.scss']
})
export class LogSystemComponent extends BaseComponent implements OnInit {

  dataTable: any = [];

  searchForm = new FormGroup({
    So_ban_ghi: new FormControl(10),
    Event: new FormControl(''),
    Perform: new FormControl(''),
    FromDate: new FormControl(''),
    ToDate: new FormControl(''),
    UserName: new FormControl(''),
  })

  ngOnInit(): void {
    this.getToken();
    this.getList();  
  }

  getList() {
    this.spinner.show();
    let req = {
      So_ban_ghi: this.searchForm.value.So_ban_ghi,
      Trang: this.page,
      Event: this.searchForm.value.Event,
      Perform: this.searchForm.value.Perform,
      FromDate: this.searchForm.value.FromDate,
      ToDate: this.searchForm.value.ToDate,
      UserName: this.searchForm.value.UserName,
    }
    this.AdminLogService.Load_List(req, this.Token).subscribe(
      (res) => {
        this.dataTable = res.Data;
        this.totalRecord = res.Tong_so_ban_ghi;
        this.spinner.hide();
      }
    );
  }

  lastPage() {
    this.getLastPage(this.searchForm.value.So_ban_ghi);
    this.getList();
  }

  filterData() {
    this.getList();
  }
  
  nextPage() {
    this.getNextPage(this.dataTable);
    this.getList();
  }
  
  previewPage() {
    this.getPreviewPage();
    this.getList();
  }

  reload() {
    this.pageReload();
    this.getList();
  }

  refreshPage() {
    this.page = 1;
    this.getList();
  }

  search() {
    this.refreshPage();
  }
}
