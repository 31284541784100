import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
import { User } from '../../Models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) { }

  GetUserList(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'UserManager/GetUserList', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  Delete(ID_user: number,token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'UserManager/Delete_User?ID_user='+ID_user,{
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  Update(req: any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'UserManager/Update_User',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  Insert(user: any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'UserManager/Insert_User',user,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }
  
  GetFunction_UserID(ID_user: number, token) {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Function/Get_Function_UserID?ID_user='+ID_user,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  Customer_UserID(UserID: number, token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Customer/Customer_UserID?UserID='+UserID,{
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }

  Get_Function_UserID(UserID: number, token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Function/Get_Function_UserID?ID_user='+UserID, {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }

  Insert_User_Function(req: any, token) {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'UserManager/Insert_User_Function',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  Insert_User_Customer(req: any, token) {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'UserManager/Insert_User_Customer',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  Excel(req: any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Excel/User_Excel',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  UpdateInfo(req: any, token) {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'UserManager/UpdateInfo',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }
}
