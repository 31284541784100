<!----------------------------------------------- HEADER ------------------------------------------------->
<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">Thống kê giao dịch thanh toán học phí Online</li>
                </ol>
            </div>
            <div class="col-md-6">
                <button class="btn btn-info btn-sm" (click)="search()" style="float: right;"><i class="fas fa-chart-bar"></i>&nbsp;Thống kê
                </button>
            </div>
        </div>
    </div>
</section>

<!----------------------------------------------- FILTER ------------------------------------------------->
<section class="content cus-body ">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="searchForm">
              <div class="row">
                <!-- <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;">Loại khách hàng</label>
                  <ng-select formControlName="TypeID" notFoundText="Không có kết quả">
                      <ng-option [value]="0">Tất cả</ng-option>
                      <ng-option *ngFor="let type of dataType" [value]="type.TypeID">{{type.TypeName}}</ng-option>
                  </ng-select>
                </div> -->
                <div class="col-md-3">
                    <label class="col-md-10" style="margin-top: 10px;">Phạm vi báo cáo</label>
                    <ng-select formControlName="Limit" notFoundText="Không có kết quả" (change)="statisticByDate()">
                        <ng-option [value]="0">Tùy chỉnh</ng-option>
                        <ng-option *ngFor="let p of selectDate" [value]="p.value">{{p.name}}</ng-option>
                    </ng-select>
                  </div>
                <div class="col-md-3">
                  <label class="col-md-10 " style="margin-top: 10px;">Từ ngày</label>
                  <input class="form-control" type="date" formControlName="Tu_ngay"/>
                </div>
                <div class="col-md-3">
                    <label class="col-md-10 " style="margin-top: 10px;">Đến ngày</label>
                    <input class="form-control" type="date" formControlName="Den_ngay"/>
                </div>
                <div class="col-md-3">
                    <label class="col-md-10 " style="margin-top: 10px;">Trạng thái</label>
                    <select class="form-control" formControlName="Trang_thai">
                        <option [ngValue]="null">Tất cả</option>
                        <option [ngValue]="1">Thành công</option>
                        <option [ngValue]="2">Thất bại</option>
                    </select>
                </div>
              </div>
        </form>
        </div>
    </div>
</section>
<section class="content cus-body ">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                  <p style="font-size: 18px;text-align: center;padding: 10px;font-weight: bold;">Thống kê giao dịch thanh toán</p>
                  <canvas id="canvas" style="height: 80% !important;">{{ Linechart }}</canvas>    
                </div>
                <div class="col-md-12">
                    <div style="height: 27vh;overflow-y: auto;border: solid 1px #dee2e6;">
                        <table class="table table-bordered table-hover" style="width: 100%;">
                            <thead style="text-align: center;">
                              <tr>
                                <th>STT</th>
                                <th>Ngày giao dịch</th>
                                <th>Số giao dịch</th>
                                <th>Tổng số tiền</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let p of dataStatistic | filter: searchString; let i=index">
                                <td style="text-align: center;">{{i+1}}</td>
                                <td style="white-space: nowrap;text-align: center;">{{p.Ngay_thang}}</td>
                                <td style="white-space: nowrap;text-align: center;">{{p.So_giao_dich}}</td>
                                <td style="text-align: center;">{{p.Tong_so_tien | number}}</td>
                              </tr>
                            </tbody>
                            <tr style="text-align: center;">
                                <td colspan="2" style="font-weight: bold;">Tổng số</td>
                                <td colspan="1" style="font-weight: bold;">{{sum_transaction | number}}</td>
                                <td colspan="1" style="font-weight: bold;">{{sum_money | number}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
