<aside
  #mainSidebar
  class="main-sidebar sidebar-light-primary elevation-4 cus-text"
  style="overflow: visible !important; position: fixed;"
>
  <!-- Brand Logo -->
  <a [routerLink]="['/']" class="brand-link">
    <img src="assets/img/unilogo.png"  class="brand-image" />
    <span class="brand-text font-weight-light">UniSoft-GOV</span>
  </a>
  <div class="sidebar">
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column"  data-widget="treeview" role="menu" data-accordion="true">
        <!--------------------------------------------------- MENU 1 (CUSTOMER)-------------------------------------------->
        <li id="menu1" class="nav-item menu-open" *ngIf="
        QUAN_LY_KHACH_HANG || QL_danh_sach_khach_hang || QL_doi_tac_tich_hop_tt || QL_muc_do_su_dung_kh || QL_muc_do_su_dung_cn || QL_tra_cuu_muc_do_su_dung || QL_thong_ke_giao_dich_tt
        ">
          <a class="nav-link cus-bg" (click)="menu('menu1')" style="background: #418994; color: white;" >
          <i class="fas fa-chalkboard-teacher"></i><p>&nbsp;Quản lí khách hàng <i class="right fa fa-angle-left"></i> </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" *ngIf="QL_danh_sach_khach_hang">
              <a  [routerLink]="['/customer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
              (click)="setTitle('Danh sách khách hàng')">
                <i class="fas fa-school"></i> <p>&nbsp;Danh sách khách hàng</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="QL_doi_tac_tich_hop_tt">
              <a  [routerLink]="['/payment-partner']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
              (click)="setTitle('Đối tác & ví điện tử')">
                <i class="fas fa-wallet"></i><p>&nbsp;Đối tác tích hợp TT</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="QL_muc_do_su_dung_kh">
              <a  [routerLink]="['/level-customer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
              (click)="setTitle('Mức độ sử dụng khách hàng')">
                <i class="fas fa-battery-half"></i> <p>&nbsp;Mức độ sử dụng KH</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="QL_muc_do_su_dung_cn">
              <a  [routerLink]="['/level-function']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
              (click)="setTitle('Mức độ sử dụng chức năng')">
              <i class="fas fa-signal"></i> <p>&nbsp;Mức độ sử dụng CN</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="QL_tra_cuu_muc_do_su_dung">
              <a  [routerLink]="['/detail-level']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
              (click)="setTitle('Tra cứu mức độ sử dụng')">
                <i class="fas fa-eye"></i> <p>&nbsp;Tra cứu mức độ sử dụng</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="QL_thong_ke_giao_dich_tt">
              <a  [routerLink]="['/transaction']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
              (click)="setTitle('Tra cứu mức độ sử dụng')">
                <i class="fas fa-handshake"></i> <p>&nbsp;Thống kê giao dịch TT</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="QL_thong_ke_giao_dich_tt">
              <a  [routerLink]="['/search-transaction']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
              (click)="setTitle('Tra cứu mức độ sử dụng')">
                <i class="fas fa-search-dollar"></i> <p>&nbsp;Tra cứu giao dịch TT</p>
              </a>
            </li>
          </ul>
        </li>
        <!--------------------------------------------------- MENU 2 (VERSION)-------------------------------------------->
        <li id="menu2" class="nav-item menu-open" *ngIf="
        QUAN_LY_TRIEN_KHAI_DU_AN || QL_trien_khai_khach_hang_moi || QL_cap_nhat_phien_ban || QL_don_vi_chua_cap_nhat
        ">
          <a class="nav-link cus-bg" (click)="menu('menu2')" style="background: #418994; color: white;" >
          <i class="fas fa-upload"></i><p>&nbsp;QL triển khai dự án <i class="right fa fa-angle-left"></i> </p>
          </a>
          <ul class="nav nav-treeview" *ngIf="QL_trien_khai_khach_hang_moi">
              <li class="nav-item">
                <a  [routerLink]="['/new-customer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                (click)="setTitle('Triển khai KH mới')">
                  <i class="fas fa-scroll"></i> <p>Triển khai KH mới</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="QL_cap_nhat_phien_ban">
                <a  [routerLink]="['/update-version']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                (click)="setTitle('Quản lý cập nhật')">
                  <i class="fas fa-wrench"></i> <p>&nbsp;Cập nhật phiên bản mới</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="QL_don_vi_chua_cap_nhat">
                <a  [routerLink]="['/out-of-date-customer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                (click)="setTitle('Quản lý cập nhật')">
                  <i class="fas fa-user-times"></i> <p>&nbsp;Đơn vị quá hạn cập nhật</p>
                </a>
              </li>
          </ul>
        </li>
        <!--------------------------------------------------- MENU 3 (ADMIN)-------------------------------------------->
        <li id="menu3" class="nav-item menu-open" *ngIf="
        QUAN_TRI_HE_THONG || QL_danh_sach_nguoi_dung || QL_tra_cuu_log || QL_lich_su_restore || QL_lich_su_cap_nhat
        ">
          <a class="nav-link cus-bg" (click)="menu('menu3')" style="background: #418994; color: white;" >
          <i class="fas fa-cog"></i><p>&nbsp;Quản trị hệ thống <i class="right fa fa-angle-left"></i> </p>
          </a>
          <ul class="nav nav-treeview">
              <li class="nav-item" *ngIf="QL_danh_sach_nguoi_dung">
                <a  [routerLink]="['/user-manager']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                (click)="setTitle('Quản lý người dùng')">
                  <i class="fas fa-users"></i> <p>&nbsp;Danh sách người dùng</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="QL_tra_cuu_log">
                <a  [routerLink]="['/log-system']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                (click)="setTitle('Tra cứu log')">
                  <i class="fas fa-file-medical-alt"></i> <p>&nbsp;Tra cứu log</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="QL_lich_su_restore">
                <a  [routerLink]="['/history-restore']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                (click)="setTitle('Lịch sử Restore')">
                  <i class="fas fa-tools"></i> <p>&nbsp;Lịch sử Restore</p>
                </a>
              </li>
              <li class="nav-item" *ngIf="QL_lich_su_cap_nhat">
                <a  [routerLink]="['/history-update-version']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                (click)="setTitle('Lịch sử cập nhật')">
                  <i class="fas fa-edit"></i> <p>&nbsp;Lịch sử cập nhật</p>
                </a>
              </li>
          </ul>
        </li>
        <!--------------------------------------------------- MENU 4 (CATEGORIES) -------------------------------------------->
        <li id="menu4" class="nav-item menu-open" *ngIf="
        DANH_MUC || QL_phien_ban || QL_phan_he || QL_loai_khach_hang || QL_danh_sach_quyen
        ">
          <a class="nav-link cus-bg" (click)="menu('menu4')" style="background: #418994; color: white;" >
          <i class="fas fa-file-medical"></i><p>&nbsp;Danh mục <i class="right fa fa-angle-left"></i> </p>
          </a>
          <ul class="nav nav-treeview" *ngIf="QL_phien_ban">
            <li class="nav-item">
              <a  [routerLink]="['/version']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
              (click)="setTitle('Phiên bản')">
                <i class="fas fa-scroll"></i> <p>&nbsp;Phiên bản</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="QL_phan_he">
                <a  [routerLink]="['/module']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                (click)="setTitle('Quản lý phân hệ')">
                  <i class="fas fa-address-book"></i> <p>&nbsp;Phân hệ</p>
                </a>
            </li>
            <li class="nav-item" *ngIf="QL_loai_khach_hang">
              <a  [routerLink]="['/customer-type']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
              (click)="setTitle('Loại khách hàng')">
                <i class="fas fa-building"></i><p>&nbsp;Loại khách hàng</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="QL_danh_sach_quyen">
              <a  [routerLink]="['/function']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
              (click)="setTitle('Quản lý quyền')">
                <i class="fas fa-dice-d6"></i> <p>&nbsp;Danh sách quyền</p>
              </a>
            </li>
            <!-- <li class="nav-item">
              <a  [routerLink]="['/user-function']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
              (click)="setTitle('Quyền cá nhân')">
                <i class="fas fa-user-tag"></i> <p>&nbsp;Quyền cá nhân</p>
              </a>
            </li> -->
          </ul>
        </li> 
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
</aside>
