<!----------------------------------------------- HEADER ------------------------------------------------->
<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">Cấp quyền đối tác Ngân hàng và Ví điện tử</li>
                </ol>
            </div>
            <div class="col-md-6">
                <button class="btn btn-outline-success btn-sm" (click)="open(AddModal,'lg','Add','')" style="float: right;"><i
                        class="fas fa-plus-circle"></i> Thêm mới
                </button>
            </div>
        </div>
    </div>
  </section>
  
<!----------------------------------------------- FILTER ------------------------------------------------->
<section class="content cus-body ">
  <div class="card">
      <div class="card-body">
          <form [formGroup]="searchForm">
            <div class="row">
              <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;">Mã khách hàng</label>
                  <input class="form-control" formControlName="Ma_truong" (keyup)="filterById()" type="text" placeholder="Nhập mã khách hàng...">
              </div>
              <div class="col-md-3">
                <label class="col-md-10" style="margin-top: 10px;">Mã đối tác</label>
                <input class="form-control" formControlName="Ma_doi_tac" (keyup)="filterById()" type="text" placeholder="Nhập mã đối tác...">
              </div>
              <div class="col-md-6">
                <label class="col-md-10" style="margin-top: 10px;">Tên đối tác</label>
                <input class="form-control" formControlName="Ten_doi_tac" (keyup)="filterById()" type="text" placeholder="Nhập tên đối tác...">
              </div>
            </div>
      </form>
      </div>
  </div>
</section>
  <!----------------------------------------------- TABLE ------------------------------------------------->
  <section class="content cus-body">
    <div class="card">
      <div class="card-body">
        <div class="tab-content">
          <div class="active tab-pane" id="TeachInfo">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <div class="container">
                  </div>
                  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                  class="table-bordered dataTables_wrapper no-footer" style="width: 100%;overflow-x: auto;">
                     <thead style="text-align: center;">
                        <tr style="white-space: nowrap;">
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                     </thead>
                     <tbody id = "myTable">
                         <tr *ngFor="let data of dataTable; let i=index" >
                             <td style="white-space: nowrap;">{{data.Ma_truong}}</td>
                             <td style="white-space: nowrap;">{{data.CustomerName}}</td>
                             <td style="white-space: nowrap;"><a href="{{data.URL_doi_tac}}">{{data.URL_doi_tac}}</a></td>
                             <td style="white-space: nowrap;">{{data.Ma_doi_tac}}</td>
                             <td style="white-space: nowrap;">{{data.Ten_doi_tac}}</td>
                             <td style="white-space: nowrap;"><a href="{{data.URL_truong}}">{{data.URL_truong}}</a></td>
                             <td style="white-space: nowrap;">{{data.Ma_dai_ly}}</td>
                             <td style="white-space: nowrap;">{{data.Ma_dich_vu}}</td>
                             <td style="white-space: nowrap;">
                              <button class="btn btn-outline-info btn-sm"
                              (click)="open(AddModal, 'lg', 'Update', data)" style="margin-right: 5px;"> 
                              <i class="fas fa-edit"></i> 
                              </button>  
                              <button class="btn btn-outline-danger btn-sm"
                              (click)="open(DeleteModal, 'xs', 'Delete', data)"> 
                              <i class="fas fa-trash-alt"></i> 
                              </button>
                             </td>
                         </tr>
                     </tbody>
                 </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <!-----------------------------------------------ADD MODAL ------------------------------------------------->
  <ng-template #AddModal let-modal>
    <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
      <h4 class="modal-title h-title w-100"> {{titleModal}}</h4>
      <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
    </div>
    <div class="modal-body">
      <form class="form-horizontal" [formGroup]="AddForm">       
        <div class="row">
          <div class="col-md-6">
            <label class="col-md-10 require">Mã trường</label>
              <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && checkvalue.Ma_truong.errors }"
              formControlName="Ma_truong" placeholder="Nhập mã...">
              <div *ngIf="submitted && checkvalue.Ma_truong.errors" class="invalid-feedback">
                <div *ngIf="checkvalue.Ma_truong.errors.required">
                  Mã khách hàng không được bỏ trống!
                </div>
                <div *ngIf="checkvalue.Ma_truong.errors.maxlength">
                  Mã khách hàng tối đa 20 ký tự!
                </div>
              </div>
          </div>
          <div class="col-md-6">
            <label class="col-md-10 require">Mã đối tác</label>
              <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && checkvalue.Ma_doi_tac.errors }"
               formControlName="Ma_doi_tac" placeholder="Nhập mã...">
               <div *ngIf="submitted && checkvalue.Ma_doi_tac.errors" class="invalid-feedback">
                <div *ngIf="checkvalue.Ma_doi_tac.errors.required">
                  Mã đối tác không được bỏ trống!
                </div>
                <div *ngIf="checkvalue.Ma_doi_tac.errors.maxlength">
                  Mã đối tác tối đa 20 ký tự!
                </div>
              </div>
          </div>
          <div class="col-md-12">
            <label class="col-md-10 require">Tên đối tác</label>
              <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && checkvalue.Ten_doi_tac.errors }"
               formControlName="Ten_doi_tac" placeholder="Nhập tên...">
               <div *ngIf="submitted && checkvalue.Ten_doi_tac.errors" class="invalid-feedback">
                <div *ngIf="checkvalue.Ten_doi_tac.errors.required">
                  Tên đối tác không được bỏ trống!
                </div>
                <div *ngIf="checkvalue.Ten_doi_tac.errors.maxlength">
                  Tên đói tác tối đa 200 ký tự!
                </div>
              </div>
          </div>
          <div class="col-md-6">
            <label class="col-md-10 ">URL cổng sinh viên</label>
              <input type="text" class="form-control" 
               formControlName="URL_truong" placeholder="Nhập URL...">
          </div>
          <div class="col-md-6">
            <label class="col-md-10 ">URL đối tác</label>
              <input type="text" class="form-control" 
               formControlName="URL_doi_tac" placeholder="Nhập đường dẫn...">
           
          </div>
          <div class="col-md-6">
            <label class="col-md-10 ">Mã đại lý</label>
              <input type="text" class="form-control" 
               formControlName="Ma_dai_ly" placeholder="Nhập mã...">
          </div>    
          <div class="col-md-6">
            <label class="col-md-10 ">Mã dịch vụ</label>
              <input type="text" class="form-control" 
               formControlName="Ma_dich_vu" placeholder="Nhập tên quyền...">
          </div>     
          <div class="col-md-12">
            <label class="col-md-10">Tên đại lý</label>
              <input type="text" class="form-control" formControlName="Ten_dai_ly" placeholder="Nhập tên...">
          </div>
        
          <div class="col-md-6">
            <label class="col-md-10 " >Mã bảo mật đối tác</label>
              <input type="text" class="form-control" 
               formControlName="Ma_bao_mat_doi_tac" placeholder="Nhập mã...">
          </div>   
          <div class="col-md-6">
            <label class="col-md-10 require" style="color: red;">Mã bảo mật Thiên An</label>
              <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && checkvalue.Ma_bao_mat.errors }"
               formControlName="Ma_bao_mat" placeholder="Nhập mã...">
               <div *ngIf="submitted && checkvalue.Ma_bao_mat.errors" class="invalid-feedback">
                <div *ngIf="checkvalue.Ma_bao_mat.errors.required">
                  Mã bảo mật không được bỏ trống!
                </div>
                <div *ngIf="checkvalue.Ma_bao_mat.errors.maxlength">
                  Mã bảo mật tối đa 200 ký tự!
                </div>
              </div>
          </div>  
          <!-- <div class="col-md-6">
            <label class="col-md-10">Số điện thoại</label>
              <input type="tel" class="form-control" formControlName="So_dien_thoai" placeholder="Nhập SĐT...">
          </div> -->
          <!-- <div class="col-md-6">
            <label class="col-md-10">Địa chỉ</label>
              <input type="text" class="form-control" formControlName="Dia_chi" placeholder="Nhập địa chỉ...">
          </div>
          <div class="col-md-6">
            <label class="col-md-10">Mã số thuế</label>
              <input type="text" class="form-control" formControlName="Ma_so_thue" placeholder="Nhập mã...">
          </div> 
          <div class="col-md-6">
            <label class="col-md-10">Người liên hệ</label>
              <input type="text" class="form-control" formControlName="Nguoi_lien_he" placeholder="Nhập tên...">
          </div>
          <div class="col-md-6">
            <label class="col-md-10 ">SĐT người liên hệ</label>
              <input type="text" class="form-control" formControlName="So_dien_thoai_nguoi_lien_he" placeholder="Nhập SĐT...">
          </div>          -->
          <!-- <div class="col-md-12">
            <label class="col-md-10">Địa chỉ người liên hệ</label>
              <input type="text" class="form-control" formControlName="Dia_chi_nguoi_lien_he" placeholder="Nhập địa chỉ...">
          </div>   -->
          <!-- <div class="col-md-12">
            <label>Nội dung hợp tác</label>
            <textarea rows="3" class="form-control" formControlName="Noi_dung_hop_tac" class="form-control"></textarea>
          </div> -->
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="float-right">
        <button type="button" class="btn btn-outline-success btn-sm" (click)="onSubmit()">
          <i class="fas fa-save"></i> Lưu
        </button>
        <button type="button" class="btn btn-outline-danger btn-sm" (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;">
          <i class="far fa-times-circle"></i> Đóng
        </button>
      </div>
    </div>
  </ng-template>
  
<!-----------------------------------------------DELETE MODAL ------------------------------------------------->
<ng-template #DeleteModal let-modal>
  <app-delete-modal (delete)="deleteItem()" (close)="dissmissModal()"></app-delete-modal>
</ng-template>

  
  