<div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
  <h4 class="modal-title h-title w-100"> {{titleModalInput}}</h4>
  <span (click)="cancel()" aria-hidden="true" style="font-size: 20px;">&times;</span>
</div>
<div class="modal-body" style="overflow-y: auto; max-height: 60vh;">
<!----------------------------------------------- FILTER ------------------------------------------------->
<!-- <section class="content cus-body "  [@openClose]="isDisplay ? 'open' : 'closed'" style="margin-bottom: 10px;">
  <div class="col-md-12">
    <div class="col-md-12">
      <form [formGroup]="searchForm">
        <div class="row">
          <div class="col-md-6">
            <label class="col-md-10" style="margin-top: 10px;">Phân hệ</label>
            <input type="text" class="form-control" placeholder="Nhập tên phân hệ...">
          </div>
          <div class="col-md-6">
            <label class="col-md-10" style="margin-top: 10px;">Kết quả</label>
            <ng-select notFoundText="Không có kết quả">
                <ng-option value="">Tất cả</ng-option>
                <ng-option value="Pass">Pass</ng-option>
                <ng-option value="Fail">Fail</ng-option>
            </ng-select>
          </div>
        </div>
      </form>
    </div>
  </div>
</section> -->
<!----------------------------------------------- TABLE ------------------------------------------------->
<section class="content cus-body">
  <div class="col-md-12">
    <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
        <div class="dol-md-12" style="margin-bottom: 10px;">
            <form [formGroup]="searchForm">
                <div class="row">
                    <div class="col-md-2">
                        <select class="form-control-sm"  formControlName="RowsOfPage" style="height: 25px;" (change)="filterData()">
                            <option [ngValue]="100000000">Tất cả</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <div class="col-md-10">
                        <!-- <button type="button" class="btn btn-sm btn-outline-secondary" (click)="clickFilter()"
                            ngbTooltip="Ẩn/hiện bộ lọc" placement="left"
                            style="float: right; margin: 0 5px;">
                            <i class="fas fa-filter"></i>
                        </button> -->
                        <!-- <button type="button" class="btn btn-sm btn-outline-secondary" placement="left" (click)="reload()"
                            ngbTooltip="Tải lại" style="float: right; margin-bottom: 5px;">
                            <i class="fas fa-redo-alt"></i>
                        </button> -->
                    </div>
                </div>
            </form>
        </div>
        <div [style]='styleBinding'>
          <table class="table table-bordered">
            <thead style="text-align: center;">
              <tr>
                  <th>STT</th>
                  <th>Tên phân hệ</th>
                  <th style="white-space: nowrap">Kết quả</th>
                  <th>Nội dung cập nhật</th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let p of dataStatisticUpdate; let i=index" >
                    <td style="text-align: center;">{{p.RowCount}}</td>
                    <td style="white-space: nowrap">{{p.ModuleName}}</td>
                    <td *ngIf="p.UpdateNote.includes('F')" style="text-align: center; color: red;font-weight: bold;">{{p.UpdateNote}}</td>
                    <td *ngIf="!p.UpdateNote.includes('F')" style="text-align: center; color: #28a745;font-weight: bold;">{{p.UpdateNote}}</td>
                    <td style="word-wrap: break-word;max-width: 200px;">{{p.UpdateError}}</td>
                </tr>
            </tbody>
            <tr *ngIf="!(dataStatisticUpdate.length > 0)" style="text-align: center;font-weight: bold;">
              <td colspan="4">Không có dữ liệu !</td>
            </tr>                    
          </table>
        </div>

          <div class="col-md-12">
            <nav aria-label="..." style="float: right;">
                <ul class="pagination" style="font-size: 13px !important;">
                  <li class="page-item">
                    <a class="page-link" tabindex="-1" [routerLink]="" (click)="refreshPage()">Đầu</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" tabindex="-1" [routerLink]="" (click)="previewPage()">Trước</a>
                  </li>
                  <!-- <li class="page-item"><a class="page-link" href="#">1</a></li>
                  <li class="page-item active">
                    <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                  </li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li> -->
                  <li class="page-item active">
                    <a class="page-link" [routerLink]="">{{page}}<span class="sr-only"></span></a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" [routerLink]="" (click)="nextPage()">Tiếp</a>
                  </li>
                </ul>
            </nav>
        </div>
      </div>
  </div>
</section>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-outline-danger btn-sm" (click)="cancel()"
        style="margin-left: 1em;">
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
</div>