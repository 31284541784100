<!----------------------------------------------- HEADER ------------------------------------------------->
<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">Quản lí Version</li>
                </ol>
            </div>
            <div class="col-md-6">
                <button class="btn btn-outline-success btn-sm" (click)="open(AddModal,'ls','Add','')" style="float: right;"><i
                        class="fas fa-plus-circle"></i> Thêm mới
                </button>
            </div>
        </div>
    </div>
  </section>

<!----------------------------------------------- TABLE ------------------------------------------------->
<section class="content cus-body">
  <div class="card">
      <div class="card-body">
          <div class="tab-content">
              <div class="active tab-pane">
                  <div class="row">
                      <div class="col-md-12">
                          <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                              <table style="width:100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                  class="table-bordered dataTables_wrapper no-footer">
                              <thead>
                                  <tr>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let version of dataTable; let i=index" >
                                      <td>{{i+1}}</td>
                                      <td>{{version.VersionCode}}</td>
                                      <td>{{version.VersionName}}</td>
                                      <td>
                                        <button class="btn btn-outline-info btn-sm"
                                        (click)="open(AddModal, 'xs', 'Update', version)"
                                        style="margin-right: 5px;">
                                        <i class="fas fa-edit"></i>
                                        </button>
                                        <button class="btn btn-outline-danger btn-sm"
                                        (click)="open(DeleteModal, 'xs', 'Delete', version)">
                                        <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </tr>
                                </tbody>
                              </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

  <!-----------------------------------------------ADD MODAL ------------------------------------------------->
  <ng-template #AddModal let-modal>
    <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
      <h4 class="modal-title h-title w-100"> {{titleModal}}</h4>
      <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
    </div>
    <div class="modal-body">
      <form class="form-horizontal" [formGroup]="Insert">
        <div class="row">
          <div class="col-md-12">
            <label class="col-md-10 require">Version Code</label>
              <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && checkvalueInsert.VersionCode.errors }"
               formControlName="VersionCode" placeholder="Nhập mã version...">
               <div *ngIf="submitted && checkvalueInsert.VersionCode.errors" class="invalid-feedback">
                  <div *ngIf="checkvalueInsert.VersionCode.errors.required">
                      Mã version không được bỏ trống!
                  </div>
                  <div *ngIf="checkvalueInsert.VersionCode.errors.maxlength">
                    Mã version tối đa 50 ký tự!
                  </div>
               </div>
          </div>
          <div class="col-md-12">
            <label class="col-md-10 require">Version Name</label>
              <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && checkvalueInsert.VersionName.errors }"
              formControlName="VersionName" placeholder="Nhập tên version...">
              <div *ngIf="submitted && checkvalueInsert.VersionName.errors" class="invalid-feedback">
                <div *ngIf="checkvalueInsert.VersionName.errors.required">
                    Tên version không được bỏ trống!
                </div>
                <div *ngIf="checkvalueInsert.VersionName.errors.maxlength">
                  Mã version tối đa 200 ký tự!
                </div>
             </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="float-right">
        <button type="button" class="btn btn-outline-success btn-sm" (click)="onSubmit()">
          <i class="fas fa-save"></i> Lưu
        </button>
        <button type="button" class="btn btn-outline-danger btn-sm" (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;">
          <i class="far fa-times-circle"></i> Đóng
        </button>
      </div>
    </div>
  </ng-template>

<!-----------------------------------------------DELETE MODAL ------------------------------------------------->
<ng-template #DeleteModal let-modal>
  <app-delete-modal (delete)="deleteItem()" (close)="dissmissModal()"></app-delete-modal>
</ng-template>
