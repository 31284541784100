import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { BaseComponent, removeSpaces } from 'src/app/base/base.component';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';

@Component({
  selector: 'app-step3-modal',
  templateUrl: './step3-modal.component.html',
  styleUrls: ['./step3-modal.component.scss']
})
export class Step3ModalComponent extends BaseComponent implements OnInit {

  @Output() submit: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  @Input() titleModalInput: any;
  @Input() customerCodeInput: any;

  searchForm = new FormGroup({
    RowsOfPage: new FormControl(10),
  })

  ngOnInit(): void {
    this.getToken();
    this.getList();
  }

  getList() {
    this.spinner.show();
    this.DeploymentService.StatisticUpdateVersion(this.customerCodeInput,this.page,this.searchForm.value.RowsOfPage,this.Token).subscribe(
      (res) => {
        this.dataStatisticUpdate = res.Data;
        if (this.dataStatisticUpdate.length > 0) {
          this.styleBinding = "height: 50vh; overflow-y: auto"
        }
        else {
          this.styleBinding = "";
        }
        this.spinner.hide();
      }
    );
  }

  cancel(){
    this.close.emit()
  }

  filterData() {
    this.getList();
  }

  nextPage() {
    this.getNextPage(this.dataStatisticUpdate);
    this.getList();
  }
  
  previewPage() {
    this.getPreviewPage();
    this.getList();
  }

  reload() {
    this.pageReload();
    this.getList();
  }

  refreshPage() {
    this.page = 1;
    this.getList();
  }
}
