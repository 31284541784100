import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
import { Function } from './../../Models/function';


@Injectable({
  providedIn: 'root'
})
export class FunctionService {

  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) { }

  Load_List(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Function/GetFunctionList', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  Delete(ID_function: number, token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Function/Delete_Function?ID_function='+ID_function,{
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  Update(req: any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Function/Update_Function',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }
  Insert(_function: any, token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Function/Insert_Function',_function,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }
  Get_Function_UserID(UserID: number, token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Function/Get_Function_UserID?ID_user='+UserID, {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
}
