import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { BaseComponent, removeSpaces, formatDate} from 'src/app/base/base.component';

@Component({
  selector: 'app-new-customer',
  templateUrl: './new-customer.component.html',
  styleUrls: ['./new-customer.component.scss']
})
export class NewCustomerComponent extends BaseComponent implements OnInit {

  dtOptions: DataTables.Settings;
  dataTable: any = []
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective
  dtTrigger = new Subject();

  AddForm = new FormGroup({
    DatabaseNameCreate: new FormControl('', [Validators.required,Validators.maxLength(200),removeSpaces]),
    DatabaseNameBackup: new FormControl('', [Validators.required]),
    ResponseMessage: new FormControl(''),
  })

  searchForm = new FormGroup({
    CustomerCode: new FormControl(''),
    CustomerName: new FormControl(''),
    Url_update: new FormControl(''),
    DatabaseName: new FormControl(''),
    ServerName: new FormControl(''),
    TypeName: new FormControl(''),
  });

  ngOnInit(): void {
    this.getToken();
    /**-------------------------USE dtOptions to format table-------------------------*/
    // Filter DATA TABLE
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: true,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        },
      },
      columns: [
        {
          title: 'STT',
          className: "dt-center",
          visible: false
        },
        {
          title: 'Mã khách hàng',
          className: "dt-center",
        },
        {
          title: 'Tên khách hàng',
        },
        {
          title: 'Loại khách hàng',
          visible: false
        },
        {
          title: 'URL',
        },
        {
          title: 'Database',

        },
        {
          title: 'Server',
        },
        {
          title: 'Bước 1: </br> Khởi tạo CSDL NB',
          className: "dt-center",
          width: '75px'
        },
        {
          title: 'Bước 2: </br> Chuyển dữ liệu NB',
          className: "dt-center",
          width: '75px'
        },
        {
          title: 'Bước 3: </br> Cài đặt KH',
          className: "dt-center",
          width: '75px'
        },
        {
          title: 'Bước 4: </br> Đào tạo KH',
          className: "dt-center",
          width: '75px'
        },
        {
          title: 'Bước 5: </br> Quy trình',
          className: "dt-center",
          width: '75px'
        },
      ],
    };
    this.GetAll();
  }


  GetAll() {
    this.spinner.show();
    this.CustomerService.Customer_UserID(this.UserID_get,this.Token)
    .subscribe(z =>{
      this.dataTable = z.listCustomers;
      this.dtTrigger.next();

      this.dataCustomerCodeFilter = [...new Set(this.dataTable.map(x => x.CustomerCode))];
      this.dataCustomerCodeFilter = this.dataCustomerCodeFilter.sort((a, b) => a.localeCompare(b));

      this.dataDBFilter = [...new Set(this.dataTable.map(x => x.DatabaseName))];
      this.dataDBFilter = this.dataDBFilter.sort((a, b) => a.localeCompare(b));

      this.dataServerFilter = [...new Set(this.dataTable.map(x => x.ServerName))];
      this.dataServerFilter = this.dataServerFilter.sort((a, b) => a.localeCompare(b));

      this.dataTypeNameFilter = [...new Set(this.dataTable.map(x => x.TypeName))];
      this.dataTypeNameFilter = this.dataTypeNameFilter.sort((a, b) => a.localeCompare(b));

      this.CustomerTypeService.Load_List(this.Token).subscribe(
        (res) => {
          this.dataCustomerType = res.Data;
          this.spinner.hide();
        }
      );
    })
  }

  
  step1Status: number;
  step1Message: string;

  step2Status: number;
  step2Message: string;

  step3Status: number;
  step3Message: string;

  step4Status: number;
  step4Message: string;

  step5Status: number;
  step5Message: string;

  getMessageWorking() {
    this.step1Status = 0;
    this.step2Status = 0;
    this.step3Status = 0;
    this.step4Status = 0;
    this.step5Status = 0;

    this.step1Message = "Đang thực thi ...";
    this.step2Message = "Đang thực thi ...";
    this.step3Message = "Đang thực thi ...";
    this.step4Message = "Đang thực thi ...";
    this.step5Message = "Đang thực thi ...";
  }

  getDataResponse() {
    this.dataResponse = [
      {"step": 1, "result": this.step1Message, "status": this.step1Status},
      {"step": 2, "result": this.step2Message, "status": this.step2Status},
      {"step": 3, "result": this.step3Message, "status": this.step3Status},
      {"step": 4, "result": this.step4Message, "status": this.step4Status},
      {"step": 5, "result": this.step5Message, "status": this.step5Status},
    ];
  }

  open(content, sizm, type, Data) {
    this.selected_ID = Data.FunctionID;
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    switch (type) {
      case "Step1" :
        this.titleModal = "BƯỚC 01: KHỞI TẠO CSDL KHÁCH HÀNG MỚI NỘI BỘ";
        this.AddForm.reset();
        this.AddForm.patchValue({
          DatabaseNameBackup: ''
        });
        this.dataResponse = '';
        this.dataCustomerFilter = [];
        break;
      case "Step2" :
        this.titleModal = "BƯỚC 02: KIỂM TRA CHUYỂN ĐỔI DỮ LIỆU KHÁCH HÀNG";
        this.URL = Data.Url_update;
        var path = this.URL.split('UniSoft_API')[0];
        this.URL_mark = path + "/UniSoft_API/Deployment/Datatransfer_Mark";
        this.URL_class = path + "/UniSoft_API/Deployment/Datatransfer_Class";
        this.URL_teacher_room = path + "/UniSoft_API/Deployment/Datatransfer_Teacher_Room";
        break;
      case "Step3" :
        this.titleModal = "BƯỚC 03: KIỂM TRA CÀI ĐẶT KHÁCH HÀNG";
        this.customerCode = Data.CustomerCode;
        break;
      case "Step4" :
        this.titleModal = "BƯỚC 04: KIỂM TRA HOẠT ĐỘNG ĐÀO TẠO KHÁCH HÀNG";        
        this.customerCode = Data.CustomerCode;
        break;
    }
  }

  get checkvalue() {
    return this.AddForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.resultResponse = true;
    this.getMessageWorking();
    if (this.AddForm.invalid) {
      return false;
    }
    let req = {
      DatabaseNameCreate: this.AddForm.value.DatabaseNameCreate,
      DatabaseNameBackup: this.AddForm.value.DatabaseNameBackup,
    }
    this.spinner.show();
    this.DeploymentService.CreateDatabse(req, this.Token)
    .subscribe(res => {
      this.step1Status = res.Status;
      this.step1Message = res.Message;
      if (this.step1Status == 1) {
        this.DeploymentService.BackupDatabase(req, this.Token)
        .subscribe(res => {
          this.step2Status = res.Status;
          this.step2Message = res.Message;
          if (this.step2Status == 1) {
            this.DeploymentService.RestoreDB(req, this.Token)
            .subscribe(res => {
              this.step3Status = res.Status;
              this.step3Message = res.Message;
              if (this.step3Status == 1) {
                this.DeploymentService.TruncateTable_Step4(req, this.Token)
                .subscribe(res => {
                  this.step4Status = res.Status;
                  this.step4Message = res.Message;
                  if (this.step4Status == 1) {
                    this.DeploymentService.TruncateTable_Step5(req, this.Token)
                    .subscribe(res => {
                      this.step5Status = res.Status;
                      if (this.step5Status == 1) {
                        this.step5Message = res.Message;
                        this.spinner.hide();
                      } 
                      else {
                        this.toastr.warning(res.Message);
                        this.step5Message = "Tác vụ thất bại. Kết thúc quá trình !";
                        this.spinner.hide();
                      }
                    })
                  }
                  else {
                    this.toastr.warning(res.Message);
                    this.step4Message = "Tác vụ thất bại !";
                    this.step5Message = "Tác vụ thất bại !";
                    this.spinner.hide();
                  }
                })
              }
              else {
                this.toastr.warning(res.Message);
                this.step3Message = "Tác vụ thất bại !";
                this.step4Message = "Tác vụ thất bại !";
                this.step5Message = "Tác vụ thất bại !";
                this.spinner.hide();
              }
            })
          }
          else {
            this.toastr.warning(res.Message);
            this.step2Message = "Tác vụ thất bại !";
            this.step3Message = "Tác vụ thất bại !";
            this.step4Message = "Tác vụ thất bại !";
            this.step5Message = "Tác vụ thất bại !";
            this.spinner.hide();
          }
        })
      }
      else {
        this.toastr.warning(res.Message);
        this.step1Message = "Tác vụ thất bại !";
        this.step2Message = "Tác vụ thất bại !";
        this.step3Message = "Tác vụ thất bại !";
        this.step4Message = "Tác vụ thất bại !";
        this.step5Message = "Tác vụ thất bại !";
        this.spinner.hide();
      }
    },
    (err) => {
     if (err.status == 401) {
      this.spinner.show();
        this.toastr.warning(
          'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
        );
        this.spinner.hide();
      }
    });  
  }

  filterCustomer() {
    this.spinner.show();
    this.dataCustomerFilter = this.dataTable.filter(x => x.DatabaseName == this.AddForm.value.DatabaseNameBackup);
    this.spinner.hide();
  }

  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn['dataTable'].ext.search.pop();
    });
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let CustomerCode_data = data[1];
      let CustomerName_data = data[2];
      let TypeName_data = data[3];
      let Url_update_data = data[4];
      let DatabaseName_data = data[5];;
      let ServerName_data = data[6];
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let bool4 = true;
      let bool5 = true;
      let bool6 = true;

      if (this.searchForm.value.CustomerCode != '') {
        bool1 = CustomerCode_data == this.searchForm.value.CustomerCode;
      }
      if (this.searchForm.value.CustomerName != '') {
        bool2 = this.xoa_dau(CustomerName_data.toLowerCase()).includes(this.xoa_dau(this.searchForm.value.CustomerName.toLowerCase()));
      }
      if (this.searchForm.value.TypeName != '') {
        bool3 = TypeName_data == this.searchForm.value.TypeName;
      }
      if (this.searchForm.value.Url_update != '') {
        bool4 = this.xoa_dau(Url_update_data.toLowerCase()).includes(this.xoa_dau(this.searchForm.value.Url_update.toLowerCase()));
      }
      if (this.searchForm.value.DatabaseName != '') {
        bool5 = DatabaseName_data == this.searchForm.value.DatabaseName;
      }
      if (this.searchForm.value.ServerName != '') {
        bool6 = ServerName_data == this.searchForm.value.ServerName;
      }
      if (bool1 && bool2 && bool3 && bool4 && bool5 && bool6) {
        return true;
      }
      return false;
    });
  }
}
