import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
import { Module } from './../../Models/module';


@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private router: Router,
    private http: HttpClient
  ) { }

  Load_List(token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Module/Load_List', {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }

  Module_CustomerID(CustomerID:number,token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Module/Module_CustomerID?CustomerID='+CustomerID, {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  Module_Load_User(UserID:number,token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Module/Load_List_User?UserID='+UserID, {
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }
  Delete(ID_module: number, token): Observable<any> {
    return this.http.get<any>(this.appConfig.UniUpdateVersion + 'Module/Delete?ModuleID='+ID_module,{
         headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
  }

  Update(req: any,token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Module/Update',req,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

  Insert(module: any, token): Observable<any> {
    return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Module/Insert',module,{
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
   }).pipe(map((z) => {return z;}));
  }

}
