import { ResponseLogin } from 'src/app/Models/output.model/ResponseLogin';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';

export class common {
  public LoginResult: ResponseLogin;
  public PortalResult: any;
  public cookieService: CookieService;
  public CheckLogin() {
    this.LoginResult = new ResponseLogin();
    this.LoginResult = this.getUserinfo();

    if (this.LoginResult) {
      const token = this.getDecodedAccessToken(this.LoginResult.Token);
      const expjwt = new Date(0);
      const date = new Date();
      const currentDate = new Date(date.setHours(date.getHours() - 1));
      const d = date.setMinutes(date.getMinutes() + 60);
  
      // Thời gian hết hạn Token
      const dateCheck = new Date(expjwt.setUTCSeconds(token.exp));
  
      let checkToken: Boolean = false;
      checkToken = (dateCheck < currentDate);
  
      if (checkToken || this.LoginResult == null) {
        localStorage.removeItem('UserInfo');
        this.router.navigate(['/login']);
      }
    }
    else {
      localStorage.removeItem('UserInfo');
      this.router.navigate(['/login']);
    }
  }
  
  getDecodedAccessToken(token: string): any {
    try{
        return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }

  constructor(private router: Router) {}

  public getUserinfo() {
    this.LoginResult = JSON.parse(localStorage.getItem('UserInfo'));
    return this.LoginResult;
  }

  login() {
    this.router.navigate(['/']);
  }
  logout() {
    localStorage.removeItem('UserInfo');
    this.router.navigate(['/login']);
  }
}
