import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { Subject } from 'rxjs';
import { BaseComponent, removeSpaces, formatDate} from 'src/app/base/base.component';

@Component({
  selector: 'app-function',
  templateUrl: './function.component.html',
  styleUrls: ['./function.component.scss']
})
export class FunctionComponent extends BaseComponent implements OnInit {

  dtOptions: DataTables.Settings;
  dataTable: any = []
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective
  dtTrigger = new Subject();

  AddForm = new FormGroup({
    FunctionCode: new FormControl(null, [
      Validators.required,
      Validators.maxLength(50),
      removeSpaces]),
    FunctionName: new FormControl(null, [
      Validators.required,
      Validators.maxLength(200),
      removeSpaces])
  })


  ngOnInit(): void {
    this.getToken();
    /**-------------------------USE dtOptions to format table-------------------------*/
    // Filter DATA TABLE
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: true,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        },
      },
      columns: [
        {
          title: 'STT',
          width: '4vh',
          className: "dt-center"
        },
        {
          title: 'Mã quyền',
          className: "dt-center",
          width: '4vh',
        },
        {
          title: 'Tên quyền',
          className: "dt-center",
          width: '10vh',
        },
        {
          title: 'Thao tác',
          width: '10vh',
          className: "dt-center"
        },
      ],
    };
    this.getListFunction();
  }

  getListFunction() {
    this.FunctionService.Load_List(this.Token)
      .subscribe(z => {
        this.dataTable = z.listFunctions;
        this.dtTrigger.next();
    });
  }

  open(content, sizm, type, Data) {
    this.selected_ID = Data.FunctionID;
    this.submitted = false;
    this.AddForm.reset();
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );

      if(type=="Add"){
        this.AddForm.reset();
        this.checkInsert = true;
        this.titleModal = "Thêm mới";
      }
      if(type=="Update") {
        this.titleModal = "Cập nhật";
        this.checkInsert =false;
        this.AddForm.patchValue({
          FunctionCode: Data.FunctionCode,
          FunctionName: Data.FunctionName,
        });
      }
      if (type == "Delete") {
        this.selected_ID = Data.FunctionID;
      }
  }

  get checkvalue() {
    return this.AddForm.controls;
  }

  deleteItem() {
    this.spinner.show();
    this.FunctionService.Delete(this.selected_ID, this.Token)
      .subscribe(z => {
        if (z.Status == 2) {
          this.spinner.show();
          this.toastr.warning(z.Message);
          this.spinner.hide();
        } else if (z.Status == 1) {
          this.spinner.show();
          this.toastr.success("Xóa thành công !");
          this.spinner.hide();
        } else {
          this.spinner.show();
          this.toastr.error(z.Message);
          this.spinner.hide();
        };
        this.modalService.dismissAll('');
        this.datatableElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          }
        );
        this.getListFunction();
      },
      (err) => {
       if (err.status == 401) {
        this.spinner.show();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.spinner.hide();
        }
      });
  }

  onSubmit() {
    this.submitted = true;
    if (this.AddForm.invalid) {
      return false;
    }
    let req = {
      FunctionID: this.selected_ID,
			FunctionCode: this.AddForm.value.FunctionCode,
			FunctionName: this.AddForm.value.FunctionName,
    }
    if(this.checkInsert){
      this.FunctionService.Insert(req,this.Token).subscribe((res)=>{
        if (res.Status == 2) {
          this.toastr.warning(res.Message);
        } else if (res.Status == 1) {
          this.toastr.success(res.Message);
        } else if (res.Status == 4) {
          this.toastr.error('Thêm mới thất bại!');
        }
        this.modalService.dismissAll('AddModal');
        this.datatableElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          }
        );
        this.getListFunction();
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      })
    }else{
      this.FunctionService.Update(req,this.Token).subscribe((res)=>{
        if (res.Status == 2) {
          this.toastr.warning(res.Message);
        } else if (res.Status == 1) {
          this.toastr.success(res.Message);
        } else if (res.Status == 4) {
          this.toastr.error('Cập nhật thất bại!');
        }
        this.modalService.dismissAll('AddModal');
        this.datatableElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          }
        );
        this.getListFunction();
      },
      (err) => {

        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      })
    }
  }
}
