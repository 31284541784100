import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { PaymentPartnerService } from 'src/app/utils/services/payment-partner.service';
import { BaseComponent, removeSpaces } from 'src/app/base/base.component';

@Component({
  selector: 'app-payment-partner',
  templateUrl: './payment-partner.component.html',
  styleUrls: ['./payment-partner.component.scss']
})

export class PaymentPartnerComponent extends BaseComponent implements OnInit {

  dtOptions: DataTables.Settings;
  dataTable: any = []
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective
  dtTrigger = new Subject();

  AddForm = new FormGroup({
    Ma_truong: new FormControl(null, [Validators.required,Validators.maxLength(20), removeSpaces]),
    Ma_doi_tac: new FormControl('', [Validators.required,Validators.maxLength(20),removeSpaces]),
    Ten_doi_tac: new FormControl('', [Validators.required,Validators.maxLength(200),removeSpaces]),
    URL_doi_tac: new FormControl(''),
    URL_truong: new FormControl(null),
    Ma_bao_mat:new FormControl('', [Validators.required,Validators.maxLength(200),removeSpaces]),
    Ma_bao_mat_doi_tac: new FormControl(''),
    Ma_dai_ly: new FormControl(''),
    Ten_dai_ly: new FormControl(''),
    Ma_dich_vu: new FormControl(''),
    Dia_chi: new FormControl(''),
    So_dien_thoai: new FormControl(''),
    Nguoi_lien_he: new FormControl(''),
    So_dien_thoai_nguoi_lien_he: new FormControl(''),
    Dia_chi_nguoi_lien_he: new FormControl(null),
    Ma_so_thue: new FormControl(null),
    Noi_dung_hop_tac: new FormControl(null),
  })

  searchForm = new FormGroup({
    Ma_truong: new FormControl(''),
    Ma_doi_tac: new FormControl(''),
    Ten_doi_tac: new FormControl(''),
  })

  ngOnInit(): void {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.UserID_get = a.Info.UserID;
    this.Token = a.Token;
    /**-------------------------USE dtOptions to format table-------------------------*/
    // Filter DATA TABLE
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: true,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        },
      },
      columns: [
        {
          title: 'Mã khách hàng',
          className: "dt-center",
        },
        {
          title: 'Tên khách hàng',
        },
        {
          title: 'URL cổng sinh viên',
        },
        {
          title: 'Mã đối tác',
          className: "dt-center",
        },
        {
          title: 'Tên đối tác',
        },
        {
          title: 'URL cổng thanh toán',
        },
        {
          title: 'Mã đại lý (MerchantId)',
          className: "dt-center",
        },
        {
          title: 'Mã dịch vụ (ServiceId)',
          className: "dt-center",
        },
        {
          title: 'Thao tác',
          width: '10vh',
          className: "dt-center"
        },
      ],
    };
    this.getList();
  }

  getList() {
    this.PaymentPartnerService.getList(this.Token).subscribe(
      (res) => {
        this.dataTable = res.Data;
        this.dtTrigger.next();
      }
    );
  }

  open(content, sizm, type, Data) {
    this.selected_ID = Data.ID_doi_tac;
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );

      if(type=="Add"){
        this.AddForm.reset();
        this.checkInsert = true;
        this.titleModal = "Thêm mới";
      }
      if(type=="Update") {
        this.selected_ID = Data.ID_doi_tac;
        this.titleModal = "Cập nhật";
        this.checkInsert =false;
        this.AddForm.patchValue({
          Ma_truong: Data.Ma_truong,
          Ma_doi_tac: Data.Ma_doi_tac,
          Ten_doi_tac: Data.Ten_doi_tac,
          URL_doi_tac: Data.URL_doi_tac,
          URL_truong: Data.URL_truong,
          Ma_bao_mat_doi_tac: Data.Ma_bao_mat_doi_tac,
          Ma_bao_mat: Data.Ma_bao_mat,
          Ma_dai_ly: Data.Ma_dai_ly,
          Ten_dai_ly: Data.Ten_dai_ly,
          Ma_dich_vu: Data.Ma_dich_vu,
          Dia_chi: Data.Dia_chi,
          So_dien_thoai: Data.So_dien_thoai,
          Nguoi_lien_he: Data.Nguoi_lien_he,
          So_dien_thoai_nguoi_lien_he: Data.So_dien_thoai_nguoi_lien_he,
          Dia_chi_nguoi_lien_he: Data.Dia_chi_nguoi_lien_he,
          Ma_so_thue: Data.Ma_so_thue,
          Noi_dung_hop_tac: Data.Noi_dung_hop_tac,
        });
      }
      if (type == "Delete") {
        this.selected_ID = Data.ID_doi_tac;
    }
  }

  get checkvalue() {
    return this.AddForm.controls;
  }

  deleteItem() {
    this.spinner.show();
    this.PaymentPartnerService.delete(this.selected_ID, this.Token)
      .subscribe(z => {
        if (z.Status == 2) {
          this.spinner.show();
          this.toastr.warning(z.Message);
          this.spinner.hide();
        } else if (z.Status == 1) {
          this.spinner.show();
          this.toastr.success(z.Message);
          this.spinner.hide();
        } else {
          this.spinner.show();
          this.toastr.error(z.Message);
          this.spinner.hide();
        };
        this.modalService.dismissAll('');
        this.datatableElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          }
        );
        this.getList();
      },
      (err) => {
       if (err.status == 401) {
        this.spinner.show();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.spinner.hide();
        }
      });
  }

  onSubmit() {
    this.submitted = true;
    if (this.AddForm.invalid) {
      return false;
    }
    let req = {
      ID_doi_tac: this.selected_ID,
      Ma_truong: this.AddForm.value.Ma_truong,
      Ma_doi_tac: this.AddForm.value.Ma_doi_tac,
      Ten_doi_tac: this.AddForm.value.Ten_doi_tac,
      URL_doi_tac: this.AddForm.value.URL_doi_tac,
      URL_truong: this.AddForm.value.URL_truong,
      Ma_bao_mat_doi_tac: this.AddForm.value.Ma_bao_mat_doi_tac,
      Ma_bao_mat: this.AddForm.value.Ma_bao_mat,
      Ma_dai_ly: this.AddForm.value.Ma_dai_ly,
      Ten_dai_ly: this.AddForm.value.Ten_dai_ly,
      Ma_dich_vu: this.AddForm.value.Ma_dich_vu,
      Dia_chi: this.AddForm.value.Dia_chi,
      So_dien_thoai: this.AddForm.value.So_dien_thoai,
      Nguoi_lien_he: this.AddForm.value.Nguoi_lien_he,
      So_dien_thoai_nguoi_lien_he: this.AddForm.value.So_dien_thoai_nguoi_lien_he,
      Dia_chi_nguoi_lien_he: this.AddForm.value.Dia_chi_nguoi_lien_he,
      Ma_so_thue: this.AddForm.value.Ma_so_thue,
      Noi_dung_hop_tac: this.AddForm.value.Noi_dung_hop_tac,
    }
    if(this.checkInsert){
      this.PaymentPartnerService.insert(req,this.Token).subscribe((res)=>{
        if (res.Status == 2) {
          this.toastr.warning(res.Message);
        } else if (res.Status == 1) {
          this.toastr.success(res.Message);
        } else if (res.Status == 4) {
          this.toastr.error(res.Message);
        }
        this.modalService.dismissAll('AddModal');
        this.datatableElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          }
        );
        this.getList();
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      })
    }else{
      this.PaymentPartnerService.update(req,this.Token).subscribe((res)=>{
        if (res.Status == 2) {
          this.toastr.warning(res.Message);
        } else if (res.Status == 1) {
          this.toastr.success(res.Message);
        } else if (res.Status == 4) {
          this.toastr.error('Cập nhật thất bại!');
        }
        this.modalService.dismissAll('AddModal');
        this.datatableElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          }
        );
        this.getList();
      },
      (err) => {

        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      })
    }
  }

  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn['dataTable'].ext.search.pop();
    });
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let Ma_truong_data = data[0];
      let Ma_doi_tac_data = data[3];
      let Ten_doi_tac_data = data[4];

      let bool1 = true;
      let bool2 = true;
      let bool3 = true;


      if (this.searchForm.value.Ma_truong != '') {
        bool1 = this.xoa_dau(Ma_truong_data.toLowerCase()).includes(this.xoa_dau(this.searchForm.value.Ma_truong.toLowerCase()));
      }
      if (this.searchForm.value.Ma_doi_tac != '') {
        bool2 = this.xoa_dau(Ma_doi_tac_data.toLowerCase()).includes(this.xoa_dau(this.searchForm.value.Ma_doi_tac.toLowerCase()));
      }
      if (this.searchForm.value.Ten_doi_tac != '') {
        bool2 = this.xoa_dau(Ten_doi_tac_data.toLowerCase()).includes(this.xoa_dau(this.searchForm.value.Ten_doi_tac.toLowerCase()));
      }
      if (bool1 && bool2 && bool3) {
        return true;
      }
      return false;
    });
  }
}

