<div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
    <h4 class="modal-title h-title w-100"> {{titleModalInput}}</h4>
    <span (click)="cancel()" aria-hidden="true" style="font-size: 20px;">&times;</span>
  </div>
  <div class="modal-body" style="overflow-y: auto; max-height: 60vh;">
    <div class="panel-group" id="accordion">
      <div class="panel panel-default">
          <div class="card-header bg-info">
              <i class="fas fa-user-tie"></i>&nbsp;Thống kê sử dụng người dùng
              <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-toggle="collapse" [attr.data-target]="'#collapse1'" [attr.aria-controls]="'#collapse1' " aria-expanded="true" >
                      <i class="fas fa-minus"></i>
                  </button>
              </div>
          </div>
          <div class="collapse hide" id="collapse1">
          <div id="collapse1" class="panel-collapse collapse in">
            <div class="panel-body">
            <!----------------------------------------------- TABLE ------------------------------------------------->
              <div [style]='styleBinding'>
              <table class="table table-bordered">
                <thead style="text-align: center;">
                  <th>Phòng ban/ Khoa</th>
                  <th style="white-space: nowrap;">Người dùng</th>
                  <th>Họ tên</th>
                  <th>Email</th>
                  <th style="white-space: nowrap;">Số chức năng</th>
                  <th style="white-space: nowrap;">Tổng số</th>
                </thead>
                <tbody>
                  <tr *ngFor="let dataUser of dataStatisticUser; let i=index">
                    <td style="white-space: nowrap;">{{dataUser.Ten_don_vi}}</td>
                    <td style="text-align: center;white-space: nowrap;">{{dataUser.Ten_nguoi_dung}}</td>
                    <td style="white-space: nowrap;">{{dataUser.Ho_ten_nguoi_dung}}</td>
                    <td style="text-align: center;white-space: nowrap;">{{dataUser.Email}}</td>
                    <td style="text-align: center">{{dataUser.So_lan_su_dung}}</td>
                    <td style="text-align: center">{{dataUser.So_chuc_nang_su_dung}}</td>
                  </tr>
                  <tr *ngIf="!(dataStatisticUser.length > 0)" style="text-align: center;font-weight: bold;">
                    <td colspan="7">Không có dữ liệu !</td>
                  </tr>
                </tbody>                                  
              </table>   
              </div>   
            </div>
          </div>
        </div>
      </div> 
      <div class="panel panel-default" style="margin-top: 10px;">
        <div class="card-header bg-info">
            <i class="fas fa-box-open"></i>&nbsp;Thống kê sử dụng chức năng
            <div class="card-tools">
                <button type="button" class="btn btn-tool" data-toggle="collapse" [attr.data-target]="'#collapse2'" [attr.aria-controls]="'#collapse2' " aria-expanded="true" >
                    <i class="fas fa-minus"></i>
                </button>
            </div>
        </div>
        <div class="collapse hide" id="collapse2">
        <div id="collapse2" class="panel-collapse collapse in">
          <div class="panel-body">
            <!----------------------------------------------- TABLE ------------------------------------------------->
            <table class="table table-bordered">
              <thead style="text-align: center;">
                <th>STT</th>
                <th>Phân hệ</th>
                <th>Tên chức năng</th>
                <th>Tổng số lần thực hành</th>
              </thead>
              <tbody>
                <tr *ngFor="let dataFunction of dataStatisticFunction; let i=index">
                  <td style="text-align: center;">{{i+1}}</td>
                  <td>{{dataFunction.Ten_phan_he}}</td>
                  <td>{{dataFunction.Ten_chuc_nang}}</td>
                  <td style="white-space: nowrap;text-align: center;">{{dataFunction.So_lan_su_dung}}</td>
                </tr>
                <tr *ngIf="!(dataStatisticUser.length > 0)" style="text-align: center;font-weight: bold;">
                  <td colspan="4">Không có dữ liệu !</td>
                </tr>
              </tbody>                                  
            </table>     
          </div>
        </div>
      </div>
    </div>   
  </div>
</div>
<div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-outline-danger btn-sm" (click)="cancel()"
        style="margin-left: 1em;">
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
</div>
