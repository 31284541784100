<!----------------------------------------------- HEADER ------------------------------------------------->
<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">Quản lí đơn vị quá hạn cập nhật</li>
                </ol>
            </div>
        </div>
    </div>
  </section>
  
<!----------------------------------------------- FILTER ------------------------------------------------->
<section class="content cus-body ">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="searchForm">
              <div class="row">
                <div class="col-md-3">
                  <label class="col-md-10" style="margin-top: 10px;">Mã khách hàng</label>
                  <input type="text" class="form-control" placeholder="Nhập mã khách hàng" formControlName="CustomerCode" (keyup)="filterById()">
                </div>
                <div class="col-md-3">
                    <label class="col-md-10" style="margin-top: 10px;">Tên khách hàng</label>
                    <input type="text" class="form-control" placeholder="Nhập tên khách hàng" formControlName="CustomerName" (keyup)="filterById()">
                </div>
                <div class="col-md-3">
                    <label class="col-md-10" style="margin-top: 10px;">Mã phân hệ</label>
                    <input type="text" class="form-control" placeholder="Nhập mã phân hệ" formControlName="ModuleCode" (keyup)="filterById()">
                </div>
                <div class="col-md-3">
                    <label class="col-md-10" style="margin-top: 10px;">Người cập nhật</label>
                    <input type="text" class="form-control" placeholder="Nhập tên cập nhật" formControlName="Nguoi_cap_nhat" (keyup)="filterById()">
                </div>
              </div>
        </form>
        </div>
    </div>
  </section>
  <!----------------------------------------------- TABLE ------------------------------------------------->
  <section class="content cus-body">
    <div class="card">
      <div class="card-body">
        <div class="tab-content">
          <div class="active tab-pane" id="TeachInfo">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <div class="container">
                  </div>
                  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                  class="table-bordered dataTables_wrapper no-footer">
                     <thead style="text-align: center;">
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                     </thead>
                     <tbody id = "myTable">
                         <tr *ngFor="let c of dataTable; let i=index" >
                             <td>{{i+1}}</td>
                             <td>{{c.CustomerCode}}</td>
                             <td>{{c.CustomerName}}</td>
                             <td>{{c.ModuleCode}}</td>
                             <td>{{c.Cap_nhat_lan_cuoi | date:'dd-MM-yyyy hh:aa'}}</td>
                             <td>{{c.Nguoi_cap_nhat}}</td>
                             <td>
                              <button class="btn btn-outline-info btn-sm"
                              (click)="open(UploadModal, 'lg', 'Upload', c)"
                              ngbTooltip="Cập nhật"
                              style="margin-right: 5px">
                              <i class="fas fa-upload"></i>
                              </button>
                             </td>
                         </tr>
                     </tbody>
                 </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-----------------------------------------------UPLOAD MODAL ------------------------------------------------->
<ng-template #UploadModal let-modal>
  <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px">
    <h4 class="modal-title h-title w-100"> {{titleModal}}</h4>
    <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
  </div>
  <div class="icon-box" style="margin-top: 20px;border: 3px solid #17a2b8 !important;">
    <i class="fas fa-file-medical"
    style="font-size: 46px;display: inline-block;margin-top: 13px; color: #17a2b8"></i>
  </div>
  <div class="container">
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="Upload">
      <div class="header-content">
        <div class="total-page col-md-12">
            <nav class="navbar navbar-light bg-light" style="padding: 20px 0 !important">
                <div class="col-md-5">
                    <label class="col-md-10 require">Phân hệ</label>
                      <ng-select [ngClass]="{ 'is-invalid': submitted && checkvalueUpload.ModuleCode.errors }"
                      formControlName="ModuleCode" notFoundText="Không có kết quả" (change)="getRevision()">
                        <ng-option value="">Chọn phân hệ</ng-option>
                        <ng-option *ngFor="let module of listModuleByCustomerID" [value]="module.ModuleCode">{{module.ModuleCode}}</ng-option>
                      </ng-select>
                      <div *ngIf="submitted && checkvalueUpload.ModuleCode.errors" class="invalid-feedback">
                        <div *ngIf="checkvalueUpload.ModuleCode.errors.required">
                            Lựa chọn bắt buộc !
                        </div>
                      </div>
                </div>
                <div class="col-md-4">
                  <label class="col-md-10 require">Revision</label>
                  <input type="text" [ngClass]="{ 'is-invalid': submitted && checkvalueUpload.Revision.errors }"
                   readonly class="form-control" formControlName="Revision">
                   <div *ngIf="submitted && checkvalueUpload.Revision.errors" class="invalid-feedback">
                    <div *ngIf="checkvalueUpload.Revision.errors.required">
                      Bắt buộc !
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <label class="col-md-10 require">Phiên bản</label>
                    <ng-select [ngClass]="{ 'is-invalid': submitted && checkvalueUpload.VersionID.errors }"
                     formControlName="VersionID" notFoundText="Không có kết quả">
                      <ng-option *ngFor="let version of versions" [value]="version.VersionID">{{version.VersionName}}</ng-option>
                    </ng-select>
                    <div *ngIf="submitted && checkvalueUpload.VersionID.errors" class="invalid-feedback">
                      <div *ngIf="checkvalueUpload.VersionID.errors.required">
                        Lựa chọn bắt buộc !
                      </div>
                    </div>
                </div>
            </nav>
        </div>
      </div>
      <div class="main-content">
        <div class="total-page col-md-12">
            <div class="row">
              <div class="half-page col-md-12">
                  <label>Nội dung cập nhật</label>
                  <textarea class="form-control" rows="2" formControlName="UpdateNote"></textarea>
              </div>
              <div class="half-page col-md-12">
                  <label>Kết quả trả về</label>
                  <textarea readonly class="form-control" rows="4" formControlName="UpdateError"></textarea>
              </div>
            </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-outline-info btn-sm" (click)="Save()"
      style="margin-left: 1em;">
      <i class="fas fa-pen-alt"></i> Cập nhật
    </button>
      <button type="button" class="btn btn-outline-danger btn-sm" (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;">
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>
