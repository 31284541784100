<div class="modal-content">
    <div class="modal-body">
        <div class="icon-box">
            <i class="fas fa-trash-alt"
                style="color: #f15e5e;font-size: 46px;display: inline-block;margin-top: 13px;"></i>
        </div>
        <p
            style="color: inherit; text-decoration: none;font-size: 20px; text-align: center; font-weight: 600; margin-top: 1em;">
            Bạn có chắc chắn muốn xóa
        </p>
        <div class="text-center">
            <button type="button" class="btn btn-md btn-outline-success" (click)="confirm()" >
                Đồng ý
            </button>
            <button type="button" class="btn btn-md btn-outline-danger" (click)="cancel()" style="margin-left: 1em;">
                Hủy bỏ
            </button>
           
        </div>
    </div>
</div>