import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
  })
  export class DeploymentService {
  
    constructor(
      @Inject(AppConfig) private readonly appConfig: AppConfiguration,
      private router: Router,
      private http: HttpClient
    ) { }

    //------------------------------------STEP 1------------------------------------
    CreateDatabse(req: any,token): Observable<any> {
      return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Deployment/CreateDatabase',req,{
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
     }).pipe(map((z) => {return z;}));
    }
    //------------------------------------STEP 2------------------------------------
    BackupDatabase(req: any,token): Observable<any> {
      return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Deployment/BackupDatabaseDeploy',req,{
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
     }).pipe(map((z) => {return z;}));
    }
    //------------------------------------STEP 3------------------------------------
    RestoreDB(req: any,token): Observable<any> {
      return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Deployment/RestoreDBDeploy',req,{
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
    }
    //------------------------------------STEP 4------------------------------------
    TruncateTable_Step4(req: any,token): Observable<any> {
      return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Deployment/TruncateTable_Step4',req,{
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
    }
    //------------------------------------STEP 5------------------------------------
    TruncateTable_Step5(req: any,token): Observable<any> {
      return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Deployment/TruncateTable_Step5',req,{
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
      }).pipe(map((z) => {return z;}));
    }
    
    Load_Datatransfer_Class(req: any): Observable<any> {
      return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Deployment/Load_Datatransfer_Class',req).pipe(map((z) => {return z;}));
    }

    Load_DataSearch_Transfer(req: any): Observable<any> {
      return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Deployment/Load_DataSearch_Transfer',req).pipe(map((z) => {return z;}));
    }
    
    Load_Datatransfer_Teacher_Room(req: any): Observable<any> {
      return this.http.post<any>(this.appConfig.UniUpdateVersion + 'Deployment/Load_Datatransfer_Teacher_Room',req).pipe(map((z) => {return z;}));
    }

    StatisticUpdateVersion(CustomerCode: any,PageNumber: number, RowsOfPage: number, token): Observable<any> {
      return this.http.get<any>(this.appConfig.UniUpdateVersion + `Deployment/StatisticUpdateVersion?CustomerCode=${CustomerCode}&PageNumber=${PageNumber}&RowsOfPage=${RowsOfPage}`,{
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
     }).pipe(map((z) => {return z;}));
    }

    StatisticFunction(CustomerCode: any, token): Observable<any> {
      return this.http.get<any>(this.appConfig.UniUpdateVersion + `Deployment/StatisticFunction?CustomerCode=${CustomerCode}`,{
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
     }).pipe(map((z) => {return z;}));
    }

    StatisticUser(CustomerCode: any, token): Observable<any> {
      return this.http.get<any>(this.appConfig.UniUpdateVersion + `Deployment/StatisticUser?CustomerCode=${CustomerCode}`,{
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
     }).pipe(map((z) => {return z;}));
    }

    GachNoGiaoDich_Load_List(req: any, token): Observable<any> {
      return this.http.post<any>(this.appConfig.UniUpdateVersion + `Deployment/GachNoGiaoDich_Load_List`,req,{
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
     }).pipe(map((z) => {return z;}));
    }
}