<app-user-customer></app-user-customer>

<!-- <section class="content-header" style="padding: 0.5% !important;">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-md-3">
              <ol class="breadcrumb float-sm-left">
                  <li class="breadcrumb-item">
                      <a [routerLink]="['/admin']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">Quản lý khách hàng</li>
              </ol>
          </div>
      </div>
  </div>
</section>

<ngb-tabset [destroyOnHide]="false">
<ngb-tab>
  <ng-template ngbTabTitle><p style="font-weight: bold;">Khách hàng cá nhân</p></ng-template>
  <ng-template ngbTabContent>
        <app-user-customer></app-user-customer>
  </ng-template>
</ngb-tab>
<ngb-tab>
  <ng-template ngbTabTitle><p style="font-weight: bold;">Khách hàng được cập nhật</p></ng-template>
  <ng-template ngbTabContent>
        <app-updated-customer></app-updated-customer>
  </ng-template>
</ngb-tab>
<ngb-tab>
  <ng-template ngbTabTitle><p style="font-weight: bold;">Khách hàng chưa cập nhật</p></ng-template>
  <ng-template ngbTabContent>
        <app-none-update-customer></app-none-update-customer>
  </ng-template>
</ngb-tab>
</ngb-tabset> -->
