import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { BlankComponent } from './views/blank/blank.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { AuthGuard } from './utils/guards/auth.guard';
import { NonAuthGuard } from './utils/guards/non-auth.guard';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { DangNhapComponent } from './pages/dang-nhap/dang-nhap.component';
import { PortalComponent } from './pages/portal/portal.component';
import { ProfileComponent } from './views/profile/profile.component';
import { ChangePassComponent } from './pages/change-pass/change-pass.component';
// import { UserCustomerComponent } from './pages/customer/user-customer/user-customer.component';
import { UserManagerComponent } from './components/user-manager/user-manager.component';
import { FunctionComponent } from './components/function/function.component';
import { ModuleComponent } from './components/module/module.component';
import { VersionComponent } from './components/version/version.component';
import { CustomerComponent } from './components/customer/customer.component';
import { UpdateVersionComponent } from './components/update-version/update-version.component';
import { UserFunctionComponent } from './components/function/user-function/user-function.component';
import { CustomerTypeComponent } from './components/customer-type/customer-type.component';
import { LogSystemComponent } from './components/admin-log/log-system/log-system.component';
import { HistoryRestoreComponent } from './components/admin-log/history-restore/history-restore.component';
import { HistoryUpdateVersionComponent } from './components/admin-log/history-update-version/history-update-version.component';
import { TestComponent } from './components/test/test.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ComfirmPasswordComponent } from './pages/comfirm-password/comfirm-password.component';
import { DeveloperSqlComponent } from './components/developer-sql/developer-sql.component';
import { PaymentPartnerComponent } from './components/payment-partner/payment-partner.component';
import { LevelCustomerComponent } from './components/level-using/level-customer/level-customer.component';
import { LevelFunctionComponent } from './components/level-using/level-function/level-function.component';
import { DetailLevelComponent } from './components/level-using/detail-level/detail-level.component';
import { TransactionComponent } from './components/transaction/transaction.component';
import { NewCustomerComponent } from './components/new-customer/new-customer.component';
import { PreLoading } from './pre-loading';
import { NoneUpdateCustomerComponent } from './components/customer/none-update-customer/none-update-customer.component';
import { OutOfDateCustomerComponent } from './components/customer/out-of-date-customer/out-of-date-customer.component';
import { SearchTransactionComponent } from './components/search-transaction/search-transaction.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: { preload: true },
    children: [
      {
        path: '',
        component: DashboardComponent,
      },
      {
        path: 'user-manager',
        component: UserManagerComponent,
      },
      {
        path: 'function',
        component: FunctionComponent,
      },
      {
        path: 'customer',
        component: CustomerComponent,
      },
      {
        path: 'version',
        component: VersionComponent,
      },
      {
        path: 'module',
        component: ModuleComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
      },
      {
        path: 'change-pass',
        component: ChangePassComponent,
      },
      {
        path: 'update-version',
        component: UpdateVersionComponent
      },
      {
        path: 'user-function',
        component: UserFunctionComponent
      },
      {
        path: 'customer-type',
        component: CustomerTypeComponent
      },
      {
        path: 'log-system',
        component: LogSystemComponent
      },
      {
        path: 'history-restore',
        component: HistoryRestoreComponent
      },
      {
        path: 'history-update-version',
        component: HistoryUpdateVersionComponent
      },
      {
        path: 'payment-partner',
        component: PaymentPartnerComponent
      },
      {
        path: 'level-customer',
        component: LevelCustomerComponent
      },
      {
        path: 'level-function',
        component: LevelFunctionComponent
      },
      {
        path: 'detail-level',
        component: DetailLevelComponent
      },
      {
        path: 'developer-sql-technical-only',
        component: DeveloperSqlComponent,
      },
      {
        path: 'transaction',
        component: TransactionComponent,
      },
      {
        path: 'new-customer',
        component: NewCustomerComponent,
      },
      {
        path: 'out-of-date-customer',
        component: OutOfDateCustomerComponent,
      },
      {
        path: 'search-transaction',
        component: SearchTransactionComponent,
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'confirm-password/:UserName/:Email/:Token',
    component: ComfirmPasswordComponent,
    canActivate: [NonAuthGuard],
    
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes, 
    { 
      relativeLinkResolution: 'legacy',
      preloadingStrategy: PreloadAllModules,
    }), 
    BrowserModule, 
    HttpClientModule],
  exports: [RouterModule],
  providers: [PreLoading]
})
export class AppRoutingModule {}
