import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { BaseComponent, removeSpaces, formatDate} from 'src/app/base/base.component';

@Component({
  selector: 'app-search-transaction',
  templateUrl: './search-transaction.component.html',
  styleUrls: ['./search-transaction.component.scss']
})
export class SearchTransactionComponent extends BaseComponent implements OnInit {

  dtOptions: DataTables.Settings;
  dataTable: any = []
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective
  dtTrigger = new Subject();

  searchForm = new FormGroup({
    Ma_khach_hang: new FormControl(''),
    Ma_ngan_hang: new FormControl(''),
    Ma_chi_nhanh: new FormControl(''),
    Tu_ngay: new FormControl(''),
    Den_ngay: new FormControl(''),
    Trang_thai: new FormControl(1),
    Limit: new FormControl(1),
  });

  ngOnInit(): void {
    this.getToken();
    /**-------------------------USE dtOptions to format table-------------------------*/
    // Filter DATA TABLE
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: true,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        },
      },
      columns: [
        {
          title: 'STT',
          className: "dt-center",
          visible: false
        },
        {
          title: 'Mã khách hàng',
          className: "dt-center",
        },
        {
          title: 'Tên khách hàng',
        },
        {
          title: 'Mã ngân hàng',
          className: "dt-center",
        },
        {
          title: 'Mã chi nhánh',
          className: "dt-center",
        },
        {
          title: 'Mã đơn hàng',
          className: "dt-center",
        },
        {
          title: 'Mã giao dịch',
          className: "dt-center",
        },
        {
          title: 'Số tiền',
          className: "dt-center",
        },
        {
          title: 'Mã trạng thái',
          className: "dt-center",
        },
        {
          title: 'Ngày tháng',
          className: "dt-center",
        },
        {
          title: 'Thao tác',
          className: "dt-center",
          width: '75px'
        },
      ],
    };
    this.statisticByDate();
    this.getList();
  }

  getList() {
    this.spinner.show();
    let dataSearch = {
      Ma_truong: this.searchForm.value.Ma_khach_hang,
      Ma_ngan_hang: this.searchForm.value.Ma_ngan_hang,
      Ma_chi_nhanh: this.searchForm.value.Ma_chi_nhanh,
      Tu_ngay: this.searchForm.value.Tu_ngay,
      Den_ngay: this.searchForm.value.Den_ngay,
      Trang_thai: this.searchForm.value.Trang_thai,
    }
    this.DeploymentService.GachNoGiaoDich_Load_List(dataSearch,this.Token)
    .subscribe(z =>{
      this.dataTable = z.Data;
      this.dtTrigger.next();
      this.spinner.hide();
    })
  }

  statisticByDate() {
    this.filterDate(this.searchForm.value.Limit);
    this.searchForm.patchValue({
      Tu_ngay: this.dayBefore,
      Den_ngay: this.currentDate,
    })
  }

  search() {
    this.getList();
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      }
    );
  }

  open(content, sizm, type, Data) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );

      if(type=="Detail"){
        this.content_update = Data.Noi_dung;
        this.titleModal = "Nội dung cập nhật";
      }
  }
}
