<!----------------------------------------------- HEADER ------------------------------------------------->
<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">Tra cứu giao dịch thanh toán</li>
                </ol>
            </div>
            <div class="col-md-6">
                <button class="btn btn-info btn-sm" (click)="search()" style="float: right;"><i class="fas fa-chart-bar"></i>&nbsp;Thống kê
                </button>
            </div>
        </div>
    </div>
</section>

<!----------------------------------------------- FILTER ------------------------------------------------->
<section class="content cus-body ">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="searchForm">
              <div class="row">
                <div class="col-md-3">
                    <label class="col-md-10 " style="margin-top: 10px;">Mã khách hàng</label>
                    <input class="form-control" type="text" formControlName="Ma_khach_hang" placeholder="Nhập mã khách hàng"/>
                </div>
                <div class="col-md-3">
                    <label class="col-md-10 " style="margin-top: 10px;">Mã ngân hàng</label>
                    <input class="form-control" type="text" formControlName="Ma_ngan_hang" placeholder="Nhập mã ngân hàng"/>
                </div>
                <div class="col-md-3">
                    <label class="col-md-10 " style="margin-top: 10px;">Mã chi nhánh</label>
                    <input class="form-control" type="text" formControlName="Ma_chi_nhanh" placeholder="Nhập mã chi nhánh"/>
                </div>
                <div class="col-md-3">
                    <label class="col-md-10 " style="margin-top: 10px;">Trạng thái</label>
                    <select class="form-control" formControlName="Trang_thai">
                        <option value="null">Tất cả</option>
                        <option [ngValue]="1">Thành công</option>
                        <option [ngValue]="2">Thất bại</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label class="col-md-10" style="margin-top: 10px;">Phạm vi báo cáo</label>
                    <ng-select formControlName="Limit" notFoundText="Không có kết quả" (change)="statisticByDate()">
                        <ng-option [value]="0">Tùy chỉnh</ng-option>
                        <ng-option *ngFor="let p of selectDate" [value]="p.value">{{p.name}}</ng-option>
                    </ng-select>
                  </div>
                <div class="col-md-3">
                  <label class="col-md-10 " style="margin-top: 10px;">Từ ngày</label>
                  <input class="form-control" type="date" formControlName="Tu_ngay"/>
                </div>
                <div class="col-md-3">
                    <label class="col-md-10 " style="margin-top: 10px;">Đến ngày</label>
                    <input class="form-control" type="date" formControlName="Den_ngay"/>
                </div>
              </div>
        </form>
        </div>
    </div>
</section>
<!----------------------------------------------- TABLE ------------------------------------------------->
<section class="content cus-body">
    <div class="card">
      <div class="card-body">
        <div class="tab-content">
          <div class="active tab-pane" id="TeachInfo">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <div class="container">
                  </div>
                  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                  class="table-bordered dataTables_wrapper no-footer">
                     <thead style="text-align: center;">
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                     </thead>
                     <tbody id = "myTable">
                         <tr *ngFor="let t of dataTable; let i=index" >
                             <td>{{i+1}}</td>
                             <td>{{t.Ma_truong}}</td>
                             <td>{{t.Ten_truong}}</td>
                             <td>{{t.Ma_ngan_hang}}</td>
                             <td>{{t.Ma_chi_nhanh}}</td>
                             <td>{{t.Ma_don_hang}}</td>
                             <td>{{t.Ma_giao_dich}}</td>
                             <td>{{t.So_tien}}</td>
                             <td>{{t.Ma_trang_thai}}</td>
                             <td>{{t.Ngay_thang | date:'dd-MM-yyyy hh:aa'}}</td>
                             <td>
                              <button class="btn btn-outline-info btn-sm" ngbTooltip="Xem nội dung"
                                (click)="open(DetailModal, 'xs', 'Detail', t)"
                                style="margin-right: 5px;"> 
                                <i class="fas fa-eye"></i>
                              </button>  
                             </td>
                         </tr>
                     </tbody>
                 </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

<!-----------------------------------------------DETAIL MODAL ------------------------------------------------->
<ng-template #DetailModal let-modal>
  <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
    <h4 class="modal-title h-title w-100"> {{titleModal}}</h4>
    <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 25px;">&times;</span>
  </div>
  <div class="modal-body">
    <div style="word-break: break-word;" [innerHTML]>{{content_update}}</div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-outline-danger btn-sm" (click)="modal.dismiss('Cross click')"
        style="margin-left: 1em;">
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </div>
</ng-template>