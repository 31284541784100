<!----------------------------------------------- HEADER ------------------------------------------------->
<section class="content-header" style="padding:0.5%  !important">
    <div class="container-fluid">
        <div class="row mb-2">
              <div class="col-md-6">
                <ol class="breadcrumb float-left">
                    <li class="breadcrumb-item"><a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">Triển khai khách hàng mới</li>
                </ol>
            </div>
            <div class="col-md-6">
                <!-- <button class="btn btn-outline-success btn-sm" (click)="open(AddModal,'lg','Add','')" style="float: right;"><i
                        class="fas fa-plus-circle"></i> Thêm mới
                </button>
                <button class="btn btn-outline-success btn-sm"  type="submit" style="float: right;margin-right: 5px;" (click)="Excel()">
                  <i class="fas fa-file-excel"></i> Xuất báo cáo
                </button> -->
            </div>
        </div>
    </div>
</section>
<!----------------------------------------------- FILTER ------------------------------------------------->
<section class="content cus-body ">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="searchForm">
              <div class="row">
                <div class="col-md-4">
                  <label class="col-md-10" style="margin-top: 10px;">Mã khách hàng</label>
                  <ng-select formControlName="CustomerCode" (change)="filterById()" notFoundText="Không có kết quả">
                      <ng-option value="">Tất cả</ng-option>
                      <ng-option *ngFor="let customer of dataCustomerCodeFilter" [value]=customer>{{customer}}</ng-option>
                  </ng-select>
                </div>
                <div class="col-md-4">
                  <label class="col-md-10 " style="margin-top: 10px;">Tên khách hàng</label>
                  <input class="form-control" placeholder="Nhập tên khách hàng" type="text"
                      formControlName="CustomerName" (keyup)="filterById()" />
                </div>
                <!-- <div class="col-md-4">
                  <label class="col-md-10 " style="margin-top: 10px;">URL</label>
                  <input class="form-control" placeholder="Nhập URL" type="text"
                      formControlName="Url_update" (keyup)="filterById()" />
                </div>
                <div class="col-md-4">
                  <label class="col-md-10 " style="margin-top: 10px;">Database</label>
                  <ng-select formControlName="DatabaseName" (change)="filterById()" notFoundText="Không có kết quả">
                      <ng-option value="">Tất cả</ng-option>
                      <ng-option *ngFor="let data of dataDBFilter" [value]=data>{{data}}</ng-option>
                  </ng-select>
                </div> -->
                <!-- <div class="col-md-4">
                  <label class="col-md-10 " style="margin-top: 10px;">Server</label>
                  <ng-select formControlName="ServerName" (change)="filterById()" notFoundText="Không có kết quả">
                      <ng-option value="">Tất cả</ng-option>
                      <ng-option *ngFor="let server of dataServerFilter" [value]=server>{{server}}</ng-option>
                  </ng-select>
                </div> -->
                <div class="col-md-4">
                  <label class="col-md-10 " style="margin-top: 10px;">Loại khách hàng</label>
                  <ng-select formControlName="TypeName" (change)="filterById()" notFoundText="Không có kết quả">
                      <ng-option value="">Tất cả</ng-option>
                      <ng-option *ngFor="let type of dataTypeNameFilter" [value]=type>{{type}}</ng-option>
                  </ng-select>
                </div>
              </div>
        </form>
        </div>
    </div>
</section>
<!----------------------------------------------- TABLE ------------------------------------------------->
<section class="content cus-body">
    <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                                <table style="width:100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                    class="table-bordered dataTables_wrapper no-footer">
                                <thead style="text-align: center;">
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let customer of dataTable; let i=index" >
                                        <td>{{i+1}}</td>
                                        <td>{{customer.CustomerCode}}</td>
                                        <td style="word-wrap: break-word;max-width: 200px;">{{customer.CustomerName}}</td>
                                        <td>{{customer.TypeName}}</td>
                                        <td style="word-wrap: break-word;max-width: 200px;">{{customer.Url_update}}</td>
                                        <td>{{customer.DatabaseName}}</td>
                                        <td>{{customer.ServerName}}</td>
                                        <td style="white-space: nowrap;">
                                          <button class="btn btn-outline-secondary btn-sm"
                                          ngbTooltip="Khởi tạo CSDL NB"
                                          (click)="open(Step1Modal, 'lg', 'Step1', customer)" 
                                          style="margin-right: 5px;">
                                          <i class="fas fa-database"></i>
                                          </button>                                        
                                        </td>
                                        <td style="white-space: nowrap;">
                                            <button class="btn btn-outline-success btn-sm"
                                            ngbTooltip="Chuyển đổi dữ liệu NB"
                                            (click)="open(Step2Modal, 'lg', 'Step2', customer)" 
                                            style="margin-right: 5px;">
                                            <i class="fas fa-exchange-alt"></i>
                                            </button>                                        
                                        </td>
                                        <td style="white-space: nowrap;">
                                            <button class="btn btn-outline-danger btn-sm"
                                            ngbTooltip="Cài đặt khách hàng"
                                            (click)="open(Step3Modal, 'lg', 'Step3', customer)"                                           
                                            style="margin-right: 5px;">
                                            <i class="fas fa-users-cog"></i>
                                            </button>                                        
                                        </td>
                                        <td style="white-space: nowrap;">
                                            <button class="btn btn-outline-info btn-sm"
                                            ngbTooltip="Đào tạo khách hàng"
                                            (click)="open(Step4Modal, 'lg', 'Step4', customer)" 
                                            style="margin-right: 5px;">
                                            <i class="fas fa-graduation-cap"></i>
                                            </button>                                        
                                        </td>
                                        <td style="white-space: nowrap;">
                                            <button class="btn btn-outline-warning btn-sm"
                                            ngbTooltip="Quy trình"
                                            style="margin-right: 5px;">
                                            <i class="fas fa-chalkboard-teacher"></i>
                                            </button>                                        
                                        </td>
                                      </tr>
                                  </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </div>
    </div>
</section>

<!-----------------------------------------------STEP1 MODAL ------------------------------------------------->
<ng-template #Step1Modal let-modal>
    <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
        <h4 class="modal-title h-title w-100"> {{titleModal}}</h4>
        <span (click)="modal.dismiss('Cross click')" aria-hidden="true" style="font-size: 20px;">&times;</span>
      </div>
      <div class="modal-body">
        <form class="form-horizontal" [formGroup]="AddForm">       
          <div class="row">
              <div class="col-md-5">
                  <label class="col-md-10 require">Tên CSDL khởi tạo mới</label>
                  <input type="text" class="form-control" placeholder="Nhập tên CSDL..." formControlName="DatabaseNameCreate"
                  [ngClass]="{ 'is-invalid': submitted && checkvalue.DatabaseNameCreate.errors }">
                  <div *ngIf="submitted && checkvalue.DatabaseNameCreate.errors" class="invalid-feedback">
                    <div *ngIf="checkvalue.DatabaseNameCreate.errors.required">
                        Tên CSDL không được bỏ trống!
                    </div>
                    <div *ngIf="checkvalue.DatabaseNameCreate.errors.maxlength">
                        Tên CSDL tối đa 200 ký tự!
                    </div>
                  </div>
              </div>
              <div class="col-md-7">
                  <label class="col-md-12 require">Tên CSDL khách hàng tham chiếu tương đồng</label>
                  <ng-select (change)="filterCustomer()" formControlName="DatabaseNameBackup"
                  [ngClass]="{ 'is-invalid': submitted && checkvalue.DatabaseNameBackup.errors }">
                      <ng-option value="">Chọn CSDL khách hàng tham chiếu tương đồng</ng-option>
                      <ng-option *ngFor="let c of dataTable" [value]="c.DatabaseName">{{c.DatabaseName}}</ng-option>
                  </ng-select>
                  <div *ngIf="submitted && checkvalue.DatabaseNameBackup.errors" class="invalid-feedback">
                    <div *ngIf="checkvalue.DatabaseNameBackup.errors.required">
                      CSDL khách hàng tham chiếu không được bỏ trống!
                    </div>
                  </div>
              </div>
              <div class="col-md-12">
                  <hr style="width: 100%;">
              </div>
              <div class="col-md-12">
                  <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Mã khách hàng</th>
                          <th scope="col">Tên khách hàng</th>
                          <th scope="col">Databse</th>
                          <th scope="col">Server</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let c of dataCustomerFilter">
                          <td>{{c.CustomerCode}}</td>
                          <td>{{c.CustomerName}}</td>
                          <td>{{c.DatabaseName}}</td>
                          <td>{{c.ServerName}}</td>
                        </tr>
                      </tbody>
                  </table>
              </div>
              <div class="col-md-12">
                  <div class="form-group">
                      <label for="exampleFormControlTextarea1">Kết quả trả về</label>
                      <textarea *ngIf="!resultResponse" class="form-control" formControlName="ResponseMessage" id="exampleFormControlTextarea1" rows="5" readonly placeholder="Kết quả sẽ được trả về sau khi bạn ấn xác nhận !"></textarea>
                      <div *ngIf="resultResponse" class="__responseBox col-md-12">
                        <!-- <div *ngFor="let res of dataResponse">
                          <div *ngIf="res.status == 1" class="__success">Bước {{res.step}}: {{res.result}} <i style="color: #28a745;" class="fas fa-check-circle"></i></div>
                          <div *ngIf="res.status != 1" class="__fail">Bước {{res.step}}: {{res.result}} &nbsp;<i style="color: #ffc107;" class="fas fa-exclamation-triangle"></i></div>
                        </div> -->
                        <ul>
                          <li *ngIf="step1Status == 1"><div class="__success">Bước 1: {{step1Message}} <i style="color: #28a745;" class="fas fa-check-circle"></i></div></li>
                          <li *ngIf="step1Status != 1"><div class="__fail">Bước 1: {{step1Message}} <i style="color: #ffc107;" class="fas fa-exclamation-triangle"></i></div></li>
                          <li *ngIf="step2Status == 1"><div class="__success">Bước 2: {{step2Message}} <i style="color: #28a745;" class="fas fa-check-circle"></i></div></li>
                          <li *ngIf="step2Status != 1"><div class="__fail">Bước 2: {{step2Message}} <i style="color: #ffc107;" class="fas fa-exclamation-triangle"></i></div></li>
                          <li *ngIf="step3Status == 1"><div class="__success">Bước 3: {{step3Message}} <i style="color: #28a745;" class="fas fa-check-circle"></i></div></li>
                          <li *ngIf="step3Status != 1"><div class="__fail">Bước 3: {{step3Message}} <i style="color: #ffc107;" class="fas fa-exclamation-triangle"></i></div></li>
                          <li *ngIf="step4Status == 1"><div class="__success">Bước 4: {{step4Message}} <i style="color: #28a745;" class="fas fa-check-circle"></i></div></li>
                          <li *ngIf="step4Status != 1"><div class="__fail">Bước 4: {{step4Message}} <i style="color: #ffc107;" class="fas fa-exclamation-triangle"></i></div></li>
                          <li *ngIf="step5Status == 1"><div class="__success">Bước 5: {{step5Message}} <i style="color: #28a745;" class="fas fa-check-circle"></i></div></li>
                          <li *ngIf="step5Status != 1"><div class="__fail">Bước 5: {{step5Message}} <i style="color: #ffc107;" class="fas fa-exclamation-triangle"></i></div></li>
                        </ul>
                      </div>                     
                    </div>
              </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="float-right">
          <button type="button" class="btn btn-outline-success btn-sm" (click)="onSubmit()">
            <i class="fas fa-save"></i> Xác nhận
          </button>
          <button type="button" class="btn btn-outline-danger btn-sm" (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;">
          <i class="far fa-times-circle"></i> Đóng
          </button>
        </div>
    </div>
</ng-template>

<!-----------------------------------------------STEP2 MODAL ------------------------------------------------->
<ng-template #Step2Modal let-modal>
    <app-step2-modal style="font-size: 13px; overflow-y: auto;max-height: 85vh;"
        [titleModalInput]="titleModal" 
        [dataCustomerInput]="dataTable"
        [dataCustomerInput]="dataTable"
        [URL_markInput]="URL_mark"
        [URL_classInput]="URL_class"
        [URL_teacher_roomInput]="URL_teacher_room"
        (close)="dissmissModal()">
    </app-step2-modal>
    <div class="modal-footer">
      <div class="float-right">
        <!-- <button type="button" class="btn btn-outline-success btn-sm">
          <i class="fas fa-save"></i> Xác nhận
        </button> -->
        <button type="button" class="btn btn-outline-danger btn-sm" (click)="modal.dismiss('Cross click')"
          style="margin-left: 1em;">
          <i class="far fa-times-circle"></i> Đóng
        </button>
      </div>
    </div>
</ng-template>

<!-----------------------------------------------STEP3 MODAL ------------------------------------------------->
<ng-template #Step3Modal let-modal>
  <app-step3-modal 
      [titleModalInput]="titleModal" 
      [customerCodeInput]="customerCode"
      (close)="dissmissModal()">
  </app-step3-modal>
</ng-template>

<!-----------------------------------------------STEP4 MODAL ------------------------------------------------->
<ng-template #Step4Modal let-modal>
  <app-step4-modal 
      [titleModalInput]="titleModal" 
      [customerCodeInput]="customerCode"
      (close)="dissmissModal()">
  </app-step4-modal>
</ng-template>