<div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
    <h4 class="modal-title h-title w-100"> {{titleModalInput}}</h4>
    <span (click)="cancel()" aria-hidden="true" style="font-size: 20px;">&times;</span>
  </div>
  <div class="modal-body">
    <form class="form-horizontal" [formGroup]="AddForm">       
      <div class="row">
          <div class="col-md-5">
              <label class="col-md-10 require">Tên CSDL khởi tạo mới</label>
              <input type="text" class="form-control" placeholder="Nhập tên CSDL..." formControlName="DatabaseNameCreate"
              [ngClass]="{ 'is-invalid': submitted && checkvalue.DatabaseNameCreate.errors }">
              <div *ngIf="submitted && checkvalue.DatabaseNameCreate.errors" class="invalid-feedback">
                <div *ngIf="checkvalue.DatabaseNameCreate.errors.required">
                    Tên CSDL không được bỏ trống!
                </div>
                <div *ngIf="checkvalue.DatabaseNameCreate.errors.maxlength">
                    Tên CSDL tối đa 200 ký tự!
                </div>
              </div>
          </div>
          <div class="col-md-7">
              <label class="col-md-12 require">Tên CSDL khách hàng tham chiếu tương đồng</label>
              <ng-select (change)="filterCustomer()" formControlName="DatabaseNameBackup"
              [ngClass]="{ 'is-invalid': submitted && checkvalue.DatabaseNameBackup.errors }">
                  <ng-option value="">Chọn CSDL khách hàng tham chiếu tương đồng</ng-option>
                  <ng-option *ngFor="let c of dataCustomerInput" [value]="c.DatabaseName">{{c.DatabaseName}}</ng-option>
              </ng-select>
              <div *ngIf="submitted && checkvalue.DatabaseNameBackup.errors" class="invalid-feedback">
                <div *ngIf="checkvalue.DatabaseNameBackup.errors.required">
                  CSDL khách hàng tham chiếu không được bỏ trống!
                </div>
              </div>
          </div>
          <div class="col-md-12">
              <hr style="width: 100%;">
          </div>
          <div class="col-md-12">
              <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Mã khách hàng</th>
                      <th scope="col">Tên khách hàng</th>
                      <th scope="col">Databse</th>
                      <th scope="col">Server</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let c of dataCustomerFilter">
                      <td>{{c.CustomerCode}}</td>
                      <td>{{c.CustomerName}}</td>
                      <td>{{c.DatabaseName}}</td>
                      <td>{{c.ServerName}}</td>
                    </tr>
                  </tbody>
              </table>
          </div>
          <div class="col-md-12">
              <div class="form-group">
                  <label for="exampleFormControlTextarea1">Kết quả trả về</label>
                  <textarea class="form-control" formControlName="ResponseMessage" id="exampleFormControlTextarea1" rows="5" readonly placeholder="Kết quả sẽ được trả về sau khi bạn ấn xác nhận !"></textarea>
              </div>
          </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button type="button" class="btn btn-outline-success btn-sm" (click)="confirm()">
        <i class="fas fa-save"></i> Xác nhận
      </button>
      <button type="button" class="btn btn-outline-danger btn-sm" (click)="cancel()"
        style="margin-left: 1em;">
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
</div>