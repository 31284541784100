import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { common } from 'src/app/app.common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder, Validators, MaxLengthValidator, AbstractControl, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { UpdateVersionService } from 'src/app/utils/services/update-version.service';
import { ExportService } from 'src/app/utils/services/export.service';
import { ModuleService } from 'src/app/utils/services/module.service';
import { VersionService } from 'src/app/utils/services/version.service';
import { BaseComponent } from 'src/app/base/base.component';

@Component({
  selector: 'app-history-update',
  templateUrl: './history-update.component.html',
  styleUrls: ['./history-update.component.scss']
})
export class HistoryUpdateComponent extends BaseComponent implements OnInit {

  @Input() CustomerID: any;

  dtOptions: DataTables.Settings;
  dataTable: any = []
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective
  dtTrigger = new Subject();

  searchForm = new FormGroup({
    ModuleCode: new FormControl(''),
    VersionName: new FormControl(''),
    Revision: new FormControl(''),
    FromDate: new FormControl(''),
    ToDate: new FormControl(''),
    UserName: new FormControl('')
  });

  ngOnInit(): void {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.Token;
    this.searchForm.value.UserName = a.Info.UserName;
    this.searchForm.patchValue({UserName: a.Info.UserName})
    /**-------------------------USE dtOptions to format table-------------------------*/
    // Filter DATA TABLE
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: true,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        },
      },
      columns: [
        {
          className: 'dt-center',
        },
        {
          title: 'UpdateID',
          className: "dt-center",
          visible: false
        },
        {
          title: 'STT',
          className: "dt-center",
        },
        {
          title: 'Phân hệ',
          className: "dt-center",
        },
        {
          title: 'Phiên bản',
          className: "dt-center",
        },
        {
          title: 'Revision',
          className: "dt-center",
        },
        {
          title: 'Ngày cập nhật',
          className: "dt-center",
        },
        {
          title: 'Nội dung cập nhật',
          className: "dt-center",
        },
        {
          title: 'Thông báo cập nhật',
        },
        {
          title: 'Người cập nhật',
          className: "dt-center",
        },
        {
          title: 'Kết quả',
          className: "dt-center",
        },
      ],
    };
  this.GetAll();
  this.GetModule();
  this.GetVersion();
  this.GetRevision();
}

  GetModule() {
    this.ModuleService.Load_List(this.Token).subscribe(
      (res) => {
        this.dataModuleCodeFilter = res.listModules;
        this.dataModuleCodeFilter.sort((a,b) => (a.ModuleCode > b.ModuleCode) ? 1 : ((b.ModuleCode > a.ModuleCode) ? -1 : 0))
      }
    );
  }

  GetVersion() {
    this.VersionService.Load_List(this.Token).subscribe(
      (res) => {
        this.dataVersionNameFilter = res.listVersions;
        this.dataVersionNameFilter.sort((a,b) => (a.VersionName > b.VersionName) ? 1 : ((b.VersionName > a.VersionName) ? -1 : 0))
      }
    );
  }

  GetRevision() {
    this.UpdateVersionService.Revision_search(this.Token).subscribe(
      (res) => {
        this.dataRevisionFilter = res.listRevisions.filter(x => x.Revision > 0);
      }
    );
  }

  Search() {
    this.GetAll();
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      }
    );
  }

  GetAll() {
    let dataSearch = {
      CustomerID: this.CustomerID,
      ModuleCode : this.searchForm.value.ModuleCode,
      VersionName : this.searchForm.value.VersionName,
      Revision : this.searchForm.value.Revision,
      FromDate : this.searchForm.value.FromDate,
      ToDate: this.searchForm.value.ToDate,
      UserName : this.searchForm.value.UserName,
    }
    this.UpdateVersionService.UpdateVersion_CustomerID(dataSearch,this.Token)
    .subscribe(z =>{
      this.spinner.show();
      this.dataTable = z.listUpdateVersions;
      this.dtTrigger.next();
      this.spinner.hide();
    })
  }

  chonall() {
    var table = $('#maindata').DataTable().rows({ filter: 'applied' }).data();
    var length = table.rows({ filter: 'applied' }).nodes().length;
    var lstID = [];
    for (var i = 0; i < length; i++) {
      var ID_update = parseInt(table[i][1]);
      lstID.push(ID_update);
    }
    for (let index = 0; index < this.dataTable.length; index++) {
      if (lstID.includes(this.dataTable[index].UpdateID)) {
        this.dataTable[index].Selected = $('#SelectALL').prop('checked');
      }
    }
  }
  SelectRow(UpdateID) {
    for (let index = 0; index < this.dataTable.length; index++) {
      if (this.dataTable[index].UpdateID == UpdateID) {
        if ($('#checkbox' + UpdateID).prop('checked')) {
          {
            this.dataTable[index].Selected = true;
          }
        } else {
          this.dataTable[index].Selected = false;
        }
        break;
      }
    }
  }

  DeleteHistory() {
    var lst_ID_delete = this.dataTable.filter(x => x.Selected == true).map(x => x.UpdateID);
    this.spinner.show();
    let req = {
      list_ID: lst_ID_delete
    }
    this.UpdateVersionService.Delete(req, this.Token).subscribe(
      (z) => {
        if (z.Status == 2) {
          this.spinner.show();
          this.toastr.warning(z.Message);
          this.spinner.hide();
        } else if (z.Status == 1) {
          this.spinner.show();
          this.toastr.success(z.Message);
          this.spinner.hide();
        } else {
          this.spinner.show();
          this.toastr.error(z.Message);
          this.spinner.hide();
        }
        let dataSearch = {
          CustomerID: this.CustomerID,
          ModuleCode : this.searchForm.value.ModuleCode,
          VersionName : this.searchForm.value.VersionName,
          Revision : this.searchForm.value.Revision,
          FromDate : this.searchForm.value.FromDate,
          ToDate: this.searchForm.value.ToDate,
          UserName : this.searchForm.value.UserName,
        }
        this.GetAll();
        this.datatableElement.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          }
        );
      },
      (err) => {
        if (err.status == 401) {
          this.spinner.show();
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
          this.spinner.hide();
        }
      }
    );
  }

  Excel(){
    let dataExport = {
      CustomerID: this.CustomerID,
      ModuleCode: this.searchForm.value.ModuleCode,
      VersionName: this.searchForm.value.VersionName,
      Revision: this.searchForm.value.Revision,
      FromDate: this.searchForm.value.FromDate,
      ToDate: this.searchForm.value.ToDate,
      UserName: this.searchForm.value.UserName,
    }
    var r = confirm("File của bạn sẽ được tải xuống trong giây lát!");
    if (r) {
      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      this.spinner.show();            
      this.UpdateVersionService.Excel_History(dataExport,a.Token)
        .subscribe(z => {
          if (z.Status == 1) {
            this.ExportService.exportExcelByte(z.FileData, 'LichSuUpdate');
            this.toastr.success("File đã được tải xuống", "Tác vụ thành công")
            this.spinner.hide();
          }
          else {
            this.toastr.error(z.Message, "Tác vụ thất bại")
            this.spinner.hide();
          }
        });
    };
  }

  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      $.fn['dataTable'].ext.search.pop();
    });
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
      let ModuleCode_data = data[3];
      let VersionName_data = data[4];
      let Revision_data = data[5];
      let UserName_data = data[9];

      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let bool4 = true;

      if (this.searchForm.value.ModuleCode != '') {
        bool1 = ModuleCode_data == this.searchForm.value.ModuleCode;
      }
      if (this.searchForm.value.VersionName != '') {
        bool2 = VersionName_data == this.searchForm.value.VersionName;
      }
      if (this.searchForm.value.Revision != '') {
        bool3 = parseInt(Revision_data) == parseInt(this.searchForm.value.Revision);
      }
      if (this.searchForm.value.UserName != '') {
        bool4 = this.xoa_dau(UserName_data.toLowerCase()).includes(this.xoa_dau(this.searchForm.value.UserName.toLowerCase()));
      }
      if (bool1 && bool2 && bool3 && bool4) {
        return true;
      }
      return false;
    });
    $('#SelectALL').prop('checked', false);
  }
}
