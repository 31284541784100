<div class="container">
	<div class="screen">
		<div class="screen__content">
      <img src="/assets/img/thienan.png" style="
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
      " class="center" />
      <span class="login100-form-title p- -43" style="font-family: Raleway, sans-serif;">
        UNISOFT-GOV
      </span>
			<form class="login" [formGroup]="loginForm">
				<div class="login__field">
					<i class="login__icon fas fa-user"></i>
					<input type="text" class="login__input" placeholder="Tên đăng nhập" formControlName="UserName">
				</div>
				<div class="login__field">
					<i class="login__icon fas fa-lock"></i>
					<input type="password" class="login__input" placeholder="Mật khẩu" formControlName="Password">
				</div>
				<button class="button login__submit" (click)="logIn()">
					<span class="button__text">Đăng nhập</span>
					<i class="button__icon fas fa-chevron-right"></i>
				</button>			
			</form>
			<div class="container-login100-form-btn" style="margin-top: 30px;font-weight: bold;">
				<a [routerLink]="['/forgot-password']" class="registertxt">
				  Quên mật khẩu
				</a>
			</div>	
			<div class="social-login">
				<h3>ThienAn</h3>
				<div class="social-icons">
					<a href="#" class="social-login__icon fab fa-instagram"></a>
					<a href="#" class="social-login__icon fab fa-facebook"></a>
					<a href="#" class="social-login__icon fab fa-twitter"></a>
				</div>
			</div>
		</div>
		<div class="screen__background">
			<span class="screen__background__shape screen__background__shape4"></span>
			<span class="screen__background__shape screen__background__shape3"></span>		
			<span class="screen__background__shape screen__background__shape2"></span>
			<span class="screen__background__shape screen__background__shape1"></span>
		</div>		
	</div>
</div>